import React from "react";
import { Link, NavLink } from "react-router-dom";

function NotFoundPage() {
  return (
    <div>
      <div class="col-md-8 width_bg1">
        <div classname="error-page--header">
          <NavLink to="/" className="logo-af">
            <img
              src="/assets/images/1658999284845_android-chrome-192x192.png"
              id="logoo"
              alt=""
            />
          </NavLink>
        </div>
        <div className="error-main">
          <h1>Oops?</h1>
          <p>
            It looks like you've reached an URL that doesn't exist. Use the
            button below to find your way back to the home page.
          </p>
          <div class="pt-lg-5 pt-3">
            <Link to="/" class="btn btn-primary btn-xl py-2 px-5 rounded-pill">
              BrainFlix Home
            </Link>
          </div>
          {/* <div className="error-page--content--errorCode">
            <span id="" data-uia="">
              Error Code <strong>404</strong>
            </span>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default NotFoundPage;
