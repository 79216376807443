import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { logo_fetech } from "../../../redux/userDetailsSlice";
import "./Header.scss";
const NAVIGATION_LIST = [
  { link: "/about", label: "About BrainFlix" },
  { link: "/how-it-works", label: "How It Works" },
  { link: "/pricing", label: "Pricing" },
  { link: "/testimonial", label: "Testimonials" },
{ link: "/blogs", label: "Blog" },
  // { link: "start-free-trial", label: "Start Free Trial" },
  // { link: "/sign-up", label: "Sign Up" },
  { link: "/SubmitLoginPage", label: "Sign Up" },

  { link: "/log-in", label: "Log In" },
];
function Header() {
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(false);

  const { logofetch_data } = useSelector((state) => state.userDetailsss);
  useEffect(() => {
    dispatch(
      logo_fetech({
        setting_slug: "logo",
      })
    );
  }, [dispatch]);

  return (
    <>
      <div className="header ">
        <nav className="navbar navbar-expand-xl">
          <div className="container">
            <div className="row justify-content-between align-items-center w-100">
              <div className="col-auto">
                <Link to="/" className="logo">
                  <img
                    src={`https://admin.brainflix.com/uploads/settings/${logofetch_data?.setting_value}`}
                    alt=""
                  />
                  {/* <img src="/assets/images/logo v7 color 1.png" alt="brain" /> */}
                </Link>
              </div>
              <div className="col d-xl-none d-flex justify-content-end">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={() => setMenu(!menu)}
                >
                  <span className="navbar-toggler-icon">
                    <i className="fa-solid fa-bars"></i>
                  </span>
                </button>
              </div>
              <div
                className={
                  menu
                    ? "col menu-flex d-flex justify-content-end active"
                    : "col menu-flex d-flex justify-content-end"
                }
              >
                {/* <div className="text-end">
              
                </div> */}

                <div id="navbarSupportedContent">
                  <button
                    className="btn cross_btn"
                    onClick={() => setMenu(false)}
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </button>
                  <ul className="list-unstyled m-0 d-flex justify-content-end">
                    {NAVIGATION_LIST.map((data, ind) => (
                      <li>
                        <NavLink to={data.link} activeClassName="selected">
                          {data?.label}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

export default Header;
