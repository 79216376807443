import { React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../Components/Layout/Footer/Footer";
import Header from "../../Components/Layout/Header/Header";
// import { useNavigate } from "react-router-dom";
// import Footer from "../../Components/Layout/Footer/Footer";
import { fetch_general_cms } from "../../redux/cmsSlice";
import "./Privacypolicy.scss";
export default function Donatetobrainflix() {
  window.scrollTo(0, 0);
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { cmsgeneral_data } = useSelector((s) => s.cms);

  useEffect(() => {
    dispatch(
      fetch_general_cms({
        slug: "donate-to-brainflix",
      })
    );
  }, [dispatch]);

  return (
    <>
      {window.location.pathname.toLowerCase() === "/donatetobrainflix" ? (
        <Header />
      ) : null}
      <div style={{ marginTop: "10rem" }}>    <div
        className="container mt-5 mb-5 privacy-style"
        style={{ minHeight: "540px" }}
      >
        <h1 className="titleprivacy">{cmsgeneral_data?.title}</h1>

        <p dangerouslySetInnerHTML={{ __html: cmsgeneral_data?.content }}></p>
      </div></div>


      {window.location.pathname.toLowerCase() === "/donatetobrainflix" ? (
        <Footer />
      ) : null}

    </>
  );
}
