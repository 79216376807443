import React, { useEffect, useState, useContext } from "react";
import Footer from "../../Components/Layout/Footer/Footer";
import Header from "../../Components/Layout/Header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import uniqid from "uniqid";
import { context } from "../../hooks";
import profile from "../../assets/images/profile.jpeg";
import Placeholder from "react-bootstrap/Placeholder";

import { toast } from "react-toastify";
const BlogDetails = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [blogRelated, setBlogRelated] = useState(null);
  const [blogDetails, setBlogDetails] = useState(null);
  const [commentPost, setCommentPost] = useState({
    content: "",
    commentator_name: "",
  });

  const [error, setError] = useState({
    commentator_name: "",
    content: "",
  });

  const [allComments, setAllComments] = useState(null);
  const [isSubmit, setIsSubmit] = useContext(context);

  useEffect(() => {
    async function getByIDBlogRelated() {
      await axios({
        method: "get",
        url: `https://webdev.wordpress-developer.us/brainflix/wp-json/custom-api/post/related/?post_id=${state?.id}`,
      })
        .then(function (response) {
          setBlogRelated(response?.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    async function getByIDBlogDetails() {
      await axios({
        method: "get",
        url: `https://webdev.wordpress-developer.us/brainflix/wp-json/wp/v2/posts/${state?.id}`,
      })
        .then(function (response) {
          setBlogDetails(response?.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    async function getByIDBlogCommentsAfter() {
      if (!isSubmit) {
        await axios({
          method: "get",
          url: `https://webdev.wordpress-developer.us/brainflix/wp-json/wp/v2/comments?post=${state?.id}`,
        })
          .then(function (response) {
            setAllComments(response?.data);
          })
          .catch(function (error) {
            console.log(error);
          });
        setIsSubmit(true);
      }
    }
    async function getByIDBlogCommentsBefore() {
      await axios({
        method: "get",
        url: `https://webdev.wordpress-developer.us/brainflix/wp-json/wp/v2/comments?post=${state?.id}`,
      })
        .then(function (response) {
          setAllComments(response?.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    getByIDBlogCommentsAfter();
    getByIDBlogCommentsBefore();
    getByIDBlogDetails();
    getByIDBlogRelated();
  }, [state, isSubmit, setIsSubmit]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "commentator_name") {
      if (value.length === 0) {
        setError({ ...error, commentator_name: "Please Enter Your Email" });
        setCommentPost({ ...commentPost, [name]: value });
      } else {
        setError({
          ...error,
          commentator_name: "",
        });
        setCommentPost({ ...commentPost, [name]: value });
      }
    }
    if (name === "content") {
      if (value.length === 0) {
        setError({ ...error, content: "Please Enter Your content" });
        setCommentPost({ ...commentPost, [name]: value });
      } else {
        setError({
          ...error,
          content: "",
        });
        setCommentPost({ ...commentPost, [name]: value });
      }
    }
  };

  const postComment = async (e) => {
    e.preventDefault();
    try {
      if (!isSubmit) {
        setIsSubmit(true);
      } else {
        setError({
          commentator_name: "",
          content: "",
        });
        if (
          commentPost.commentator_name.length === 0 &&
          commentPost.content.length === 0
        ) {
          setError({
            commentator_name: "Please Enter Your Name",
            content: "Please Enter Your valuable Comment",
          });
        } else if (commentPost.commentator_name.length === 0) {
          setError({
            ...error,
            commentator_name: "Please Enter Your fullName",
          });
        } else if (commentPost.content.length === 0) {
          setError({ ...error, content: "Please Enter Your Commnets" });
        }

        await axios
          .post(
            "https://webdev.wordpress-developer.us/brainflix/wp-json/wp/v2/comments",
            {
              post: state?.id,
              content: commentPost.content,
              meta: {
                commentator_id: uniqid(),
                commentator_name: commentPost.commentator_name,
              },
            },
            {
              auth: {
                username: "admin-brainflix",
                password: "KiV5UQj@h3wa8S1#Uu",
              },
            }
          )
          .then((response) => {
            toast.success("Comment Successfully Added");
            console.log(
              "🚀  file: BlogDetails.jsx  line 50  .then  response",
              response
            );
          })
          .catch((error) => {
            console.log(error);
          });
        setIsSubmit(false);
        window.scrollTo(0, 0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const goToBlogInnerPage = (e, id, name) => {
    navigate(`/blog-details/${name.replace(/\s/g, "-")}`, {
      state: { id: id },
    });
    window.scrollTo(0, 0);
  };
  return (
    <>
      <Header />
      <div className="container header_top">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <h2 style={{ color: "white" }}>Blog</h2>
          </div>
          <div className="col-sm-8">
            {blogDetails !== null ? (
              <>
                <div className="Index_wraper__ox8sf p-1 pnew-1">
                  <div className="blog-details-contain">
                    <div className="blog-top-one">
                      <h4 className="fonttt">
                        {" "}
                        {blogDetails?.yoast_head_json?.title}
                      </h4>
                      <div className="d-flex">
                        <span>
                          <i className="fa-solid fa-calendar mx-2"></i>
                          {new Date(blogDetails.date).toDateString()}
                        </span>
                        {/* <span className="px-3">
                          <i className="fa-solid fa-clock mx-2"></i>
                          {new Date(blogDetails.date).toLocaleTimeString()}
                        </span> */}
                      </div>
                    </div>

                    <p> {blogDetails?.yoast_head_json?.og_description}</p>
                    <img
                      src={blogDetails?.yoast_head_json?.og_image[0]?.url}
                      style={{ maxWidth: "100%" }}
                      alt=""
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="Index_wraper__ox8sf p-1 pnew-1">
                  <div className="blog-details-contain">
                    <h2>
                      <Placeholder animation="glow">
                        <Placeholder
                          xs={12}
                          style={{
                            color: "white",
                            height: "20px",
                          }}
                        />
                      </Placeholder>
                    </h2>
                    <p>
                      {" "}
                      <Placeholder animation="glow">
                        <Placeholder
                          xs={12}
                          style={{
                            color: "white",
                            height: "150px",
                          }}
                        />
                      </Placeholder>
                    </p>
                    <Placeholder animation="glow">
                      <Placeholder
                        xs={12}
                        style={{
                          color: "white",
                          height: "300px",
                        }}
                      />
                    </Placeholder>
                  </div>
                </div>
              </>
            )}

            <div className="Index_wraper__ox8sf p-1 pnew-1">
              <div className="comend-section-main">
                <h1 className="mb-5 color-white">Comments</h1>
                {allComments !== null ? (
                  <>
                    {allComments.map((item, i) => (
                      <>
                        <div className="commend-contain11" key={i}>
                          <figure>
                            <img src={profile} alt="" />
                          </figure>
                          <div className="heading-contain">
                            <h2>{item?.meta?.commentator_name} says:</h2>
                            <p>{new Date(item?.date).toDateString()} </p>
                          </div>
                        </div>
                        <div class="new-comment-content">
                          <p
                            className="p-0"
                            dangerouslySetInnerHTML={{
                              __html: `${item?.content?.rendered}`,
                            }}
                          ></p>
                        </div>
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    {" "}
                    {[1, 2, 3, 4].map((i) => (
                      <>
                        <div className="new-comment-content" key={i}>
                          <div className="heading-contain">
                            <Placeholder animation="glow">
                              <Placeholder
                                xs={4}
                                style={{
                                  color: "white",
                                }}
                              />
                            </Placeholder>
                            <p>
                              {" "}
                              <Placeholder animation="glow">
                                <Placeholder
                                  xs={5}
                                  style={{
                                    color: "white",
                                  }}
                                />
                              </Placeholder>
                            </p>
                          </div>
                        </div>
                        <div class="new-comment-content">
                          <p>
                            {" "}
                            <Placeholder animation="glow">
                              <Placeholder
                                xs={8}
                                style={{
                                  color: "white",
                                }}
                              />
                            </Placeholder>
                          </p>
                        </div>
                      </>
                    ))}
                  </>
                )}
              </div>
            </div>
            <div className="Index_wraper__ox8sf p-1 pnew-1 mb-2 mt-2">
              <div className="reply-bottom-contain">
                <div className="row">
                  <div className="col-sm-12 mb-3">
                    <h5 className="e-text1">COMMENT *</h5>
                    <textarea
                      className="form-control"
                      name="content"
                      value={commentPost.content}
                      onChange={handleChange}
                    ></textarea>
                    <span style={{ color: "red" }}>{error.content}</span>
                  </div>
                  <div className="col-sm-12 mb-3">
                    <h5 className="e-text1">NAME *</h5>
                    <input
                      name="commentator_name"
                      value={commentPost.commentator_name}
                      onChange={handleChange}
                      text="text"
                      placeholder="Enter Your name"
                      className="form-control"
                    />
                    <span style={{ color: "red" }}>
                      {error.commentator_name}
                    </span>
                  </div>

                  <div className="col-sm-12 mb-3">
                    <button className="button-one" onClick={postComment}>
                      Submit
                    </button>
                  </div>
                </div>
                {/* <div className="row">
                    <div className="col-sm-12 mb-2">
                      <h5 className="e-text1">NAME *</h5>
                      <input
                        name="commentator_name"
                        value={commentPost.commentator_name}
                        onChange={handleChange}
                        text="text"
                        placeholder="Enter Your name"
                        className="form-control"
                      />
                      <span style={{ color: "red" }}>
                        {error.commentator_name}
                      </span>
                    </div>
                    <div className="col-sm-12 mb-2">
                      <label className="e-text1">COMMENT *</label>
                      <textarea
                        className="form-control"
                        name="content"
                        value={commentPost.content}
                        onChange={handleChange}
                      ></textarea>
                      <span style={{ color: "red" }}>{error.content}</span>
                    </div>

              
                    <div className="col-sm-12 mb-2">
                      <div className="col-sm-12 mb-2">
                        <button
                          className="button-one"
                          type="submit"
                          onClick={postComment}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div> */}
              </div>
            </div>
          </div>

          {blogRelated !== null ? (
            <div className="col-sm-4">
              <div className="Index_wraper__ox8sf p-1 pnew-1">
                <div className="listing-right">
                  <h2>Related Posts</h2>
                  <ul style={{ cursor: "pointer" }}>
                    {blogRelated.map((item, i) => (
                      <div
                        onClick={(e) =>
                          goToBlogInnerPage(e, item?.post_id, item?.post_title)
                        }
                      >
                        <li>
                          {" "}
                          <h4>{item?.post_title}</h4>{" "}
                        </li>
                        <div className="d-flex">
                          <span>
                            <i className="fa-solid fa-calendar mx-2"></i>
                            {new Date(item.post_publish_date).toDateString()}
                          </span>
                        </div>
                        <hr />
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="col-sm-4">
                <div className="Index_wraper__ox8sf p-1 pnew-1">
                  <div className="listing-right">
                    <p>
                      {" "}
                      <Placeholder animation="glow">
                        <Placeholder
                          xs={12}
                          style={{
                            color: "white",
                          }}
                        />
                      </Placeholder>
                    </p>
                    {[1, 2, 3, 4].map((i) => (
                      <ul key={i}>
                        <li>
                          {" "}
                          <Placeholder animation="glow">
                            <Placeholder
                              xs={7}
                              style={{
                                color: "white",
                              }}
                            />
                          </Placeholder>
                          <div className="">
                            <span>
                              <Placeholder animation="glow">
                                <Placeholder
                                  xs={4}
                                  style={{
                                    color: "white",
                                  }}
                                />
                              </Placeholder>
                            </span>
                          </div>
                        </li>
                      </ul>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default BlogDetails;
