import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetch_watch_history,
  post_watch_history,
} from "../../redux/videoSlice";
import Placeholder from "react-bootstrap/Placeholder";
import { useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import VideoPopup from "../VideoPopUp/VideoPopup";
import { context } from "../../hooks";
export default function Recent() {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const {
    fetch_watch_history_data,
    hide_video_status,
    fetch_watch_history_status,
  } = useSelector((state) => state.video);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [show, setShow] = useState(false);
  const [videoDetails, setVideoDetails] = useState({});
  const [isSubmit, setIsSubmit] = useContext(context);
  const [fullscreen, setFullscreen] = useState(false);

  const handleOpenFullScreen = () => {
    setFullscreen(!fullscreen);
  };

  const handleCloseFullScreen = () => {
    setFullscreen(false);
  };

  const handleClose = () => {
    setShow(false);
    // if (JSON.parse(localStorage.getItem("subUserId"))) {
    //   dispatch(
    //     fetch_watch_history({
    //       userId:
    //         JSON.parse(localStorage.getItem("subUserId")) || userDetails._id,
    //     })
    //   );
    // }
  };
  const handleShow = (e, item) => {
    setVideoDetails(item);
    setShow(true);
  };
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("subUserId"))) {
      dispatch(
        fetch_watch_history({
          userId: JSON.parse(localStorage.getItem("subUserId")),
        })
      );
    } else {
      dispatch(
        fetch_watch_history({
          userId: userDetails._id,
        })
      );
    }
  }, [dispatch, userDetails._id, hide_video_status, show]);
  const watchHistory = (e, videoID) => {
    try {
      dispatch(
        post_watch_history({
          videoId: videoID,
          userId:
            JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              ? JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              : state?.id,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="search-page">
        <div className="common-container">
          <div className="favourites">
            <div className="fabourites__heading">
              <h2 style={{fontSize:"28px"}}>
                <img style={{width:"34px"}} src="/assets/images/icons/recent-history.svg" alt="" />
                Your recently watched videos
              </h2>
            </div>
          </div>

          {fetch_watch_history_status === "loading" ? (
            <>
              {" "}
              <div className="search-page__result">
                <div className="row">
                  <Placeholder animation="glow">
                    <Placeholder
                      xs={4}
                      style={{
                        color: "white",
                      }}
                      className="mb-3"
                    />
                  </Placeholder>
                  {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
                    <div className="col-lg-3 col-md-3 col-12 mb-4" key={i}>
                      <Placeholder animation="glow">
                        <Placeholder
                          className="rec-slider reset-foucs-v"
                          xs={6}
                          style={{
                            color: "white",
                            height: "200px",
                            width: "300px",
                          }}
                        />
                      </Placeholder>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : fetch_watch_history_data !== "no data found" ? (
            <>
              <div className="search-page__result">
                <div className="row">
                  {fetch_watch_history_data?.docs?.length !== 0 ? (
                    <>
                      {fetch_watch_history_data?.docs?.map((item, i) => (
                        <div
                          className="col-lg-3 col-md-6 mb-4"
                          key={i}
                          onClick={(e) => {
                            watchHistory(e, item?.videoId?._id);
                            handleShow(e, item?.videoId);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="rec-slider reset-foucs-v">
                            <img
                              src={`https://img.youtube.com/vi/${item?.videoId?.videoId}/0.jpg`}
                              alt={item?.title}
                            />
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <span
                      style={{
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "30px",
                      }}
                    >
                      Oops ! No Recent's Video found
                    </span>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <span
                style={{
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "30px",
                }}
              >
                Oops ! No Recent's Video found
              </span>
            </>
          )}
        </div>
      </div>
      <Modal
        fullscreen={fullscreen}
        show={show}
        onHide={() => {
          // watchHistory();
          handleClose();
        }}
        className={fullscreen ? "modal_pop_full" : "modal_pop"}
      >
        <VideoPopup
          videoDetails={videoDetails}
          hideVideo={handleClose}
          fullscreen={fullscreen}
          handleOpenFullScreen={handleOpenFullScreen}
          handleCloseFullScreen={handleCloseFullScreen}
        />
      </Modal>
    </>
  );
}
