import React, { useEffect, useState } from "react";
import { Modal, Placeholder } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetch_hide_video } from "../../redux/videoSlice";
import VideoPopup from "../VideoPopUp/VideoPopup";
import { useLocation } from "react-router-dom";
import "./Hide.scss";

export default function Hde() {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { fetch_hide_video_data, fetch_hide_video_status, hide_video_status } =
    useSelector((s) => s.video);
  const [show, setShow] = useState(false);
  const [videoDetails, setVideoDetails] = useState({});
  const [fullscreen, setFullscreen] = useState(false);
  const [fromHide, setFromHide] = useState("");
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const handleOpenFullScreen = () => {
    setFullscreen(!fullscreen);
  };

  const handleCloseFullScreen = () => {
    setFullscreen(false);
  };
  const handleClose = () => setShow(false);
  const handleShow = (e, item, val) => {
    setVideoDetails(item);
    setShow(true);
    setFromHide(val);
  };

  useEffect(() => {
    dispatch(
      fetch_hide_video({
        userId:
          JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
            ? JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
            : state?.id,
      })
    );
  }, [dispatch, userDetails._id, state, hide_video_status]);

  return (
    <>
      <div className="search-page">
        <div className="common-container m-height">
          <div className="favourites">
            <div className="fabourites__heading">
              <h2>
                <img
                  src="/assets/images/hide.png"
                  style={{ width: "48px" }}
                  alt=""
                />
                Your hidden videos
              </h2>
            </div>
          </div>
          {fetch_hide_video_status === "loading" ? (
            <>
              <div className="search-page__result mt-3">
                <div className="row">
                  {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
                    <div className="col-lg-3 col-md-3 col-12 mb-4" key={i}>
                      <Placeholder animation="glow">
                        <Placeholder
                          className="rec-slider reset-foucs-v"
                          xs={6}
                          style={{
                            color: "white",
                            height: "200px",
                            width: "300px",
                          }}
                        />
                      </Placeholder>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : fetch_hide_video_data !== "no data" ? (
            <>
              <div className="search-page__result mt-3">
                <div className="row">
                  {fetch_hide_video_data?.map((item, i) => (
                    <div
                      className="col-lg-3 col-md-6 mb-4"
                      key={i}
                      onClick={(e) => {
                        handleShow(e, item?.videoId, "hide");
                        // watchHistory(e, item._id);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="rec-slider reset-foucs-v">
                        <img
                          src={`https://img.youtube.com/vi/${item?.videoId?.videoId}/0.jpg`}
                          alt={item?.title}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <>
            
              <span
                style={{
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "30px",
                }}
               >
                Oops ! No video found
              </span>
            </>
          )}
        </div>
      </div>
      <Modal
        fullscreen={fullscreen}
        show={show}
        onHide={handleClose}
        className={fullscreen ? "modal_pop_full" : "modal_pop"}
       >
        <VideoPopup
          videoDetails={videoDetails}
          hideVideo={handleClose}
          fullscreen={fullscreen}
          handleOpenFullScreen={handleOpenFullScreen}
          handleCloseFullScreen={handleCloseFullScreen}
          fromHide={fromHide}
        />
      </Modal>
    </>
  );
}
