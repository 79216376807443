import React, { useEffect } from "react";
import "./AccountSettings.scss";
import { useDispatch, useSelector } from "react-redux";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  fetch_userdata_data,
  Parent_profile_update,
} from "../../redux/userDetailsSlice";
import { closeModal, handleopenModal } from "../../redux/authSlice";
import Placeholder from "react-bootstrap/Placeholder";

// import { photoUrl } from "../../API";
// import { Link } from "react-router-dom";
// import { Button, Modal } from "react-bootstrap";
import ResetModal from "./Resetmodal";
import "./AccountSettings.scss";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// import AfterLoginHeader from "../../Components/Layout/AfterLoginHeader/AfterLoginHeader";

export default function AccountSettings() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const { user_data, Parent_profile_status } = useSelector(
    (state) => state.userDetailsss
  );

  const [edit_parent_usersss, setEdit_parent_usersss] = useState();

  const [error, setError] = useState({
    fullName: "",
  });

  const { openModal } = useSelector((state) => state.auth);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const goToHelp = () => {
    navigate("/browser/help");
  };

  useEffect(() => {
    if (userDetails?._id) {
      dispatch(
        fetch_userdata_data({
          userId:
            JSON.parse(localStorage.getItem("subUserId")) || userDetails?._id
              ? JSON.parse(localStorage.getItem("subUserId")) ||
                userDetails?._id
              : JSON.stringify(localStorage.getItem("UserID")),
        })
      );
    }
  }, [dispatch, userDetails?._id, Parent_profile_status]);
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   if (name === "fullName") {
  //     if (value.length === 0) {
  //       setError({ ...error, fullName: "Please Enter Your Profile Name" });
  //       setEdit_parent_usersss({ ...edit_parent_usersss, [name]: value });
  //     }
  //   }
  // };
  const handleEdituser = (e) => {
    e.preventDefault();
    setError({
      fullName: "",
    });
    if (edit_parent_usersss.length === 0) {
      setError({
        fullName: "Please Enter Your Profile Name",
      });
    } else {
      const data = {
        fullName: edit_parent_usersss,
      };
      dispatch(Parent_profile_update(data));
      toast.success("Profile Updated Successfully");
      // navigate("/browser/first-profile");
    }
  };

  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  return (
    <>
      <div className="acc-set" style={{ marginBottom: "10%" }}>
        <div className="common-container">
          {/* <Link to="/browser">
            <button className="back-page">
              <img src="/assets/images/icons/back-page.svg" alt="" /> Back
            </button>
          </Link> */}

          <div className="acc-set__lists">
            <div className="page-heading">
              <div className="page-heading__wrapper">
                <h2>Account Information</h2>
              </div>
            </div>

            <div className="acc-set__lists__item">
              <div className="acc-set__list__label">
                <label htmlFor="">Account Details</label>
              </div>
              <div className="settings-wrapper">
                <div className="row">
                  <div className="col-md-5">
                    <p>User Name: </p>
                  </div>
                  <div className="col-md-7">
                    {user_data !== null ? (
                      <p className="two-elem">
                        {user_data?.fullName}
                        {user_data?.role?.role === "user" && (
                          <button
                            className="button-def edit-name"
                            onClick={handleShow}
                          >
                            <img
                              src="/assets/images/icons/edit-icon.svg"
                              alt=""
                            />
                          </button>
                        )}
                      </p>
                    ) : (
                      <Placeholder animation="glow">
                        <Placeholder
                          xs={4}
                          style={{
                            color: "white",
                          }}
                          className="mb-3"
                        />
                      </Placeholder>
                    )}

                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Edit User Name</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="form-group">
                          <div className="label" style={{ color: "white" }}>
                            Profile Name
                          </div>
                          <input
                            type="text"
                            name="fullName"
                            id=""
                            onChange={(e) =>
                              setEdit_parent_usersss(e.target.value)
                            }
                            value={edit_parent_usersss}
                          />
                          <span style={{ color: "red" }}>{error.fullName}</span>
                        </div>
                        <button
                          className="btn btn-outline-primary rounded-pill px-4 py-2 shadow-none"
                          onClick={(e) => {
                            handleEdituser(e);
                            handleClose();
                          }}
                          //   disabled={resetStatus === "loading"}
                        >
                          Save changes
                        </button>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <p>Email: </p>
                  </div>
                  <div className="col-md-7">
                    <p>
                      {user_data?.email ? user_data?.email : userDetails?.email}
                    </p>
                  </div>
                </div>
                {user_data?.socialId === "" ? (
                  <div className="row">
                    <div className="col-md-5">
                      <p>Password: </p>
                    </div>
                    <div className="col-md-7">
                      <p className="two-elem">
                        **********
                        <button
                          className="button-def change-pass"
                          onClick={() => dispatch(handleopenModal())}
                        >
                          Change Password
                        </button>
                      </p>

                      <ResetModal
                        show2={openModal}
                        handleClose2={() => dispatch(closeModal())}
                      />
                    </div>
                  </div>
                ) : null}

                <div className="row">
                  <div className="col-md-5">
                    <p>Referral Link: </p>
                  </div>
                  <div className="col-md-7">
                    {user_data !== null ? (
                      <p className="two-elem">
                        {`https://admin.brainflix.com/sign-up?ref=${
                          user_data?.referralCode
                            ? user_data?.referralCode
                            : userDetails?.referralCode
                        }`}
                        <button className="button-def" onClick={goToHelp}>
                          <img
                            src="/assets/images/icons/ref-link-qu.svg"
                            alt=""
                          />
                        </button>
                      </p>
                    ) : (
                      <Placeholder animation="glow">
                        <Placeholder
                          xs={6}
                          style={{
                            color: "white",
                          }}
                          className="mb-3"
                        />
                      </Placeholder>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-5">
                    <p>Credits: </p>
                  </div>
                  <div className="col-md-7">
                    {user_data !== null ? (
                      <p className="two-elem" style={{ color: "#489FDF" }}>
                        {user_data?.creditScores === 0
                          ? "$0.00"
                          : `$ ${user_data?.creditScores}`}
                        {/* {user_data?.creditScores} */}
                        <button className="button-def" onClick={goToHelp}>
                          <img
                            src="/assets/images/icons/ref-link-qu.svg"
                            alt=""
                          />
                        </button>
                      </p>
                    ) : (
                      <Placeholder animation="glow">
                        <Placeholder
                          xs={6}
                          style={{
                            color: "white",
                          }}
                          className="mb-3"
                        />
                      </Placeholder>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {
              user_data?.subscription !== null ? (
                <>
                  <div className="acc-set__lists__item">
                    <div className="acc-set__list__label">
                      <label htmlFor="">Membership / Payment</label>
                    </div>
                    <div className="settings-wrapper">
                      <div className="row">
                        <div className="col-md-5">
                          <p>Membership: </p>
                        </div>
                        <div className="col-md-7">
                          <p className="two-elem">
                            {user_data?.subscription?.plan_id?.title}{" "}
                            {/* <button className="button-def edit-name">
                        <img src="/assets/images/icons/edit-icon.svg" alt="" />
                      </button> */}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-5">
                          <p>Billing Period: </p>
                        </div>
                        <div className="col-md-7">
                          <div className="two-elem">
                            <p>
                              Auto renew{" "}
                              {user_data?.subscription?.plan_id?.planDuration}
                              <br />
                              Next billing date:{" "}
                              {new Date(
                                user_data?.subscription?.endDate
                              ).toDateString()}
                            </p>
                            {/* <div className="div-switch">
                        <small>Auto Renew</small>

                        <label className="cus-switch">
                          <input type="checkbox" />
                          <span className="swslide round"></span>
                        </label>
                      </div> */}
                          </div>

                          {/* <small className="notific">
                          Switch to annual and save up to 30%
                        </small> */}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-5">
                          <p>Next Billing Amount:</p>
                        </div>
                        <div className="col-md-7">
                          <p className="two-elem">
                            $ {user_data?.subscription?.amount}{" "}
                          </p>
                          {/* <small className="notific-sec">
                          ($12.99 to be deducted from account credit)
                        </small> */}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-5">
                          <p>Account Credit: </p>
                        </div>
                        <div className="col-md-7">
                          <p className="two-elem">
                            $ {user_data?.subscription?.amount}{" "}
                            <button className="button-def">
                              <img
                                src="/assets/images/icons/ref-link-qu.svg"
                                alt=""
                              />
                            </button>
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-5">
                          <p>Payment Method: </p>
                        </div>
                        <div className="col-md-7">
                          <p className="two-elem">
                            {/* Credit card ending{" "} */}
                            **** **** ****{" "}
                            {user_data?.usercards?.last_four_digit}
                            {/* <button className="button-def edit-name">
                        <img src="/assets/images/icons/edit-icon.svg" alt="" />
                      </button> */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null
              // <>
              //   <span
              //     style={{
              //       color: "white",
              //       display: "flex",
              //       justifyContent: "center",
              //       fontSize: "18px",
              //     }}
              //   >
              //     Sorry !! You don't have any Subscription yet
              //   </span>
              // </>
            }
          </div>
        </div>
        {/* <div className="modal-sec">
         <div className="modal-body">
           <div className="form-group">
           <label>User Name:</label>
           <input type="text"/>
           </div>
           <div className="form-group">
           <label>Email:</label>
           <input type="email"/>
           </div>
         </div>
        </div> */}
        {/* <div className="modal-sec">
         <div className="modal-body">
           <div className="form-group">
           <label>Payment Method:</label>
           <input type="text"/>
           </div>
           
         </div>
        </div> */}
      </div>
    </>
  );
}
