import React, { useState, createContext } from "react";

export const context = createContext();
const GlobalState = ({ children }) => {
  const [isSubmit, setIsSubmit] = useState(false);

  return (
    <context.Provider value={[isSubmit, setIsSubmit]}>
      {children}
    </context.Provider>
  );
};

export default GlobalState;
