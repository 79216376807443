import React, { useEffect } from "react";
import "./testimonial.scss";
import ReactStars from "react-rating-stars-component";
import Header from "../../Components/Layout/Header/Header";
import Footer from "../../Components/Layout/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";

import { fetch_testimonial_data } from "../../redux/cmsSlice";
import { photoUrl } from "../../API";
function Testimonial() {
  const ratingChanged = (newRating) => {};
  // const loop = [1, 2, 3];
  const dispatch = useDispatch();
  const { cmsTestimonial_data } = useSelector((state) => state.cms);

  useEffect(() => {
    dispatch(fetch_testimonial_data());
  }, [dispatch]);

  return (
    <>
      <Header />
      <div className="testimonial common_gap">
        <div className="container my-3">
          <div className="row px-2">
            <div className="col-lg-12 mb-5 mt-3">
              <h2>Our Testimonials</h2>
            </div>
            {cmsTestimonial_data?.docs?.map((item, index) => {
              return (
                <div className="col-lg-10 mx-auto my-3" key={index}>
                  <div className="d-sm-flex testimonial_box">
                    {/* <img src="/assets/images/c1.png" alt="img" /> */}
                    <img src={photoUrl("testimonial", item?.image)} alt="" />

                    <div className="align-self-center px-sm-4 px-0">
                      <h5>{item?.raterName}</h5>
                      <ReactStars
                        value={item?.rating}
                        count={5}
                        onChange={ratingChanged}
                        size={24}
                        activeColor="#ffd700"
                      />

                      <p>
                        <span>Feedback : </span>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: item?.description,
                          }}
                        ></p>
                        {/* <span> See More</span> */}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Testimonial;
