/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import AfterLoginFooter from "../../Components/Layout/AfterLoginFooter/AfterLoginFooter";
import AfterLoginHeader from "../../Components/Layout/AfterLoginHeader/AfterLoginHeader";
// import Header from "../../Components/Layout/Header/Header";
import AccountSettings from "../../Pages/AccountSettings/AccountSettings";
import Contact from "../../Pages/Contact/Contact";
import Favourites from "../../Pages/Favourites/Favourites";
import FirstProfile from "../../Pages/FirstProfile/FirstProfile";
import NewProfileAvatar from "../../Pages/NewProfileAvatar/NewProfileAvatar";
import ProfileCreation from "../../Pages/ProfileCreation/ProfileCreation";
import ProfileSelection from "../../Pages/ProfileSelection/ProfileSelection";
import Recent from "../../Pages/Recent/Recent";
import Search from "../../Pages/Search/Search";
import SelectedChannel from "../../Pages/SelectedChannel/SelectedChannel";
import VideoBrowser from "../../Pages/VideoBrowser/VideoBrowser";
import ShowVideoCategory from "../../Pages/ShowVideoCategoryWise";
import EditsubProfile from "../../Pages/Editprofile/Editsubprofile";
import PreferencePage from "../../Pages/PreferencePage";
import Hide from "../../../src/Pages/Hidevideo/Hide";
import Help from "../../../src/Pages/Help/Help";
import Privacypolicy from "../../Pages/FooterCMS/ Privacypolicy";
import Termsconditions from "../../Pages/FooterCMS/Termsconditions";
import Supporteddevices from "../../Pages/FooterCMS/Supporteddevices";

import Aboutafterlogin from "../../Pages/FooterCMS/Aboutafterlogin";
import Donatetobrainflix from "../../Pages/FooterCMS/ Donatetobrainflix";
import EditParentUser from "../../Pages/Editprofile/EditParentUser";

const AfterLoginRouter = () => {
  const { pathname } = useLocation();
  const id = JSON.parse(localStorage.getItem("subUserId"));
  const paId = JSON.parse(localStorage.getItem("UserID"));
  return (
    <div>
      {pathname !== "/browser/first-profile" &&
        pathname !== "/browser/first-profile/profile-creation" &&
        pathname !==
          "/browser/first-profile/profile-creation/new-profile-avatar" &&
        pathname !== `/browser/edit-subProfile/${id}` &&
        pathname !== `/browser/edit-profile/${paId}` && <AfterLoginHeader />}

      <Routes path="/" element={<VideoBrowser />}>
        <Route path="/" element={<VideoBrowser />} />
        <Route path="selected-channel/:name" element={<SelectedChannel />} />
        <Route path="search" element={<Search />} />
        <Route path="favorites" element={<Favourites />} />
        <Route path="recent" element={<Recent />} />
        <Route path="account-settings" element={<AccountSettings />} />
        <Route path="/selected-channel" element={<SelectedChannel />} />
        <Route path="/search" element={<Search />} />
        <Route path="/favorites" element={<Favourites />} />
        <Route path="/hidevideo" element={<Hide />} />
        <Route path="/recent" element={<Recent />} />
        <Route path="/account-settings" element={<AccountSettings />} />
        <Route path="/help" element={<Help />} />
        <Route
          path="/first-profile/profile-creation"
          element={<ProfileCreation />}
        />
        <Route
          path="/first-profile/profile-creation/new-profile-avatar"
          element={<NewProfileAvatar />}
        />
        <Route path="first-profile" element={<FirstProfile />} />
        <Route path="profile-selection" element={<ProfileSelection />} />
        <Route path="/edit-subProfile/:id" element={<EditsubProfile />} />
        <Route path="/edit-profile/:id" element={<EditParentUser />} />
        <Route path="contact" element={<Contact />} />
        <Route path="/show-all/:name" element={<ShowVideoCategory />} />
        <Route path="user-preference" element={<PreferencePage />} />
        <Route path="privacypolicy" element={<Privacypolicy />} />
        <Route path="termscondition" element={<Termsconditions />} />
        <Route path="supported-devices" element={<Supporteddevices />} />
        <Route path="aboutafterlogin" element={<Aboutafterlogin />} />
        <Route path="donatetobrainflix" element={<Donatetobrainflix />} />
      </Routes>

      {pathname !== "/browser/first-profile" &&
        pathname !== "/browser/first-profile/profile-creation" &&
        pathname !==
          "/browser/first-profile/profile-creation/new-profile-avatar" &&
        pathname !== `/browser/edit-subProfile/${id}` &&
        pathname !== `/browser/edit-profile/${paId}` && <AfterLoginFooter />}
    </div>
  );
};

export default AfterLoginRouter;
