import axios from "axios";

export const baseURL = "https://admin.brainflix.com/api/"; //development
// const apidoc = "http://104.211.229.156:1571/apidoc/";

export const photoUrl = (path, photu) => {
  return `https://admin.brainflix.com/uploads/${path}/${photu}`;
  // return `https://admin-Brainflix.dedicateddevelopers.us/uploads/${path}/${photu}`;
  // return `https://admin-Brainflix.dedicateddevelopers.us/uploads/${photu}`;
};

export const imagePath = baseURL;
export const course_storagePath = baseURL + "/storage/";
let axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(
  async function (config) {
    const token =
      localStorage.getItem("brainFlix_token") ||
      sessionStorage.getItem("brainFlix_token");

    const subToken = localStorage.getItem("brainFlix_token_subcription_token");
    if (
      token !== null ||
      token !== undefined ||
      subToken !== null ||
      subToken !== undefined
    ) {
      config.headers["x-access-token"] = subToken ? subToken : token;
    }
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

export const fetchBaseQueryInstance = () => {
  return {
    baseUrl: baseURL,
    prepareHeaders: (headers) => {
      headers.set(
        "x-access-token",

        localStorage.getItem("brainFlix_token_subcription_token")
          ? localStorage.getItem("brainFlix_token_subcription_token")
          : localStorage.getItem("brainFlix_token") ||
              sessionStorage.getItem("brainFlix_token")
      );

      return headers;
    },
  };
};

export default axiosInstance;
