import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Controller } from "swiper";
import {
  fetch_channel_data_by_id,
  post_watch_history,
} from "../../redux/videoSlice";
import Modal from "react-bootstrap/Modal";
import VideoPopup from "../VideoPopUp/VideoPopup";
import { photoUrl } from "../../API";
import Placeholder from "react-bootstrap/Placeholder";
import Slider from "react-slick";
import sstSet from "../VideoBrowser/SlideJSON/sstSet";

import "./SelectedChannel.scss";
export default function SelectedChannel() {
  window.scrollTo(0, 0);
  const { state } = useLocation();
  const dispatch = useDispatch();
  let { vid_channel_data_by_id, hide_video_status } = useSelector(
    (state) => state.video
  );
  const [show, setShow] = useState(false);
  const [videoDetails, setVideoDetails] = useState({});
  const [fullscreen, setFullscreen] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const handleOpenFullScreen = () => {
    setFullscreen(!fullscreen);
  };

  const handleCloseFullScreen = () => {
    setFullscreen(false);
  };

  useEffect(() => {
    if (state.channelID) {
      dispatch(
        fetch_channel_data_by_id({
          channelId: state.channelID,
          userId:
            JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              ? JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              : state?.id,
        })
      );
    }
  }, [dispatch, state, userDetails._id, hide_video_status]);

  const handleClose = () => setShow(false);
  const handleShow = (e, item) => {
    setVideoDetails(item);
    setShow(true);
  };
  const watchHistory = (e, videoID) => {
    try {
      dispatch(
        post_watch_history({
          videoId: videoID,
          userId:
            JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              ? JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              : state?.id,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="selected-chn">
        <div className="common-container">
          {vid_channel_data_by_id !== null ? (
            <>
              <div className="user-info mb-2 pb-3">
                <div className="user-pf-left">
                  <div className="user-info__img">
                    <img
                      src={photoUrl(
                        "channelManagement",
                        vid_channel_data_by_id?.channelThumbnail
                      )}
                      alt={vid_channel_data_by_id?.channelTitle}
                    />
                  </div>
                </div>
                <div className="user-content-right mt-md-0 mt-3">
                  <div className="user-info__deta">
                    <p className="user-info__deta__name">
                      {vid_channel_data_by_id?.channelTitle}
                    </p>
                    <p className="user-info__deta__designation">
                      {vid_channel_data_by_id?.channelDescription}
                    </p>
                  </div>
                </div>
              </div>
              <div className="search-page__result">
                <div className="row">
                  {vid_channel_data_by_id?.playlists?.length !== 0 ? (
                    <>
                      {vid_channel_data_by_id?.playlists?.map((item, i) => (
                        <div className="vdo-bws__row" key={i}>
                          <div className="vdo-bws__label channel_btn">
                            <p>{item?.playlistTitle}</p>
                          </div>
                          <div className="vdo-bws__slider">
                            <div className="vdo-bws__wrapper">
                              {item.videoData.length !== 0 ? (
                                <>
                                  {/* {...sstSet} */}

                                  <Swiper
                                    slidesPerView={5.2}
                                    spaceBetween={3}
                                    // pagination={{
                                    //   clickable: true,
                                    // }}
                                    modules={[Pagination]}
                                    className="mySwiper"
                                  >
                                    {item?.videoData?.map((item, i) => (
                                      <>
                                        <SwiperSlide>
                                          {" "}
                                          <div
                                            key={i}
                                            className="rec-slider reset-foucs-v"
                                            onClick={(e) => {
                                              watchHistory(e, item._id);
                                              handleShow(e, item);
                                            }}
                                            style={{ cursor: "pointer" }}
                                          >
                                            <img
                                              width="100%"
                                              height="100%"
                                              src={`https://img.youtube.com/vi/${item.videoId}/0.jpg`}
                                              alt={item?.title}
                                            />
                                          </div>
                                        </SwiperSlide>
                                      </>
                                    ))}
                                  </Swiper>
                                </>
                              ) : (
                                <span
                                  style={{
                                    color: "white",
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "30px",
                                  }}
                                >
                                  Oops ! No video found
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {" "}
                      <span
                        style={{
                          color: "white",
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "30px",
                        }}
                      >
                        Oops ! No video found
                      </span>
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <Placeholder animation="glow">
                <Placeholder
                  className="search-page__result__img"
                  xs={6}
                  style={{
                    color: "white",
                    height: "150px",
                    width: "150px",
                    borderRadius: "50%",
                  }}
                />
              </Placeholder>
              <p>
                <Placeholder animation="glow">
                  <Placeholder
                    xs={4}
                    style={{
                      color: "white",
                    }}
                  />
                </Placeholder>
              </p>
              <Placeholder animation="glow">
                <Placeholder
                  xs={8}
                  style={{
                    color: "white",
                    height: "50px",
                    width: "150px",
                  }}
                />
              </Placeholder>
              {[1, 2, 3].map((i) => (
                <div className="vdo-bws__row mt-4" key={i}>
                  <div className="vdo-bws__label">
                    <Placeholder animation="glow">
                      <Placeholder
                        xs={4}
                        style={{
                          color: "white",
                        }}
                        className="mb-3"
                      />
                    </Placeholder>
                  </div>
                  <div className="vdo-bws__slider">
                    <div className="vdo-bws__wrapper">
                      <Slider {...sstSet}>
                        {[1, 2, 3, 4, 5, 6].map((i) => (
                          <div className="mb-4" key={i}>
                            <Placeholder animation="glow">
                              <Placeholder
                                className="rec-slider reset-foucs-v"
                                xs={6}
                                style={{
                                  color: "white",
                                  height: "200px",
                                  width: "380px",
                                }}
                              />
                            </Placeholder>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>

      <Modal
        fullscreen={fullscreen}
        show={show}
        onHide={() => {
          // watchHistory();
          handleClose();
        }}
        className={fullscreen ? "modal_pop_full" : "modal_pop"}
      >
        <VideoPopup
          videoDetails={videoDetails}
          hideVideo={handleClose}
          fullscreen={fullscreen}
          handleOpenFullScreen={handleOpenFullScreen}
          handleCloseFullScreen={handleCloseFullScreen}
        />
      </Modal>
    </>
  );
}
