/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Footer from "../../Components/Layout/Footer/Footer";
import Header from "../../Components/Layout/Header/Header";
import "./howit.scss";
import { useDispatch, useSelector } from "react-redux";

import { fetch_howitwork_data } from "../../redux/cmsSlice";

// import { photoUrl } from "../../API";
const Howworks = () => {
  const { cmsHowitwork_data, cmsHowitwork_status } = useSelector(
    (state) => state.cms
  );
  console.log(
    "🚀 ~ file: Howworks.jsx ~ line 13 ~ Howworks ~ cmsHowitwork_data",
    cmsHowitwork_data
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetch_howitwork_data());
  }, []);

  return (
    <>
      <Header />

      <div className="how-it-works common_gap">
        <div className="container my-3">
        <h2>{cmsHowitwork_data?.mainHeading}</h2>
          <div>
            <div className="col-lg-12 mb-5 mt-3">
  
            </div>
            <div >
              <h3>{cmsHowitwork_data?.subHeading1}</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: cmsHowitwork_data?.description1,
                }}
              ></p>
            </div>



            <div >
              <h3>{cmsHowitwork_data?.subHeading2}</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: cmsHowitwork_data?.description2,
                }}
              ></p>
            </div>


            <div >
              <h3>{cmsHowitwork_data?.subHeading3}</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: cmsHowitwork_data?.description3,
                }}
              ></p>
            </div>
         
          </div>
    
        </div>
      </div> 




















      {/* <div className="how-it-works common_gap">
        <div className="container my-3">
        <h2>{cmsHowitwork_data?.mainHeading}</h2>
          <div className="row px-3">
            <div className="col-lg-12 mb-5 mt-3">
  
            </div>
            <div className="col-lg-8">
              <h2>{cmsHowitwork_data?.subHeading1}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: cmsHowitwork_data?.description1,
                }}
              ></p>
            </div>
            <div className="col-lg-4 ">
              <img src="/assets/images/1.png" alt="img" />
            </div>
          </div>
          <div className="row mt-4 px-3">
            <div className="col-lg-4  order-lg-0 order-1">
              <img src="/assets/images/1.png" alt="img" />
            </div>
            <div className="col-lg-8 order-lg-1 order-0">
              <p>
                <span>Step-4</span> Lorem Ipsum is simply dummy text of the
                printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled it to make a
                type specimen book. It has survived not only five centuries, but
                also the leap into electronic typesetting, remaining essentially
                unchanged.
                <ul>
                  <li>Lorem Ipsum is simply dummy text</li>
                  <li>Lorem Ipsum is simply dummy text</li>
                  <li>Lorem Ipsum is simply dummy text</li>
                  <li>Lorem Ipsum is simply dummy text</li>
                </ul>
              </p>
              <p>
                <span>Step-5</span> Lorem Ipsum is simply dummy text of the
                printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled it to make a
                type specimen book. It has survived not only five centuries, but
                also the leap into electronic typesetting, remaining essentially
                unchanged.
              </p>
            </div>
          </div>
        </div>
      </div> */}
      <Footer />
    </>
  );
};

export default Howworks;
