import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logo_fetech } from "../../../redux/userDetailsSlice";

import "./Footer.scss";

function Footer() {
  const dispatch = useDispatch();
  const { logofetch_data } = useSelector((state) => state.userDetailsss);
  useEffect(() => {
    dispatch(
      logo_fetech({
        setting_slug: "logo",
      })
    );
  }, [dispatch]);
  return (
    <>
      {/* <div className="footer">
        <div className="footer-logo">
          <img
            src={`https://admin-brainflix.dedicateddevelopers.us/uploads/settings/${logofetch_data?.setting_value}`}
            alt=""
          />
        </div>
        <div className="container-fluid mt-4 mb-2">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-lg-flex" style={{ marginLeft: "20%" }}>
                <p className="space-text">
                  <Link to="">Privacy Policy</Link>
                </p>
                <p className="space-text">
                  <Link to="">Terms of Use</Link>
                </p>
                <p className="space-text">
                  <Link to="">Help</Link>
                </p>
                <p className="space-text">
                  <Link to="">Supported Devices</Link>
                </p>
                <p className="space-text">
                  <Link to="">About Us</Link>
                </p>
                <p className="space-text">
                  <Link to="">Donate to BrainFlix</Link>
                </p>
                <p className="space-text">
                  <Link to="contact">Contact Us</Link>
                </p>
              </div>
            </div>
          </div>

          <div className="d-lg-flex flex-wrap" style={{ marginLeft: "43%" }}>
            <a
              href="https://www.facebook.com/profile.php?id=100083080314355"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <button className="btn">
                <i className="fa-brands fa-facebook"></i>
              </button>
            </a>
            <a
              href="https://www.instagram.com/brainflixhq/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <button className="btn">
                <i className="fa-brands fa-instagram"></i>
              </button>
            </a>
            <a
              href="https://twitter.com/BrainFlix"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <button className="btn">
                <i className="fa-brands fa-twitter"></i>
              </button>
            </a>
          </div>
 
        </div>
        <div className="footer-logo__txt">
          <p>©2022 BrainFlix LLC. All Rights Reserved</p>
        </div>
      </div> */}

      <div className="footer">
        <div className="footer-logo">
          <img
            src={`https://admin-brainflix.dedicateddevelopers.us/uploads/settings/${logofetch_data?.setting_value}`}
            alt=""
          />
        </div>
        <div className="container-fluid mt-4 mb-2">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-lg-flex flex-wrap justify-content-center">
                <p className="space-text">
                  <NavLink to="/privacypolicy">Privacy Policy</NavLink>
                </p>
                <p className="space-text">
                  <NavLink to="/termscondition">Terms of Use</NavLink>
                </p>
                <p className="space-text">
                  <Link to="/help">Help</Link>
                </p>
                <p className="space-text">
                  <Link to="/supported-devices">Supported Devices</Link>
                </p>
                <p className="space-text">
                  <Link to="/about">About BrainFlix</Link>
                </p>
                <p className="space-text">
                  <Link to="/donatetobrainflix">Donate to BrainFlix</Link>
                </p>
                <p className="space-text">
                  <Link to="/contact">Contact Us</Link>
                </p>
              </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-6 m-auto">
              <div className="d-lg-flex flex-wrap justify-content-center">
                <a
                  href="https://www.facebook.com/profile.php?id=100083080314355"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <button className="btn">
                    <i className="fa-brands fa-facebook"></i>
                  </button>
                </a>
                <a
                  href="https://www.instagram.com/brainflixhq/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <button className="btn">
                    <i className="fa-brands fa-instagram"></i>
                  </button>
                </a>
                <a
                  href="https://twitter.com/BrainFlix"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <button className="btn">
                    <i className="fa-brands fa-twitter"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-logo__txt">
          <p>©2022 BrainFlix LLC. All Rights Reserved</p>
        </div>
      </div>
    </>
  );
}

export default Footer;
