import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player";
import Placeholder from "react-bootstrap/Placeholder";
import { useLocation } from "react-router-dom";
import "./popup.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  add_remove_favourite,
  fetch_favourite,
  post_hide_video,
  fetch_hide_video,
} from "../../redux/videoSlice";
import { toast } from "react-toastify";
function VideoPopup({
  videoDetails,
  hideVideo,
  handleOpenFullScreen,
  fullscreen,
  fromHide,
  handleCloseFullScreen,
}) {

  const { state } = useLocation();
  const { favourite_status, fetch_favourite_data, fetch_hide_video_data } =
    useSelector((s) => s.video);
  const dispatch = useDispatch();
  const iref = useRef(null);
  const [pausedView, setPausedView] = useState(false);
  const [EndedView, setEndedView] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [onReadyLoad, setOnReadyLoad] = useState(true);
  const [fab, setFab] = React.useState(false);
  const [hideAndUnhide, setHideAndUnhide] = useState(false);

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  useEffect(() => {
    dispatch(
      fetch_favourite({
        userId:
          JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
            ? JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
            : state?.id,
      })
    );

    dispatch(
      fetch_hide_video({
        userId:
          JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
            ? JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
            : state?.id,
      })
    );
  }, [dispatch, favourite_status, userDetails._id, state]);

  useEffect(() => {
    if (fetch_favourite_data?.length) {
      const find_fab = fetch_favourite_data
        ?.map((x) => x?.videoId)
        .find((x) => x?._id === videoDetails?._id);
      if (find_fab !== undefined) {
        setFab(true);
      } else {
        setFab(false);
      }
    }

    if (fetch_hide_video_data?.length) {
      if (fetch_hide_video_data !== "no data") {
        const find_hide = fetch_hide_video_data
          ?.map((x) => x?.videoId)
          .find((x) => x?._id === videoDetails?._id);
        if (find_hide !== undefined) {
          setHideAndUnhide(true);
        } else {
          setHideAndUnhide(false);
        }
      } else {
        setHideAndUnhide(false);
      }
    }
  }, [
    fetch_favourite_data,
    favourite_status,
    videoDetails?._id,
    fetch_hide_video_data,
  ]);

  const handlePause = () => {
    setPlaying(false);
    setPausedView(true);
  };

  const onPLay = () => {
    setPlaying(true);
    setPausedView(false);
    setEndedView(false);
  };

  const onEnd = () => {
    setPlaying(false);
    setPausedView(false);
    setEndedView(true);
  };
  const onReady = () => {
    setOnReadyLoad(false);
  };
  const addToFav = (e) => {
    dispatch(
      add_remove_favourite({
        videoId: videoDetails?._id,
        userId:
          JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
            ? JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
            : state?.id,
      })
    );
    {
      fab
        ? toast.success("Video removed from favourite")
        : toast.success("Video added to favourite");
    }
  };

  const hideVideoOnPlayer = () => {
    if (hideAndUnhide) {
      dispatch(
        post_hide_video({
          videoId: videoDetails?._id,
          userId:
            JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              ? JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              : state?.id,
        })
      );
      toast.success("Video Unhide Successfully!");
    } else {
      dispatch(
        post_hide_video({
          videoId: videoDetails?._id,
          userId:
            JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              ? JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              : state?.id,
        })
      );
      {
      }
      toast.success("Video Hide Successfully!");
    }
  };
  return (
    <div
      className="video_pop"
      style={{
        padding: "0 !important",
      }}
    >
      <Modal.Header
        closeVariant="white"
        closeButton
        onClick={handleCloseFullScreen}
      ></Modal.Header>

      <Modal.Body>
        <div className="vedioContainer">
          {/* <img src="/assets/images/close.png" alt=""  />  */}
          <ReactPlayer
            config={{
              youtube: {
                playerVars: {
                  showinfo: 0,
                  controls: 1,
                  rel: 0,
                  fs: 0,
                  autoplay: 1,
                },
              },
            }}
            url={`https://www.youtube.com/embed/${videoDetails?.videoId}`}
            onPause={handlePause}
            onEnded={onEnd}
            onPlay={onPLay}
            playing={playing}
            width={"100%"}
            height={fullscreen ? window.innerHeight : 600}
            // onPlay={(e) => console.log(ref.current.getSecondsLoaded(),"play")}
            // onSeek={(e) => console.log(e, "seek")}
            // onBuffer={(e) => console.log(e, "buffer")}
            onReady={onReady}
            ref={iref}
            // onDuration={(e) => console.log(e, "duration")}
            controls={true}
          />
          <button className="fullScreenIcon" onClick={handleOpenFullScreen}>
            <i class="fa-solid fa-expand"></i>
          </button>
          {onReadyLoad && (
            <div className="load_view">
              <Placeholder animation="glow">
                <Placeholder
                  xs={12}
                  style={{
                    color: "white",
                    width: "100%",
                    height: 600,
                  }}
                />
              </Placeholder>
            </div>
          )}
          {pausedView && (
            <div
              className={fullscreen ? "paused_view large" : "paused_view"}
              style={{
                height: fullscreen ? window.innerHeight : 600,
              }}
              onClick={onPLay}
            >
              <div className="company-logo">
                {/* <img src="/assets/images/logo.svg" alt=""></img> */}
              </div>

              <i
                className="fa fa-play"
                style={{
                  color: "white",
                  fontSize: "30px",
                  cursor: "pointer",
                }}
              ></i>
              {/* <div className="video-text">
                <p>Thank you Wathcing Brain Flix</p>
              </div> */}
            </div>
          )}
          {EndedView && (
            <div
              className="paused_view"
              style={{
                height: fullscreen ? window.innerHeight : 600,
              }}
              onClick={onPLay}
            >
              <div className="company-logo">
                {/* <img src="/assets/images/logo.svg" alt=""></img> */}
              </div>
              <i
                className="fa fa-repeat"
                style={{
                  color: "white",
                  fontSize: "30px",
                  cursor: "pointer",
                }}
              ></i>
              {/* <div className="video-text">
                <p>Thank you Wathcing Brain Flix</p>
              </div>  */}
            </div>
          )}
        </div>{" "}
        {!fullscreen && (
          <div>
            <h3>{videoDetails?.title}</h3>
            <span
              style={{ color: "white" }}
              dangerouslySetInnerHTML={{
                __html: `${videoDetails?.description}`,
              }}
            ></span>
            <div className="d-flex">
              <p>{new Date(videoDetails?.uploadedDate).toDateString()}</p>

              <p className="px-4">| {videoDetails?.length} Min</p>
              <p>| {videoDetails?.videoChannel?.channelTitle} </p>

              <p style={{ marginLeft: "5px" }}>
                | {videoDetails?.likeCount} Likes
              </p>
            </div>

            <p className="mb-2">{videoDetails?.videoCategory?.title}</p>

            <div className=" sadada">
              {fromHide === "hide" ? (
                <></>
              ) : (
                <p style={{ cursor: "pointer" }} onClick={addToFav}>
                  <i
                    className="fa-solid fa-heart mx-2"
                    style={{ color: fab && "red" }}
                  ></i>
                  {`Click to ${fab ? "remove from" : "add to"}  favorite`}
                </p>
              )}
              <div className="hideright">   <p
                onClick={() => {
                  hideVideoOnPlayer();
                  hideVideo();
                }}
                style={{ cursor: "pointer" }}
              >
                <i
                  className={`fa-solid ${
                    hideAndUnhide ? "fa-eye-slash" : "fa-eye"
                  } mx-2`}
                ></i>
                {`Click to ${hideAndUnhide ? "Unhide" : "Hide"} this
            video`}
              </p></div>
           



            </div>

            {/* <p>
              <i className="fa-solid fa-thumbs-up mx-2"></i>{" "}
              {videoDetails?.likeCount} Likes
            </p> */}
          </div>
        )}
        {console.log(
          "🚀 ~ file: VideoPopup.jsx ~ line 321 ~ videoDetails",
          videoDetails
        )}
      </Modal.Body>
    </div>
  );
}

export default VideoPopup;
