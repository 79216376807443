// jon@yopmail.com 123456789

//figma => https://www.figma.com/file/HJ9hW9puaidmZp2RIvnvEl/MJS-%E2%80%93-Shisha-Web?node-id=823%3A34773
export const endpoint = {
  aboutBrainFlix: "about-brainflix/details",
  login: "user/signin",
  signup: "user/signup",
  howitworklist: "how-it-work/list",
  testimoniallist: "testimonial/list",
  planlist: "plan-management/list",
  userdetails: "user/details",
  socialLogin: "user/social-signup",
  changePassword: "user/change-password",
  forgotPassword: "user/forgot-password",
  videoGetByID: "video-management/fetchVideoByURL",
  allVideoList: "video-management/list-all",
  categoryWiseList: "category-wise-video-list",
  categoryByIDList: "video-management/list-by-category",
  recommendedvideo: "recommended-video/",
  channelManagement: "channel-management/list",
  logout: "user/logout",
  addsubuser: "add-profile/add-sub-user",
  subuserDetails: "user/sub-user-details",
  allavter: "add-profile/fetch-all-avatars",
  favourite: "favvideo/favourite",
  fetch_favourite: "favvideo/all-favourite-videos",
  channelDataByID: "video-management/list-by-channel",
  subuser_delete: "add-profile/delete-sub-user",
  Sub_User_edit: "add-profile/update-sub-user",
  createWatchHistory: "watch-history/create",
  getWatchHistory: "watch-history/list",
  postHideVideo: "hide-unhide-video/hide",
  getAllHideVideo: "hide-unhide-video/all-hidden-videos",
  feebackApi: "feedback-management/submit",
  contactApi: "contact-us/insert",
  userbeta: "brainflix-beta/create",
  cmsgeneral: "general-cms/list",
  search_list_api: "video-management/list-all",
  userCardDetails: "user/take-subscription",
  Parentprofileupdate: "user/update-profile",
  logodata: "setting/detail",
  topsection: "top-section/details",
  betcms:"brainflix-beta-cms/details"
};
