import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../API";
import { endpoint } from "../API/endpoint";

/* =========== GET VIDEO BY ID ============= */
export const fetch_video_by_id = createAsyncThunk(
  "/video-management/fetchVideoByURL",
  async (id) => {
    try {
      let res = await axiosInstance.get(
        `${endpoint.videoGetByID}?videoId=${id}`
      );
      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);
/* =========== GET VIDEO BY ID ============= */
/* =========== GET ALL VIDEO =============== */
export const fetch_all_video = createAsyncThunk(
  "video-management/list-all",
  async (id) => {
    try {
      let res = await axiosInstance.get(endpoint.allVideoList);
      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

/* =========== GET ALL VIDEO =============== */

/* =========== GET CATEGORY WISE ALL VIDEO =============== */
export const fetch_category_wise_all_video = createAsyncThunk(
  "video-management/category-wise-video-list",
  async (obj) => {
    try {
      let res = await axiosInstance.post(endpoint.categoryWiseList, obj);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

/* =========== GET CATEGORY WISE ALL VIDEO =============== */

/* =========== GET CATEGORY BY ID LIST =============== */
export const fetch_category_by_id = createAsyncThunk(
  "video-management/list-by-category",
  async (obj) => {
    try {
      let res = await axiosInstance.post(endpoint.categoryByIDList, obj);
      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);
/* =========== GET CATEGORY BY ID LIST =============== */

/* =========== GET Recomended  LIST =============== */
export const fetch_Recommended_video = createAsyncThunk(
  "api/recommended-video/",
  async (obj) => {
    try {
      let res = await axiosInstance.post(endpoint.recommendedvideo, obj);
      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);
/* =========== GET Recomended  LIST =============== */
/* =========== GET ALL Channel list =============== */
export const fetch_all_channel_list = createAsyncThunk(
  "video-management/fetch_all_channel_list",
  async (obj) => {
    try {
      let res = await axiosInstance.post(endpoint.channelManagement, obj);
      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

/* =========== = GET ALL Channel list  =============== */
/* =========== POST  Channel  by id list =============== */

export const fetch_channel_data_by_id = createAsyncThunk(
  "video-management/list-by-channel",
  async (obj) => {
    try {
      let res = await axiosInstance.post(endpoint?.channelDataByID, obj);
      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

/* =========== = POST  Channel by id list  =============== */

// add fav
export const add_remove_favourite = createAsyncThunk(
  "video-management/favourite",
  async (id) => {
    try {
      let res = await axiosInstance.post(endpoint.favourite, id);
      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);
/// fetch fav video
export const fetch_favourite = createAsyncThunk(
  "video-management/fetch_favourite",
  async (obj) => {
    try {
      let res = await axiosInstance.post(endpoint.fetch_favourite, obj);
      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);
/* =========== POST  WATCH HISTORY =============== */
export const post_watch_history = createAsyncThunk(
  "watch-history/create",
  async (obj) => {
    try {
      let res = await axiosInstance.post(endpoint?.createWatchHistory, obj);
      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

/* =========== = POST WATCH HISTORY =============== */
/* =========== GET  WATCH HISTORY =============== */
export const fetch_watch_history = createAsyncThunk(
  "watch-history/list",
  async (obj) => {
    try {
      let res = await axiosInstance.post(endpoint?.getWatchHistory, obj);
      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

/* =========== = GET WATCH HISTORY =============== */

/* =========== POST HIDE VIDEO =============== */
export const post_hide_video = createAsyncThunk(
  "hide-unhide-video/hide",
  async (obj) => {
    try {
      let res = await axiosInstance.post(endpoint?.postHideVideo, obj);
      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

/* ============ POST HIDE VIDEO =============== */

/* =========== GET HIDE VIDEO =============== */
export const fetch_hide_video = createAsyncThunk(
  "hide-unhide-video/all-hidden-videos",
  async (obj) => {
    try {
      let res = await axiosInstance.post(endpoint?.getAllHideVideo, obj);
      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

/* ============ GET HIDE VIDEO =============== */
/* +++++++++++++ INITIAL STATE ++++++++++++++ */
const initialState = {
  vidGet_ByID_data: "",
  vidGet_ByID_Status: "idle",

  vidGet_all: {},
  vidGet_all_Status: "idle",

  vid_category_wise_get_all: [],
  vid_category_wise_status: "idle",

  vid_category_by_id: undefined,
  vid_category_by_id_status: "idle",

  fetch_Recommended_video_status: "idle",
  fetch_Recommended_video_data: null,

  fetch_all_channel_data: null,
  fetch_all_channel_status: "idle",

  vid_channel_data_by_id: null,
  vid_channel_data_by_id_status: "idle",
  //
  favourite_status: "idle",
  //
  fetch_favourite_status: "idle",
  fetch_favourite_data: null,

  fetch_watch_history_status: "idle",
  fetch_watch_history_data: null,

  hide_video_status: "idle",
  fetch_hide_video_status: "idle",
  fetch_hide_video_data: null,
};
/* +++++++++++++ INITIAL STATE ++++++++++++++ */

/* CALL ALL SLICE */
export const videoSlice = createSlice({
  name: "videoSlice",
  initialState,
  extraReducers: {
    /* ====== GET VIDEO BY ID ===== */
    [fetch_video_by_id.pending]: (state) => {
      state.vidGet_ByID_Status = "loading";
      state.vidGet_ByID_data = "";
    },
    [fetch_video_by_id.fulfilled]: (state, { payload }) => {
      state.vidGet_ByID_Status = "idle";
      state.vidGet_ByID_data = payload?.data;
    },
    [fetch_video_by_id.rejected]: (state) => {
      state.vidGet_ByID_Status = "idle";
    },
    /* ====== GET VIDEO BY ID ===== */

    /* ====== GET VIDEO ALL ===== */
    [fetch_all_video.pending]: (state) => {
      state.vidGet_all_Status = "loading";
      state.vidGet_all = {};
    },
    [fetch_all_video.fulfilled]: (state, { payload }) => {
      state.vidGet_all_Status = "idle";
      if (payload?.type === "success") {
        state.vidGet_all = payload?.data;
      } else {
        state.vidGet_all = "payload?.data";
      }
    },
    [fetch_all_video.rejected]: (state) => {
      state.vidGet_all_Status = "idle";
    },
    /* ====== GET VIDEO ALL ===== */

    /* ====== GET CATEGORY WISE ALL VIDEO ===== */
    [fetch_category_wise_all_video.pending]: (state) => {
      state.vid_category_wise_status = "loading";
      state.vid_category_wise_get_all = [];
    },
    [fetch_category_wise_all_video.fulfilled]: (state, { payload }) => {
      state.vid_category_wise_status = "idle";
      if (payload?.type === "success") {
        state.vid_category_wise_get_all = payload?.data;
      } else {
        state.vid_category_wise_get_all = "payload?.data";
      }
    },
    [fetch_category_wise_all_video.rejected]: (state) => {
      state.vid_category_wise_status = "idle";
    },
    /* ====== GET CATEGORY WISE ALL VIDEO ===== */

    /* ====== GET CATEGORY BY ID ===== */
    [fetch_category_by_id.pending]: (state) => {
      state.vid_category_by_id_status = "loading";
      state.vid_category_by_id = undefined;
    },
    [fetch_category_by_id.fulfilled]: (state, { payload }) => {
      state.vid_category_by_id_status = "idle";
      if (payload?.type === "success") {
        state.vid_category_by_id = payload?.data;
      } else {
        state.vid_category_by_id = "payload?.data";
      }
    },
    [fetch_category_by_id.rejected]: (state) => {
      state.vid_category_by_id_status = "idle";
    },
    /* ====== GET CATEGORY BY ID ===== */

    /* ====== GET Recomended ===== */
    [fetch_Recommended_video.pending]: (state) => {
      state.fetch_Recommended_video_status = "loading";
      state.fetch_Recommended_video_data = null;
    },
    [fetch_Recommended_video.fulfilled]: (state, { payload }) => {
      state.fetch_Recommended_video_status = "idle";
      if (payload?.type === "success") {
        state.fetch_Recommended_video_data = payload?.data;
      } else {
        state.fetch_Recommended_video_data = "No Data Found";
      }
    },
    [fetch_Recommended_video.rejected]: (state) => {
      state.fetch_Recommended_video_status = "idle";
    },
    /* ====== GET Recomended ===== */
    /* ====== GET channel list ===== */
    [fetch_all_channel_list.pending]: (state) => {
      state.fetch_all_channel_status = "loading";
      state.fetch_all_channel_data = null;
    },
    [fetch_all_channel_list.fulfilled]: (state, { payload }) => {
      state.fetch_all_channel_status = "idle";
      if (payload?.type === "success") {
        state.fetch_all_channel_data = payload?.data;
      } else {
        state.fetch_all_channel_data = "";
      }
    },
    [fetch_all_channel_list.rejected]: (state) => {
      state.fetch_all_channel_status = "idle";
    },
    /* ====== GET channel list ===== */

    /* ====== POST channel by id list ===== */
    [fetch_channel_data_by_id.pending]: (state) => {
      state.vid_channel_data_by_id_status = "loading";
      state.vid_channel_data_by_id = null;
    },
    [fetch_channel_data_by_id.fulfilled]: (state, { payload }) => {
      state.vid_channel_data_by_id_status = "idle";
      if (payload?.type === "success") {
        state.vid_channel_data_by_id = payload?.data;
      } else {
        state.vid_channel_data_by_id = "no data found";
      }
    },
    [fetch_channel_data_by_id.rejected]: (state) => {
      state.vid_channel_data_by_id_status = "idle";
    },
    /* ====== POST channel by id list ===== */
    //add_remove_favourite
    [add_remove_favourite.pending]: (state) => {
      state.favourite_status = "loading";
    },
    [add_remove_favourite.fulfilled]: (state, { payload }) => {
      state.favourite_status = "idle";
      if (payload?.type === "success") {
        // state.fetch_all_channel_data = payload?.data;
      } else {
        // state.fetch_all_channel_data = [];
      }
    },
    [add_remove_favourite.rejected]: (state) => {
      state.favourite_status = "idle";
    },
    //fetch favourite data
    [fetch_favourite.pending]: (state) => {
      state.fetch_favourite_status = "loading";
      state.fetch_favourite_data = null;
    },
    [fetch_favourite.fulfilled]: (state, { payload }) => {
      state.fetch_favourite_status = "idle";
      if (payload?.type === "success") {
        state.fetch_favourite_data = payload?.data?.docs;
        // toast.success("Successfully");
      }
    },
    [fetch_favourite.rejected]: (state) => {
      state.fetch_favourite_status = "idle";
    },

    /* ====== GET WATCH HISTORY list ===== */
    [fetch_watch_history.pending]: (state) => {
      state.fetch_watch_history_status = "loading";
      state.fetch_watch_history_data = null;
    },
    [fetch_watch_history.fulfilled]: (state, { payload }) => {
      state.fetch_watch_history_status = "idle";
      if (payload?.type === "success") {
        state.fetch_watch_history_data = payload?.data;
      } else {
        state.fetch_watch_history_data = "no data found";
      }
    },
    [fetch_watch_history.rejected]: (state) => {
      state.fetch_watch_history_status = "idle";
    },
    /* ====== GET WATCH HISTORY list ===== */
    //add_remove_favourite
    [post_hide_video.pending]: (state) => {
      state.hide_video_status = "loading";
    },
    [post_hide_video.fulfilled]: (state, { payload }) => {
      state.hide_video_status = "idle";
      if (payload?.type === "success") {
        // state.fetch_all_channel_data = payload?.data;
      } else {
        // state.fetch_all_channel_data = [];
      }
    },
    [post_hide_video.rejected]: (state) => {
      state.hide_video_status = "idle";
    },
    /* ====== GET HIDE VIDEO list ===== */
    [fetch_hide_video.pending]: (state) => {
      state.fetch_hide_video_status = "loading";
      state.fetch_hide_video_data = null;
    },
    [fetch_hide_video.fulfilled]: (state, { payload }) => {
      state.fetch_hide_video_status = "idle";
      if (payload?.type === "success") {
        state.fetch_hide_video_data = payload?.data;
      } else {
        state.fetch_hide_video_data = "no data";
      }
    },
    [fetch_hide_video.rejected]: (state) => {
      state.fetch_hide_video_status = "idle";
    },
    /* ====== GET HIDE VIDEO list  ===== */
  },
});
/* CALL ALL SLICE */
