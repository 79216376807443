import { getApp, getApps, initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore"
let app;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  // measurementId: process.env.REACT_APP_measurementId,
};



// console.log("con",firebaseConfig)

if (getApps().length) {
  app = getApp();
} else {
  app = initializeApp(firebaseConfig);
}

export default app;