import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  fetch_plan_data,
  fetch_general_cms,
  fetch_general_cms1,
  fetch_general_cms2Thank,
} from "../../redux/cmsSlice";
import { logo_fetech, post_card_details } from "../../redux/userDetailsSlice";
import Placeholder from "react-bootstrap/Placeholder";
import { checkAmnt } from "../../../src/helpers/utilts";
const Subcription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    cmsPlan_data,
    cmsgeneral_data,
    cmsgeneral_data1,
    cmsgeneral_data_thankyou,
  } = useSelector((state) => state.cms);
 
  const { userCardDetails, userCardDetails_status } = useSelector(
    (state) => state.userDetailsss
  );
  const { userDetails } = useSelector((s) => s.auth);
  const userDataSubcription = JSON.parse(
    localStorage.getItem("brainFlix_token_subcription_data")
  );
  const [subcriptionDetails, setSubcriptionDetails] = useState({
    card_holder_name: "",
    card_number: "",
    exp_month: "",
    exp_year: "",
    cvv: "",
    plan_id: "",
    code:
      userDetails?.userData?.referralParent?.referralCode ||
        userDataSubcription?.referralParent?.referralCode
        ? userDetails?.userData?.referralParent?.referralCode ||
        userDataSubcription?.referralParent?.referralCode
        : "",
  });
  const [error, setError] = useState({
    card_holder_name: "",
    card_number: "",
    exp_month: "",
    exp_year: "",
    cvv: "",
    plan_id: "",
  });
  useEffect(() => {
    dispatch(fetch_plan_data());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetch_general_cms({
        slug: "take-subscription-footer",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetch_general_cms2Thank({
        slug: "thank-you-signup",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetch_general_cms1({
        slug: "take-subscription-header",
      })
    );
  }, [dispatch]);
  useEffect(() => {
    document.body.classList.add("no-header");
    document.body.classList.add("width_bg");
    return () => {
      document.body.classList.remove("no-header");
      document.body.classList.remove("width_bg");
    };
  }, []);

  const handleChangeForPlan = (e) => {
    const { value, type } = e.target;
    if (type === "radio") {
      if (value.length === 0) {
        setError({
          ...error,
          plan_id: "Please Select a Plan",
        });
        setSubcriptionDetails({ ...subcriptionDetails, plan_id: value });
      } else {
        setError({
          ...error,
          plan_id: "",
        });
        setSubcriptionDetails({ ...subcriptionDetails, plan_id: value });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "card_number") {
      if (checkAmnt(value)) {
        if (value.length === 0) {
          setError({
            ...error,
            card_number: "Please enter your Card Number",
          });
          setSubcriptionDetails({ ...subcriptionDetails, [name]: value });
        } else {
          setError({
            ...error,
            card_number: "",
          });
          setSubcriptionDetails({ ...subcriptionDetails, [name]: value });
        }
      }
    }

    if (name === "exp_month") {
      if (checkAmnt(value)) {
        if (value.length === 0) {
          setError({
            ...error,
            exp_month: "Please enter your Expire Month",
          });
          setSubcriptionDetails({ ...subcriptionDetails, [name]: value });
        } else {
          setError({
            ...error,
            exp_month: "",
          });
          setSubcriptionDetails({ ...subcriptionDetails, [name]: value });
        }
      }
    }
    if (name === "exp_year") {
      if (checkAmnt(value)) {
        if (value.length === 0) {
          setError({
            ...error,
            exp_year: "Please enter your Expire Year",
          });
          setSubcriptionDetails({ ...subcriptionDetails, [name]: value });
        } else {
          setError({
            ...error,
            exp_year: "",
          });
          setSubcriptionDetails({ ...subcriptionDetails, [name]: value });
        }
      }
    }
    if (name === "card_holder_name") {
      if (value.length === 0) {
        setError({
          ...error,
          card_holder_name: "Please enter your Card Holder Name",
        });
        setSubcriptionDetails({ ...subcriptionDetails, [name]: value });
      } else {
        setError({
          ...error,
          card_holder_name: "",
        });
        setSubcriptionDetails({ ...subcriptionDetails, [name]: value });
      }
    }
    if (name === "cvv") {
      if (checkAmnt(value)) {
        if (value.length === 0) {
          setError({
            ...error,
            cvv: "Please enter your CVV Number",
          });
          setSubcriptionDetails({ ...subcriptionDetails, [name]: value });
        } else {
          setError({
            ...error,
            cvv: "",
          });
          setSubcriptionDetails({ ...subcriptionDetails, [name]: value });
        }
      }
    }
    if (name === "code") {
      setSubcriptionDetails({ ...subcriptionDetails, [name]: value });
    }
  };

  const restErr = () => {
    setError({
      card_holder_name: "",
      card_number: "",
      exp_month: "",
      exp_year: "",
      cvv: "",
      plan_id: "",
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    restErr();
    if (
      subcriptionDetails.plan_id === "" &&
      subcriptionDetails.card_number.length === 0 &&
      subcriptionDetails.exp_month.length === 0 &&
      subcriptionDetails.exp_year.length === 0 &&
      subcriptionDetails.card_holder_name.length === 0 &&
      subcriptionDetails.cvv.length === 0 &&
      subcriptionDetails.plan_id.length === 0
    ) {
      setError({
        // plan: "Please Select a Plan",
        card_number: "Please enter your Card Number",
        exp_month: "Please enter your Expire Month",
        exp_year: "Please enter your Expire Year",
        card_holder_name: "Please enter your card holder name",
        cvv: "Please enter your CVV",
        plan_id: "Please select a plan",
      });
    } else if (subcriptionDetails.plan_id.length === 0) {
      setError({
        ...error,
        plan_id: "Please Select a Plan",
      });
    } else if (subcriptionDetails.card_holder_name.length === 0) {
      setError({
        ...error,
        card_holder_name: "Please enter your card holder name",
      });
    } else if (subcriptionDetails.card_number.length === 0) {
      setError({
        ...error,
        card_number: "Please enter your Card Number",
      });
    } else if (subcriptionDetails.exp_month.length === 0) {
      setError({
        ...error,
        exp_month: "Please enter your Expire Month",
      });
    } else if (subcriptionDetails.exp_year.length === 0) {
      setError({
        ...error,
        exp_year: "Please enter your Expire Year",
      });
    } else if (subcriptionDetails.cvv.length === 0) {
      setError({
        ...error,
        cvv: "Please enter your CVV",
      });
    } else {
      dispatch(post_card_details(subcriptionDetails));
    }
  };

  const goToLoginPage = () => {
    navigate("/");
    localStorage.removeItem("brainFlix_token_subcription_token");
    window.location.reload();
  };
  const { user_data, logofetch_data } = useSelector(
    (state) => state.userDetailsss
  );

  // useEffect(() => {
  //   dispatch(
  //     logo_fetech({
  //       setting_slug: "logo",
  //     })
  //   );
  // });

  return (
    <div>
      <div className="loginPAge">
        <div className="loginPageHead">
          {/* <Link to="/" className="logo">
          
            <img src="/assets/images/logo.svg" alt="brain" />
          </Link> */}
          <NavLink to="/" className="logo-af">
            {/* <img src="/assets/images/logo.svg" alt="" /> */}
            <img
              id="logoo"
              src="/assets/images/1658999284845_android-chrome-192x192.png"
              alt=""
            />
            {/* <img
              src={`https://admin-brainflix.dedicateddevelopers.us/uploads/settings/${logofetch_data?.setting_value}`}
              alt=""
            /> */}
            {/* setting_value */}
          </NavLink>
        </div>
        {userCardDetails?.isSubscribed ? (
          <>
            <div className="thank-you-sec">
              <div className="thank-you-heading">
                <h5
                  dangerouslySetInnerHTML={{
                    __html: `${cmsgeneral_data_thankyou?.title}`,
                  }}
                ></h5>
              </div>
              <div className="heading-text">
                <p
                  dangerouslySetInnerHTML={{
                    __html: `${cmsgeneral_data_thankyou?.content}`,
                  }}
                ></p>
              </div>
              <div className="thank-you-button">
                <button className="btn btn-primary" onClick={goToLoginPage}>
                  CONTINUE TO CREATE YOUR PROFILE
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="plane-new-sec">
              <div className="new-plane-heading">
                <h5>Choose the right plan for your family</h5>
                <p
                  dangerouslySetInnerHTML={{
                    __html: `${cmsgeneral_data1?.content}`,
                  }}
                >
                  {/* All plans include up to 4 profiles and come with a{" "}
                  <b>30-day trial period.</b> After that, you are enrolled in
                  automatic payments of the subscription fee (plus tax where
                  applicable) that will continue until you cancel Pricing
                  subject to change. <b>Your rate is locked in</b> as long as
                  you remain an active subscriber of the same plan. */}
                </p>
              </div>
              <div className="subcription-sec dasdada">
                {cmsPlan_data !== null ? (
                  <>
                    {cmsPlan_data !== "no data" ? (
                      <>
                        {cmsPlan_data?.docs
                          ?.filter((item) => item?.isVisible === true)
                          ?.map((item, i) => (
                            <div
                              className="sub-wrapper"
                              key={i}
                            // onClick={(e) =>
                            //   setSubcriptionDetails({
                            //     ...subcriptionDetails,
                            //     plan_id: item?._id,
                            //   })
                            // }
                            >
                              <input
                                type="radio"
                                name="plan_id"
                                value={item?._id}
                                onChange={handleChangeForPlan}
                              />

                              <div className="yearly_pop">
                                {item?.planDuration === "Annually" && (
                                  <span style={{ background: "#393939" }}>
                                    Popular
                                  </span>
                                )}
                                <div className="year-sec  blueclass">
                                  <h5>
                                    {item?.planDuration}
                                    {item?.planDuration === "Annually" ? (
                                      <> ${item?.planPrice}/yr</>
                                    ) : (
                                      <> ${item?.planPrice}/mo</>
                                    )}
                                  </h5>
                                  {item?.planFeatures.map((item, i) => (
                                    <p key={i}>{item}</p>
                                  ))}
                                </div>
                              </div>
                              <span
                                style={{ color: "red", background: "none" }}
                              >
                                {error.plan_id}
                              </span>
                            </div>
                          ))}
                      </>
                    ) : (
                      <>
                        <span
                          style={{
                            color: "white",
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "20px",
                          }}
                        >
                          Oops ! No plans is here
                        </span>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {[1, 2].map((i) => (
                      <div className="sub-wrapper" key={i}>
                        <input type="radio" name="checkSub" />
                        <div className="yearly_pop">
                          <div className="year-sec  blueclass">
                            <h5>
                              <Placeholder animation="glow">
                                <Placeholder
                                  xs={4}
                                  style={{
                                    color: "white",
                                  }}
                                  className="mb-3 mr-3"
                                />
                              </Placeholder>
                            </h5>
                            {[1, 2, 3, 4].map((i) => (
                              <Placeholder animation="glow" key={i}>
                                <Placeholder
                                  xs={4}
                                  style={{
                                    color: "white",
                                  }}
                                  className="mb-3 mr-3"
                                />
                              </Placeholder>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
              <div className="form-sec">
                <div className="form-heading">
                  <div className="headding-inner">
                    <h5>Credit Card Information</h5>
                  </div>
                  <div className="heading-pic">
                    <img src="assets/images/visa-card.png" alt="" />
                    <img src="assets/images/master-card.png" alt="" />
                    <img src="assets/images/discover-card.png" alt="" />
                    <img src="assets/images/american-card.png" alt="" />
                  </div>
                </div>
                <form>
                  <div className="from-group">
                    <label>Name on Card</label>
                    <input
                      type="text"
                      name="card_holder_name"
                      value={subcriptionDetails.card_holder_name}
                      onChange={handleChange}
                      placeholder="Card Holder Name"
                    />
                    <span style={{ color: "red" }}>
                      {error.card_holder_name}
                    </span>
                  </div>

                  <div className="from-group">
                    <label>Card Number</label>
                    {/* <InputMask
                      mask="9999-9999-9999-9999"
                      type="tel"
                      maskChar={null}
                      name="card_number"
                      maskvalue={subcriptionDetails.card_number}
                      onChange={handleChange}
                     /> */}
                    {/*                       
                    </InputMask> */}
                    <input
                      type="tel"
                      name="card_number"
                      placeholder="Card Number"
                      maxLength={16}
                      value={subcriptionDetails.card_number}
                      onChange={handleChange}
                    />
                    <span style={{ color: "red" }}>{error.card_number}</span>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="from-group">
                        <label>Exp Month</label>
                        <input
                          type="tel"
                          name="exp_month"
                          placeholder="Expire month"
                          value={subcriptionDetails.exp_month}
                          onChange={handleChange}
                          maxLength={2}
                        />
                        <span style={{ color: "red" }}>{error.exp_month}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="from-group">
                        <label>Exp Year</label>
                        <input
                          type="tel"
                          name="exp_year"
                          placeholder="Expire year"
                          value={subcriptionDetails.exp_year}
                          onChange={handleChange}
                          maxLength={4}
                        />
                        <span style={{ color: "red" }}>{error.exp_year}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="from-group">
                        <label>CVV Code</label>
                        <input
                          type="tel"
                          name="cvv"
                          value={subcriptionDetails.cvv}
                          onChange={handleChange}
                          placeholder="Your CVV"
                          maxLength={3}
                        />
                        <span style={{ color: "red" }}>{error.cvv}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="from-group">
                        <label>
                          PROMO/REFERRAL CODE
                          {/* (OPTIONAL) */}
                        </label>
                        <input
                          type="text"
                          name="code"
                          value={subcriptionDetails.code}
                          placeholder="Optional"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </form>
                <p
                  dangerouslySetInnerHTML={{
                    __html: `${cmsgeneral_data?.content}`,
                  }}
                >
                  {/* By clicking "Agree & Subscribe, you agree to our Subscriber
                  Agreement. You can cancel at any time in your account setting
                  or by contacting us, effective at the end of the billling
                  period. */}
                </p>
                <div className="agree-button">
                  <button
                    className="btn btn-primary"
                    onClick={handleSubmit}
                    disabled={userCardDetails_status === "loading"}
                  >
                    {userCardDetails_status === "loading" && (
                      <span
                        className="spinner-border spinner-border-sm mx-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    AGREE & SUBSCRIBE
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Subcription;
