import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import settings from "../VideoBrowser/SlideJSON/settings";
import { useLocation } from "react-router-dom";
import {
  fetch_category_by_id,
  fetch_Recommended_video,
  post_watch_history,
} from "../../redux/videoSlice";
import Placeholder from "react-bootstrap/Placeholder";
import Modal from "react-bootstrap/Modal";
import VideoPopup from "../VideoPopUp/VideoPopup";
import "./ShowVideoCategory.scss";

const ShowVideoCategory = () => {
  window.scrollTo(0, 0);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const {
    vid_category_by_id,
    fetch_Recommended_video_data,
    hide_video_status,
  } = useSelector((state) => state.video);
  const [show, setShow] = useState(false);
  const [videoDetails, setVideoDetails] = useState({});
  const [fullscreen, setFullscreen] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const handleOpenFullScreen = () => {
    setFullscreen(!fullscreen);
  };

  const handleCloseFullScreen = () => {
    setFullscreen(false);
  };
  useEffect(() => {
    if (state?.id) {
      dispatch(
        fetch_category_by_id({
          categoryId: state?.id,
          userId:
            JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              ? JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              : state?.id,
        })
      );
      if (
        JSON.parse(localStorage.getItem("subUserId")) ||
        state?.id ||
        userDetails._id
      ) {
        dispatch(
          fetch_Recommended_video({
            userId:
              JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
                ? JSON.parse(localStorage.getItem("subUserId")) ||
                  userDetails._id
                : state?.id,
          })
        );
      }
    }
  }, [dispatch, state, userDetails._id, hide_video_status]);
  const handleClose = () => setShow(false);
  const handleShow = (e, item) => {
    setVideoDetails(item);
    setShow(true);
  };
  const watchHistory = (e, videoID) => {
    try {
      dispatch(
        post_watch_history({
          videoId: videoID,
          userId:
            JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              ? JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              : state?.id,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="vdo-bws">
        <div className="common-container">
          {fetch_Recommended_video_data !== null ? (
            <>
              <div className="vdo-bws__row">
                <div className="vdo-bws__label">
                  <p>Recommended For You</p>
                </div>
                <div className="vdo-bws__slider">
                  <div className="vdo-bws__wrapper">
                    {fetch_Recommended_video_data?.docs[0]?.recommendedVideos
                      .length !== 0 ? (
                      <>
                        <Slider {...settings}>
                          {fetch_Recommended_video_data?.docs[0]?.recommendedVideos?.map(
                            (item, i) => (
                              <>
                                <div
                                  key={i}
                                  className="rec-slider reset-foucs-v"
                                  onClick={(e) => {
                                    watchHistory(e, item._id);
                                    handleShow(e, item);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    width="100%"
                                    height="100%"
                                    src={`https://img.youtube.com/vi/${item?.videoId}/hqdefault.jpg`}
                                    alt={item?.title}
                                  />
                                </div>
                              </>
                            )
                          )}
                        </Slider>
                      </>
                    ) : (
                      <>
                        <span
                          style={{
                            color: "white",
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "30px",
                          }}
                        >
                          Oops ! No Video found
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="vdo-bws__row">
              <div className="vdo-bws__label">
                <Placeholder animation="glow">
                  <Placeholder
                    xs={4}
                    style={{
                      color: "white",
                    }}
                    className="mb-3"
                  />
                </Placeholder>
              </div>
              <div className="vdo-bws__slider">
                <div className="vdo-bws__wrapper">
                  <Slider {...settings}>
                    {[1, 2, 3, 4].map((i) => (
                      <div className="mb-4" key={i}>
                        <Placeholder animation="glow">
                          <Placeholder
                            className="rec-slider reset-foucs-v"
                            xs={6}
                            style={{
                              color: "white",
                              height: "200px",
                              width: "380px",
                            }}
                          />
                        </Placeholder>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          )}
          {vid_category_by_id !== undefined ? (
            <>
              <div className="search-page__result">
                <div className="row">
                  <div className="vdo-bws__label channel_btn">
                    <p>{state?.name}</p>
                  </div>
                  {vid_category_by_id?.docs.length !== 0 && (
                    <>
                      {vid_category_by_id?.docs?.map((item, i) => (
                        <div
                          className="col-lg-3 col-md-6 mb-4"
                          key={i}
                          onClick={(e) => {
                            watchHistory(e, item._id);
                            handleShow(e, item);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="rec-slider reset-foucs-v">
                            <img
                              src={`https://img.youtube.com/vi/${item.videoId}/0.jpg`}
                              alt={item?.title}
                            />
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="search-page__result">
                <div className="row">
                  <Placeholder animation="glow">
                    <Placeholder
                      xs={4}
                      style={{
                        color: "white",
                      }}
                      className="mb-3"
                    />
                  </Placeholder>
                  {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
                    <div className="col-lg-3 col-md-3 col-12 mb-4" key={i}>
                      <Placeholder animation="glow">
                        <Placeholder
                          className="rec-slider reset-foucs-v"
                          xs={6}
                          style={{
                            color: "white",
                            height: "200px",
                            width: "300px",
                          }}
                        />
                      </Placeholder>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Modal
        fullscreen={fullscreen}
        show={show}
        onHide={() => {
          // watchHistory();
          handleClose();
        }}
        c
        className={fullscreen ? "modal_pop_full" : "modal_pop"}
      >
        <VideoPopup
          videoDetails={videoDetails}
          hideVideo={handleClose}
          fullscreen={fullscreen}
          handleOpenFullScreen={handleOpenFullScreen}
          handleCloseFullScreen={handleCloseFullScreen}
        />
      </Modal>
    </>
  );
};

export default ShowVideoCategory;
