/* eslint-disable no-useless-escape */
import loadash from "lodash";

//spcl
export const passwordRegex = new RegExp("^(?=.*[A-Z])");
export const passspecial = new RegExp("^(?=.*[!@#$%^&*_()])");

//Regex
export function validEmail(elementValue) {
  // var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  var emailPattern =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return emailPattern.test(elementValue);
}

export function validPassword(str) {
  var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(str);
}
export function checkAmnt(str) {
  var re = /^[0-9]*$/;
  return re.test(str);
}

export function checkName(str) {
  var re = /^[a-zA-Z ]+$/;
  return re.test(str);
}

export function truncateString(str, num) {
  if (str.length > num) {
    return str.slice(0, num) + "...(READ MORE)";
  } else {
    return str;
  }
}

export function truncateString2(str, num) {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}

export const formatPhoneNumber = (input) => {
  if (!input || isNaN(input)) return "";
  if (typeof input !== "string") input = input.toString();
  return input.replace(/(\d{3})(\d{2})(\d{3})(\d{4})/, "$1 $2 $3 $4");
};

//is empty check
export const isEmpty = (obj) => {
  if (obj === null || obj === undefined || obj === {}) return true;
  return false;
};

export const uniqBy = (arr, key) => {
  return loadash.uniqBy(arr, key);
};

export const ScrollToTop = () => {
  //scroll to top with smooth
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const RedirectUser = (navigate) => {
  let getPathname = localStorage.getItem("pathname");

  if (getPathname !== null && getPathname !== undefined && getPathname !== "") {
    // window.location.pathname = getPathname;
    navigate(getPathname);
  } else {
    navigate("/");
    // window.location.pathname = "/";
  }

  localStorage.removeItem("pathname");
};

export const RedirectUser2 = (navigate) => {
  let token = localStorage.getItem("token");
  let isInLoginPage = window.location.pathname.toLowerCase() === "/login";

  if (token !== null && token !== undefined && token !== "") {
    // window.location.pathname = getPathname;
    isInLoginPage && navigate("/");
  }
};

//detect browser refresh
export const isBrowserRefresh = () => {
  let getPathname = localStorage.getItem("pathname");
  if (getPathname !== null && getPathname !== undefined && getPathname !== "") {
    return true;
  } else {
    return false;
  }
};

// formart iso to YYYY/MM/DD
export const formatDateBlog = (date) => {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return `${year}/${month}/${day}`;
};

export const ReplaceImageUpload = (str) => {
  console.log("vvv", str);
  let newUrl = str?.split("https://webdev.wordpress-developer.us/brainflix");

  return `https://brainflix.com/blog${newUrl[1]}`;
};
