import { React, useEffect } from "react";
// import Footer from "../../Components/Layout/Footer/Footer";
// import Header from "../../Components/Layout/Header/Header";
import { useDispatch, useSelector } from "react-redux";

import { fetch_AboutCms_data } from "../../redux/cmsSlice";
import { photoUrl } from "../../API";
// import "./About.scss";

function Aboutafterlogin() {
  window.scrollTo(0, 0);
  const { cmsAbout_data, cmsAbout_status } = useSelector((state) => state.cms);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetch_AboutCms_data());
  }, [dispatch]);

  return (
    <>
      {/* <Header /> */}
      <section className="about">
        <div className="container">
          <div className="row px-3 about_body">
            <h4>About BrainFlix </h4>
            <div className="about_body">
              {cmsAbout_status === "loading" ? (
                <div className="loader"></div>
              ) : (
                <h4>{cmsAbout_data.block1_heading}</h4>
              )}

              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="aboutImg">
                    {/* <img src='/assets/images/about_img.png' alt='' /> */}
                    <img
                      className="w-100"
                      src={photoUrl(
                        "about-brainflix",
                        cmsAbout_data.block1_image
                      )}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="aboutText">
                    <ul>
                      {/* <li>In the post-pandemic world where online video platforms became
                                                the de facto babysitters, parents struggle to find a safe
                                                environment for kids that’s free of harmful content, consumerism,
                                                narcissism and toxic comments. </li>
                                            <li>BrainFlix was created by parents, for parents. We carefully
                                                curate educational and age-appropriate videos that foster curiosity
                                                in children and supports their academic success in the digital
                                                age.</li>
                                            <li>Learning doesn’t have to be boring. BrainFlix works with parents,
                                                educators and kids to make sure the content is fun, engaging
                                                and personalized to foster curiosity for new knowledge and
                                                skills.</li> */}

                      <li>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: cmsAbout_data?.block1_description,
                          }}
                        ></p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="about_body">
              {/* <h4>Thousands of safe and educational videos for all age groups</h4> */}
              <h4>{cmsAbout_data.block2_heading}</h4>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="aboutText">
                    <ul>
                      {/* <li>BrainFlix has already curated thousands of high-quality videos
                                                that cover a wide range of subjects. The list is growing everyday
                                                thanks to the assistance of modern technology. You will find some
                                                of the most popular YouTube channels here and there’s always
                                                something new to learn!</li>
                                            <li>We inspire to be the most trusted video platforms for children.
                                                A growing community of parents and educators actively participate
                                                in the collection and moderation of video content, so you can be
                                                sure that your child is not accidentally exposed to inappropriate
                                                content.</li>
                                            <li>Our propriatary recommendation engine does not aim to generate
                                                more views or ad revenue, but rather the confidence that the video
                                                will provide knowledge or teach new skills in a fun and engaging
                                                way.</li> */}

                      <li>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: cmsAbout_data.block2_description,
                          }}
                        ></p>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="aboutImg">
                    <img
                      className="w-100"
                      src={photoUrl(
                        "about-brainflix",
                        cmsAbout_data.block2_image
                      )}
                      alt=""
                    />
                    {/* <img src="/assets/images/about_img1.png" alt="" /> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="about_body">
              <h4>{cmsAbout_data.block3_heading}</h4>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="aboutText">
                    <ul>
                      {/* <li>BrainFlix is designed to be kids-friendly. Your child will see a
                                                familiar user interface and easy ways to navigate and search for
                                                videos. By “liking” or “hiding” specific videos, it helps the
                                                system understand the child’s preference, which influences what
                                                videos will be shown in the future.</li> */}
                      <li>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: cmsAbout_data.block3_description,
                          }}
                        ></p>{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="aboutImg">
                    {/* <img src='/assets/images/homepage_img.jpg' alt='' /> */}
                    <img
                      src={photoUrl(
                        "about-brainflix",
                        cmsAbout_data.block3_image
                      )}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Footer /> */}
    </>
  );
}

export default Aboutafterlogin;
