import React, { useEffect, useState } from "react";
import "./Search.scss";
import Modal from "react-bootstrap/Modal";
import VideoPopup from "../VideoPopUp/VideoPopup";
import { useDispatch, useSelector } from "react-redux";
import {
  // useNavigate, useParams,
  useLocation,
} from "react-router-dom";
import { search_list } from "../../redux/userDetailsSlice";
import { Placeholder } from "react-bootstrap";
import { post_watch_history } from "../../redux/videoSlice";

export default function Search() {
  const { state } = useLocation();
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const pathname = useParams();
  const [show, setShow] = useState(false);
  const [videoDetails, setVideoDetails] = useState({});
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [search, setsearch] = useState("");
  const [fullscreen, setFullscreen] = useState(false);

  const handleOpenFullScreen = () => {
    setFullscreen(!fullscreen);
  };

  const handleCloseFullScreen = () => {
    setFullscreen(false);
  };
  const handleClose = () => setShow(false);
  const handleShow = (e, item) => {
    setVideoDetails(item);
    setShow(true);
  };
  const { search_list_status, search_list_data } = useSelector(
    (s) => s.userDetailsss
  );
  // useEffect(() => {
  //   if (search.length === 0) {
  //     dispatch(
  //       search_list({
  //         userId:
  //           JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
  //             ? JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
  //             : state?.id,
  //       })
  //     );
  //   }
  // }, [dispatch, userDetails._id, state, search]);

  const [input, setInput] = useState(false);

  const handlesearch = (e) => {
    e.preventDefault();
    setInput(true);
    dispatch(
      search_list({
        userId:
          JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
            ? JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
            : state?.id,
        generalSearch: search,
        dispatch: dispatch,
      })
    );
  };

  const watchHistory = (e, videoID) => {
    try {
      dispatch(
        post_watch_history({
          videoId: videoID,
          userId:
            JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              ? JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              : state?.id,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="search-page" style={{ minHeight: "640px" }}>
        <div className="common-container">
          <form
            action=""
            onSubmit={handlesearch}
            onSubmitCapture={() => {
              if ("activeElement" in document) document.activeElement.blur();
            }}
          >
            <div className="search-page__input">
              <input
                type="text"
                placeholder="Search anything"
                name="search"
                value={search}
                onChange={(e) => setsearch(e?.target?.value)}
                style={{ color: "black" }}
                autocomplete="off"
              />
              <div className="search-icon">
                <button type="submit">
                  {" "}
                  <img
                    src="/assets/images/icons/search-icon.svg"
                    alt=""
                    srcset=""
                  />
                </button>
              </div>
            </div>
          </form>

          <div className="search-page__result">
            <div className="row">
              <div className="vdo-bws__label">{/* <p>Top Searches</p> */}</div>
              {search_list_status === "loading" ? (
                <>
                  {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
                    <div className="col-lg-3 col-md-3 col-12 mb-4" key={i}>
                      <Placeholder animation="glow">
                        <Placeholder
                          className="rec-slider reset-foucs-v"
                          xs={6}
                          style={{
                            color: "white",
                            height: "200px",
                            width: "300px",
                          }}
                        />
                      </Placeholder>
                    </div>
                  ))}
                </>
              ) : search_list_data?.length ? (
                search_list_data?.map((item, index) => {
                  return (
                    <>
                      <div
                        className="col-lg-3 col-md-6 mb-4"
                        key={index}
                        onClick={(e) => {
                          watchHistory(e, item._id);
                          handleShow(e, item);
                        }}
                      >
                        <div
                          class="rec-slider reset-foucs-v"
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={`https://img.youtube.com/vi/${item?.videoId}/0.jpg`}
                            alt={item?.title}
                          />
                        </div>
                      </div>
                    </>
                  );
                })
              ) : input === false ? null : (
                <span
                  style={{
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "30px",
                  }}
                >
                  Oops ! No Video found
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        fullscreen={fullscreen}
        show={show}
        onHide={() => {
          handleClose();
          // watchHistory();
        }}
        className={fullscreen ? "modal_pop_full" : "modal_pop"}
      >
        <VideoPopup
          videoDetails={videoDetails}
          hideVideo={handleClose}
          fullscreen={fullscreen}
          handleOpenFullScreen={handleOpenFullScreen}
          handleCloseFullScreen={handleCloseFullScreen}
        />
      </Modal>
    </>
  );
}
