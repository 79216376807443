import React, { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import swal from "sweetalert";
// import AfterFeedbackkHeader from '../../Components/Layout/AfterFeedbackkHeader/AfterFeedbackkHeader';
// import Footer from "../../Components/Layout/Footer/Footer";
// import Header from "../../Components/Layout/Header/Header";
import { validEmail } from "../../helpers/utilts";
import { fetch_general_cms } from "../../redux/cmsSlice";
import { user_feedback } from "../../redux/userDetailsSlice";
import "./Help.scss";

function Contact() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const { feedback_status, feedback_data } = useSelector(
  //   (s) => s.userDetailsss
  // );

  const { cmsgeneral_data } = useSelector((s) => s.cms);
  console.log(
    "🚀 ~ file: Help.jsx ~ line 24 ~ Contact ~ cmsgeneral_data",
    cmsgeneral_data
  );

  useEffect(() => {
    dispatch(
      fetch_general_cms({
        slug: "help",
      })
    );
  }, [dispatch]);

  const [Feedbackk, setFeedbackk] = useState({
    fullName: "",
    email: "",
    feedback: "",
  });

  const [error, setError] = useState({ fullName: "", email: "", feedback: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      if (value.length === 0) {
        setError({ ...error, email: "Please Enter Your Email" });
        setFeedbackk({ ...Feedbackk, [name]: value });
      } else {
        setError({
          ...error,
          email: "",
        });
        setFeedbackk({ ...Feedbackk, [name]: value });
      }
    }
    if (name === "fullName") {
      if (value.length === 0) {
        setError({ ...error, fullName: "Please enter your name" });
        setFeedbackk({ ...Feedbackk, [name]: value });
      } else {
        setError({
          ...error,
          fullName: "",
        });
        setFeedbackk({ ...Feedbackk, [name]: value });
      }
    }

    if (name === "feedback") {
      if (value.length === 0) {
        setError({
          ...error,
          feedback: "Please enter your feedback or questions",
        });
        setFeedbackk({ ...Feedbackk, [name]: value });
      } else {
        setError({
          ...error,
          feedback: "",
        });
        setFeedbackk({ ...Feedbackk, [name]: value });
      }
    }
  };

  const handleFeedback = (e) => {
    e.preventDefault();
    setError({
      fullName: "",
      email: "",
      feedback: "",
    });
    if (
      Feedbackk.email.length === 0 &&
      Feedbackk.fullName.length === 0 &&
      Feedbackk.feedback.length === 0
    ) {
      setError({
        email: "Please enter your email",
        fullName: "Please enter your name",
        feedback: "Please enter your feedback or questions",
      });
    } else if (Feedbackk.email.length === 0) {
      setError({ ...error, email: "Please enter your email" });
    } else if (!validEmail(Feedbackk.email)) {
      setError({
        ...error,
        email: "Please enter your email",
      });
    } else if (Feedbackk.fullName.length === 0) {
      setError({
        ...error,
        fullName: "Please enter your name",
      });
    } else if (Feedbackk.feedback.length === 0) {
      setError({
        ...error,
        feedback: "Please enter your feedback or questions",
      });
    } else {
      dispatch(user_feedback(Feedbackk));
      navigate("/browser");
      toast.success(
        "Thank you for contacting us! You will hear back from us via email if a response is needed. "
      );
      // swal(
      //   "Feedback Submitted Successfully! Admin Get Back You Soon!!!",
      //   "You clicked the button!",
      //   "success"
      // );
    }
  };

  return (
    <>
      <div className="contact common_gap">
        <div className="container my-4">
          <div className="row px-3">
            <div className="col-lg-12 mb-5 ">
              <div style={{ marginTop: "-10rem" }}>
                {" "}
                <h2>{cmsgeneral_data?.title}</h2>
                <ul>
                  <li className="a">
                    {" "}
                    <p
                      dangerouslySetInnerHTML={{
                        __html: cmsgeneral_data?.content,
                      }}
                    ></p>{" "}
                  </li>
                </ul>
              </div>

              <h2 style={{ marginTop: "80px" }}>Feedback</h2>
              <p style={{ color: "white" }}>
                If you have any feedback or additional questions, please feel
                free to fill out the form below. We will review as soon as we
                can and respond as needed. Thank you.
              </p>
            </div>
            <div className="col-lg-6 mx-auto">
              <div className="form-group mt-3">
                <label>Name</label>
                <input
                  type="text"
                  id=""
                  className="form-control py-2"
                  placeholder="Your Name"
                  onChange={handleChange}
                  value={Feedbackk.fullName}
                  name="fullName"
                />
                <span style={{ color: "red" }}>{error.fullName}</span>
              </div>
              <div className="form-group mt-3">
                <label>Email</label>
                <input
                  type="text"
                  id=""
                  className="form-control py-2"
                  placeholder="Example@gmail.com"
                  onChange={handleChange}
                  value={Feedbackk.email}
                  name="email"
                />
                <span style={{ color: "red" }}>{error.email}</span>
              </div>
              <div className="form-group mt-3">
                <label htmlFor="exampleFormControlTextarea1">Feedback</label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows={3}
                  placeholder="Your feedback or questions"
                  onChange={handleChange}
                  value={Feedbackk.feedback}
                  name="feedback"
                />
                <span style={{ color: "red" }}>{error.feedback}</span>
              </div>
              <div className="text-center mt-4">
                <button
                  className="btn btn-primary rounded-pill"
                  // onClick={handleFeedback}
                  onClick={(e) => handleFeedback(e)}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
