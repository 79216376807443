import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../API";
import { endpoint } from "../API/endpoint";
// import { toast } from "react-toastify";
// toast.configure();
export const fetch_AboutCms_data = createAsyncThunk(
  "/aboutBrainFlix",
  async () => {
    try {
      let res = await axiosInstance.get(endpoint.aboutBrainFlix);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const fetch_topsection = createAsyncThunk(
  "/fetch_topsection",
  async () => {
    try {
      let res = await axiosInstance.get(endpoint.topsection);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const fetch_howitwork_data = createAsyncThunk(
  "/how-it-work/listdd",
  async () => {
    try {
      let res = await axiosInstance.get(endpoint.howitworklist);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const fetch_testimonial_data = createAsyncThunk(
  "/testimonial/list",
  async () => {
    try {
      let res = await axiosInstance.get(endpoint.testimoniallist);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

/* ========== GET PLAN DATA ========= */
export const fetch_plan_data = createAsyncThunk(
  "/plan-management/list",
  async () => {
    try {
      let res = await axiosInstance.get(endpoint.planlist);
      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);
/* ========== GET PLAN DATA ========= */

/* ========== GET general CMS ========= */
export const fetch_general_cms = createAsyncThunk(
  "fetch_general_data",
  async (obj) => {
    try {
      let res = await axiosInstance.post(endpoint.cmsgeneral, obj);
      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);
/* ========== GET general CMS ========= */
export const fetch_general_cms1 = createAsyncThunk("sssdsaas", async (obj) => {
  try {
    let res = await axiosInstance.post(endpoint.cmsgeneral, obj);
    return res?.data;
  } catch (error) {
    console.log(error);
  }
});

export const fetch_general_cms2Thank = createAsyncThunk(
  "sssdsaasss",
  async (obj) => {
    try {
      let res = await axiosInstance.post(endpoint.cmsgeneral, obj);
      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  cmsAbout_data: {},
  cmsAbout_status: "idle",

  cmsHowitwork_data: [],
  cmsHowitwork_status: "idle",

  cmsTestimonial_data: [],
  cmsTestimonial_status: "idle",

  cmsPlan_data: null,
  cmsPlan_status: "idle",

  cmsgeneral_data: null,
  cmsgeneral_status: "idle",

  cmsgeneral_data1: null,

  cmsgeneral_data2: null,

  cmsgeneral_data_thankyou: [],
  cmsgeneral_data_thankyou_status: "idle",

  fetch_topsection_data: null,
  fetch_topsection_status: "idle",
};

export const cmsSlice = createSlice({
  name: "cmsSlice",
  initialState,
  extraReducers: {
    [fetch_AboutCms_data.pending]: (state) => {
      state.cmsAbout_status = "loading";
    },
    [fetch_AboutCms_data.fulfilled]: (state, { payload }) => {
      state.cmsAbout_status = "idle";
      if (payload?.type === "success") {
        // toast(payload?.message, { type: "success" });
        state.cmsAbout_data = payload.data;
      } else {
        // state.cmsAbout_data = "abc";
        // toast(payload?.message, { type: "error" });
      }
    },
    [fetch_AboutCms_data.rejected]: (state) => {
      state.cmsAbout_status = "idle";
      //   toast(state.message, { type: "error" });
    },

    [fetch_howitwork_data.pending]: (state) => {
      state.cmsHowitwork_status = "loading";
    },
    [fetch_howitwork_data.fulfilled]: (state, { payload }) => {
      state.cmsHowitwork_status = "idle";
      if (payload?.type === "success") {
        state.cmsHowitwork_data = payload?.data;
      } else {
        state.cmsHowitwork_data = [];
      }
    },
    [fetch_howitwork_data.rejected]: (state) => {
      state.cmsHowitwork_status = "idle";
    },

    [fetch_testimonial_data.pending]: (state) => {
      state.cmsTestimonial_status = "loading";
    },
    [fetch_testimonial_data.fulfilled]: (state, { payload }) => {
      state.cmsTestimonial_status = "idle";
      if (payload?.type === "success") {
        state.cmsTestimonial_data = payload?.data;
      } else {
        state.cmsTestimonial_data = [];
      }
    },
    [fetch_testimonial_data.rejected]: (state) => {
      state.cmsTestimonial_status = "idle";
    },

    /* ========== GET PLAN DATA ========= */
    [fetch_plan_data.pending]: (state) => {
      state.cmsPlan_status = "loading";
      state.cmsPlan_data = null;
    },
    [fetch_plan_data.fulfilled]: (state, { payload }) => {
      state.cmsPlan_status = "idle";
      if (payload?.type === "success") {
        state.cmsPlan_data = payload?.data;
      } else {
        state.cmsPlan_data = "no data";
      }
    },
    [fetch_plan_data.rejected]: (state) => {
      state.cmsPlan_status = "idle";
    },
    /* ========== GET PLAN DATA ========= */

    /* ==========GET general CMS ========= */
    [fetch_general_cms.pending]: (state) => {
      state.cmsgeneral_status = "loading";
    },
    [fetch_general_cms.fulfilled]: (state, { payload }) => {
      state.cmsgeneral_status = "idle";
      if (payload?.type === "success") {
        state.cmsgeneral_data = payload?.data;

        // state.cmsgeneral_data_thankyou = payload?.data;
      } else {
        state.cmsgeneral_data = "no data";
      }
    },
    [fetch_general_cms.rejected]: (state) => {
      state.cmsgeneral_status = "idle";
    },
    /* ========== GET general CMS========= */

    /* ==========GET general CMS1 ========= */
    [fetch_general_cms1.pending]: (state) => {
      state.cmsgeneral_status = "loading";
    },
    [fetch_general_cms1.fulfilled]: (state, { payload }) => {
      state.cmsgeneral_status = "idle";
      if (payload?.type === "success") {
        state.cmsgeneral_data1 = payload?.data;
      

        // state.cmsgeneral_data_thankyou = payload?.data;
      } else {
        state.cmsgeneral_data1 = "no data";
      }
    },
    [fetch_general_cms1.rejected]: (state) => {
      state.cmsgeneral_status = "idle";
    },
    /* ========== GET general CMS1========= */

    /* ========== GET PLAN DATA ========= */

    /* ==========fetch_topsection ========= */
    [fetch_topsection.pending]: (state) => {
      state.fetch_topsection_status = "loading";
    },
    [fetch_topsection.fulfilled]: (state, { payload }) => {
      state.fetch_topsection_status = "idle";
      if (payload?.type === "success") {
        state.fetch_topsection_data = payload?.data;
        // state.fetch_topsection_data_thankyou = payload?.data;
      } else {
        state.fetch_topsection_data = "no data";
      }
    },
    [fetch_topsection.rejected]: (state) => {
      state.fetch_topsection_status = "idle";
    },
    /* ========== fetch_topsection========= */
    /* ==========fetch_general_cms2Thank ========= */
    [fetch_general_cms2Thank.pending]: (state) => {
      state.cmsgeneral_status = "loading";
    },
    [fetch_general_cms2Thank.fulfilled]: (state, { payload }) => {
      state.cmsgeneral_status = "idle";
      if (payload?.type === "success") {
        state.cmsgeneral_data_thankyou = payload?.data;
        console.log(
          "🚀 ~ file: cmsSlice.js ~ line 220 ~ cmsgeneral_data1sss",
          payload?.data
        );

        // state.cmsgeneral_data_thankyou = payload?.data;
      } else {
        state.cmsgeneral_data_thankyou = "no data";
      }
    },
    [fetch_general_cms2Thank.rejected]: (state) => {
      state.cmsgeneral_status = "idle";
    },
    /* ==========fetch_general_cms2Thank========= */
  },
});
