/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import style from "./Index.module.scss";
import { Link } from "react-router-dom";
import Header from "../../Components/Layout/Header/Header";
import Footer from "../../Components/Layout/Footer/Footer";
import "./Index.module.scss";
// import Tabs from "react-bootstrap/Tabs";
import { EffectFade, Navigation, Pagination } from "swiper";
// import "./swiper/css/navigation"
// import "swiper/css/navigation";
// import { Navigation } from "swiper";
import { useDispatch, useSelector } from "react-redux";
import banner from "../../assets/images/banner-img2.svg";
import {
  fetch_AboutCms_data,
  fetch_howitwork_data,
  fetch_testimonial_data,
  fetch_topsection,
} from "../../redux/cmsSlice";
// import { photoUrl } from "../../API";
// import { photoUrl } from "../../API";

import { Swiper, SwiperSlide } from "swiper/react";
import { photoUrl } from "../../API";
function Index() {
  const dispatch = useDispatch();
  useEffect(() => {
    document.body.classList.add("width_bg");
    return () => {
      document.body.classList.remove("width_bg");
    };
  });

  const loop = [1, 2, 3];

  const {
    cmsHowitwork_data,
    fetch_topsection_data,
    cmsTestimonial_data,
    cmsAbout_data,
    cmsAbout_status,
  } = useSelector((state) => state.cms);

  useEffect(() => {
    dispatch(fetch_AboutCms_data());
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetch_testimonial_data());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetch_howitwork_data());
    dispatch(fetch_topsection());
  }, []);

  const content = cmsHowitwork_data?.subHeading1?.split(" ");
  const video = cmsHowitwork_data?.subHeading2?.split(" ");
  const SUGGESTION = cmsHowitwork_data?.subHeading3?.split(" ");

  return (
    <>
      <Header />
      <div className="container">
        <div className={`header_top ${style.afterline} ${style.wraper}`}>
          <section className={`${style.layer_one}`}>
            <div className="row ">
              <div className="col-md-6">
                <img
                  style={{ marginTop: "10px", marginBottom: "20px" }}
                  src="/assets/images/Welcome to BrainFlix!.png"
                  alt=""
                />
                {/* <h1 className="mb-md-4">
                  WELCOME <br /> TO <span>BRAINFLIX!</span>
                </h1> */}
                <p>
                  {fetch_topsection_data?.text}
                  {/* A walled garden of carefully-curated videos for the curious
                  minds */}
                </p>

                <div className="pt-lg-5 pt-3">
                  <Link
                    // to="/sign-up"
                    to="/SubmitLoginPage"
                    className="btn btn-primary btn-xl py-2 px-5 rounded-pill"
                  >
                    Try it free for 30 days
                  </Link>
                </div>
              </div>
              <div className="col-md-6">
                <figure>
                  <img
                    className="w-100"
                    src={`https://admin.brainflix.com/uploads/top-section/${fetch_topsection_data?.image}`}
                    alt=""
                  />
                  {/* <img src={banner} alt="" /> */}
                </figure>
              </div>
            </div>
          </section>

          <section className={`w-100 d-lg-block d-none ${style.longDash}`}>
            <span> </span>
          </section>

          <section className="about1">
            <div className="container">
              <div className="row px-3">
                <div className="about_body">
                  {/* <h4>The Ideal Platform for Concerned Parents in the Digital Age </h4> */}
                  {cmsAbout_status === "loading" ? (
                    <div className="loader"></div>
                  ) : (
                    <h4>{cmsAbout_data.block1_heading}</h4>
                  )}

                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="aboutImg">
                        {/* <img src='/assets/images/about_img.png' alt='' /> */}
                        <img
                          className="w-100"
                          src={photoUrl(
                            "about-brainflix",
                            cmsAbout_data.block1_image
                          )}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="aboutText">
                        <ul>
                          <li>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: cmsAbout_data?.block1_description,
                              }}
                            ></p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <section
                  className={`w-100 d-lg-block d-none ${style.longDash}`}
                >
                  <span> </span>
                </section>
                <div className="about_body">
                  {/* <h4>Thousands of safe and educational videos for all age groups</h4> */}
                  <h4>{cmsAbout_data.block2_heading}</h4>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="aboutText">
                        <ul>
                          <li>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: cmsAbout_data.block2_description,
                              }}
                            ></p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="aboutImg">
                        <img
                          className="w-100"
                          src={photoUrl(
                            "about-brainflix",
                            cmsAbout_data.block2_image
                          )}
                          alt=""
                        />
                        {/* <img src="/assets/images/about_img1.png" alt="" /> */}
                      </div>
                    </div>
                  </div>
                </div>

                <section
                  className={`w-100 d-lg-block d-none ${style.longDash}`}
                >
                  <span> </span>
                </section>
                <div className="about_body">
                  <h4>{cmsAbout_data.block3_heading}</h4>
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="aboutText">
                        <ul>
                          {/* <li>BrainFlix is designed to be kids-friendly. Your child will see a
                                                familiar user interface and easy ways to navigate and search for
                                                videos. By “liking” or “hiding” specific videos, it helps the
                                                system understand the child’s preference, which influences what
                                                videos will be shown in the future.</li> */}
                          <li>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: cmsAbout_data.block3_description,
                              }}
                            ></p>{" "}
                          </li>
                        </ul>
                      </div>
                      <div className="aboutImg">
                        {/* <img src='/assets/images/homepage_img.jpg' alt='' /> */}
                        <img
                          src={photoUrl(
                            "about-brainflix",
                            cmsAbout_data.block3_image
                          )}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className={`w-100 d-lg-block d-none ${style.longDash}`}>
            <span> </span>
          </section>
          <section className={` ${style.layer_three}`}>
            {/* <h2 className="text-center">How BrainFlix Works</h2> */}
            <h2 className="text-center">{cmsHowitwork_data?.mainHeading}</h2>

            <div className="row ">
              <div className="col-xl-4 col-lg-6 ">
                <div className={` ${style.workCard}`}>
                  <div className={` ${style.head}`}>
                    <img src="assets/images/3.svg" alt="" />
                    <div
                      className={`${style.text} d-flex align-items-center justify-content-center`}
                    >
                      <span style={{ textTransform: "uppercase" }}>
                        {content?.length > 0 && content[0]}
                        <br /> {content?.length > 0 && content[1]}
                      </span>
                    </div>
                  </div>
                  <div className={` ${style.body} pt-5`}>
                    {/* <p>
                      We select videos from the best educational YouTube
                      channels and other sources based on user reviews, parent
                      feedback and educator validation.
                    </p> */}
                    <p
                      dangerouslySetInnerHTML={{
                        __html: cmsHowitwork_data.description1,
                      }}
                    ></p>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-6">
                <div className={` ${style.workCard}`}>
                  <div className={` ${style.head}`}>
                    <img src="assets/images/4.svg" alt="" />
                    <div
                      className={`${style.text} d-flex align-items-center justify-content-center`}
                    >
                      {/* <span>
             
                        VIDEO TAGGING <br /> & RATING


                      </span> */}

                      <span style={{ textTransform: "uppercase" }}>
                        {video?.length > 0 && video[0]}
                        {video?.length > 0 && video[1]} <br />
                        {video?.length > 0 && video[2]}
                        {video?.length > 0 && video[3]}
                      </span>
                    </div>
                  </div>
                  <div className={` ${style.body} pt-5`}>
                    {/* <p>
                      We select videos from the best educational YouTube
                      channels and other sources based on user reviews, parent
                      feedback and educator validation.
                    </p> */}
                    <p
                      dangerouslySetInnerHTML={{
                        __html: cmsHowitwork_data.description2,
                      }}
                    ></p>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-6">
                <div className={` ${style.workCard}`}>
                  <div className={` ${style.head}`}>
                    <img src="assets/images/5.svg" alt="" />
                    <div
                      className={`${style.text} d-flex align-items-center justify-content-center`}
                    >
                      {/* <span>
                        
                        VIDEO <br /> SUGGESTION
                      </span> */}

                      <span style={{ textTransform: "uppercase" }}>
                        {SUGGESTION?.length > 0 && SUGGESTION[0]} <br />
                        {SUGGESTION?.length > 0 && SUGGESTION[1]}
                      </span>
                    </div>
                  </div>
                  <div className={` ${style.body} pt-5`}>
                    {/* <p>
                      We select videos from the best educational YouTube
                      channels and other sources based on user reviews, parent
                      feedback and educator validation.
                    </p>         */}
                    <p
                      dangerouslySetInnerHTML={{
                        __html: cmsHowitwork_data.description3,
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className={`w-100 d-lg-block d-none ${style.longDash}`}>
            <span></span>
          </section>
          <div className="choose-module ">
            {/* <section className={` afterline ${style.outsideWwrapper}`}>
              <h2 className={`text-center ${style.mainHeading} mb-2`}>
                Choose the plan that's right for your family{" "}
              </h2>


              <div className="plan-tabs">
                <Tabs
                  defaultActiveKey="monthly"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tabs eventKey="monthly" title="Monthly"></Tabs>
                  <Tabs eventKey="annual" title="Annual"></Tabs>
                </Tabs>
              </div>

              <div className="membership-card-holder">
                <div className="membership-card__row xw-100">
                  <div className="membership-card__cards">
                    <div className="pricing-card active mt-xl-0 mt-2">
                      <div className="pricing-card__wrapper">
                        <div className="tag">
                          <p>Popular</p>
                        </div>
                        <div className="pricing-card__heading ">
                          <h2>BRAINFLIX PREMIUM</h2>
                        </div>

                        <div className="pricing-card__pricing">
                          <h1>
                            $12.99 <span>/mo</span>
                          </h1>
                          <p>The perfect way to start your journey</p>
                        </div>

                        <div className="pricing-card__lists">
                          <ul>
                            <li>Access to all curated videos</li>
                            <li>Choose preferred categories</li>
                            <li>Individualized recommendations</li>
                            <li>Parental controls</li>
                          </ul>
                        </div>

                        <div className="pricing-card__button">
                          <button className="blue">
                            Start your 30-day trial
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="pricing-card mt-xl-0 mt-2">
                      <div className="pricing-card__wrapper">
                        <div className="pricing-card__heading">
                          <h2>BRAINFLIX HOMESCHOOL</h2>
                        </div>

                        <div className="pricing-card__pricing">
                          <h1>
                            $19.99 <span>/mo</span>
                          </h1>
                          <p>
                            Educator-approved content for for all your
                            homeschooling needs
                          </p>
                        </div>

                        <div className="pricing-card__lists">
                          <p className="pricing-card__lists__extra">
                            Everything in Premium, plus
                          </p>
                          <ul>
                            <li>
                              Educator-approved videos organized by grade,
                              curriculum and topic
                            </li>
                            <li>
                              Individualized activity tracker and report for
                              parents
                            </li>
                          </ul>
                        </div>

                        <div className="pricing-card__button">
                          <button className="blue">
                            Start your 30-day trial
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}

            <div className="parents-say-heading">
              <h2>What parents are saying</h2>
              <p>
                This is how BrainFlix helps families in real life. Join the
                movement, where learning is fun!
              </p>
            </div>

            <Swiper
              spaceBetween={30}
              navigation
              loop={true}
              autoplay={{
                delay: 1000,
              }}
              speed={1500}
              pagination={{
                clickable: true,
              }}
              modules={[EffectFade, Navigation, Pagination]}
              className="mySwiperss"
              >
              <>
                {cmsTestimonial_data?.docs?.map((item, i) => (
                  <>
                    <SwiperSlide>
                      <div className="parents-saying">
                        <div className="parents-say-heading"></div>
                        <img
                          className="parents-prof"
                          src={photoUrl("testimonial", item?.image)}
                          alt=""
                        ></img>

                        <div className="insight-conversation">
                          <h3>{item?.raterName}</h3>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item?.description,
                            }}
                          ></p>
                        </div>
                      </div>
                    </SwiperSlide>
                  </>
                ))}
              </>
            </Swiper>

            {/* 
            <section className={` afterline ${style.outsideWwrapper}`}>
              <h2 className={`text-center ${style.mainHeading} mb-2`}>
                What parents are saying
              </h2>
              <p className={`text-center mb-4 ${style.mainParagraph}`}>
                This is how BrainFlix helps families in real life. Join the
                movement, where learning is fun!
              </p>

              <div className="card-parent-row row">
                {loop.map((item, index) => {
                  return (
                    <div className=" col-lg-4 col-md-6 mt-3" key={index}>
                      <div className="card-review">
                        <div className="card-review__img">
                          <img src="/assets/images/p1.png" alt="" />
                        </div>
                        <div className="card-review__body ">
                          <div className="card-review__body__star">
                            <img src="/assets/images/stars.png" alt="" />
                          </div>
                          <div className="card-review__body__info ">
                            <h2>Insight and conversation starts</h2>
                            <p className="">
                              Best of all conversation we have regarding give me
                              insight into my child's world and his relationship
                            </p>
                            <hr></hr>
                            <h6>Dave C</h6>
                            <p>Dad of 4</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </section> */}
            <section className="free-trial">
              <h2 className={`text-center ${style.mainHeading} mb-2`}>
                Try BrainFlix Free for 30 Days{" "}
              </h2>
              <p className={` mb-4 ${style.mainParagraph}`}>
                BrainFlix was created by parents, for parents. It’s a walled
                garden of safe, educational content for the curious minds.
                BrainFlix grows with your child and inspires them with the
                bright minds all over the world.
              </p>

              <div className="text-center">
                <Link
                  // to="/sign-up"
                  to="/SubmitLoginPage"
                >
                  <button className="free_btn btn">
                    {" "}
                    Start your free trial
                  </button>
                </Link>
              </div>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Index;
