/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { validEmail } from "../../../helpers/utilts";
import { loginRequest, socialSignIN } from "../../../redux/authSlice";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "@firebase/auth";
import app from "../../../firebase/FirebaseConfig";
import "./LoginPage.scss";
import { toast } from "react-toastify";
import { logo_fetech } from "../../../redux/userDetailsSlice";
function LoginPage() {
  const { status, isLoggedIn } = useSelector((s) => s.auth);
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    document.body.classList.add("no-header");
    document.body.classList.add("width_bg");
    return () => {
      document.body.classList.remove("no-header");
      document.body.classList.remove("width_bg");
    };
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      if (value.length === 0) {
        setError({ ...error, email: "Please Enter Your Email" });
        setLogin({ ...login, [name]: value });
      } else {
        setError({
          ...error,
          email: "",
        });
        setLogin({ ...login, [name]: value });
      }
    }
    if (name === "password") {
      if (value.length === 0) {
        setError({ ...error, password: "Please Enter Your Password" });
        setLogin({ ...login, [name]: value });
      } else {
        setError({
          ...error,
          password: "",
        });
        setLogin({ ...login, [name]: value });
      }
    }
  };
  const handleLogin = (e) => {
    e.preventDefault();
    setError({
      name: "",
      email: "",
      password: "",
    });
    if (login.email.length === 0 && login.password.length === 0) {
      setError({
        email: "Please Enter Your Email",
        password: "Please Enter Your Password",
      });
    } else if (login.email.length === 0) {
      setError({ ...error, email: "Please Enter Your Email" });
    } else if (!validEmail(login.email)) {
      setError({
        ...error,
        email: "Please Enter a vaild Email",
      });
    } else if (login.password.length === 0) {
      setError({ ...error, password: "Please Enter Your Password" });
    } else if (login.password.length < 8) {
      setError({
        ...error,
        password: "Password must be at least 8 characters",
      });
    } else {
      dispatch(loginRequest(login));
    }
  };
  const handleGoogleLogin = (e) => {
    const auth = getAuth(app);
    const gmailProvier = new GoogleAuthProvider();
    signInWithPopup(auth, gmailProvier)
      .then((result) => {
        const user = result.user;

        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const accessToken = credential.accessToken;

        let payload = {
          fullName: user?.displayName,
          email: user?.email,
          phone: user?.phoneNumber,
          registerType: "Google",
          social_id: user?.providerData[0].uid,
          // deviceToken: accessToken,
          // deviceType: window.navigator.userAgent,
        };
        // console.log("sadadasda",payload);
        dispatch(socialSignIN(payload));
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        if (errorCode === "auth/account-exists-with-different-credential") {
          toast.error("You have already registered with this email");
        }
        if (errorCode === "auth/popup-closed-by-user") {
          toast.error("You have already registered with this email");
        }
        console.log("error in google sign in ", error);
        // ...
      });
  };
  useEffect(() => {
    if (isLoggedIn) {
      if (localStorage.getItem("brainFlix_token_subcription_token")) {
        navigate("/subcription");
      } else {
        navigate("/browser/first-profile");
      }
    }
  }, [isLoggedIn]);

  const { user_data, logofetch_data } = useSelector(
    (state) => state.userDetailsss
  );

  return (
    <>
      <div className="loginPAge">
        <div className="loginPageHead">
          <NavLink to="/" className="logo-af">
            {/* <img src="/assets/images/logo.svg" alt="" /> */}
            <img
              src="/assets/images/1658999284845_android-chrome-192x192.png"
              alt=""
              id="logoo"
            />
            {/* <img
              src={`https://admin-brainflix.dedicateddevelopers.us/uploads/settings/${logofetch_data?.setting_value}`}
              alt=""
            /> */}
            {/* setting_value */}
          </NavLink>
        </div>

        <div className="loginFormWraper">
          <h2>Login</h2>
          {/* <button className="google_box py-2" onClick={handleGoogleLogin}>
            <img src="/assets/images/icons/googleImg.png" alt="img" />{" "}
            <p>Sign in with google</p>{" "}
          </button> */}
          <form onSubmit={handleLogin} className="loginForm">
            {/* <div className="or_class">Or</div> */}
            <div className="form-group">
              <div className="label">Email</div>
              <input
                type="text"
                id=""
                className="form-control py-2"
                placeholder="Your@email.com"
                onChange={handleChange}
                value={login.email}
                name="email"
              />
              <span style={{ color: "red" }}>{error.email}</span>
            </div>
            <div className="form-group">
              <div className="label">Password</div>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                id=""
                className="form-control py-2"
                placeholder="Enter Password"
                onChange={handleChange}
                value={login.password}
              />
              <i
                onClick={() => setShowPassword(!showPassword)}
                className={`fa-solid ${
                  showPassword ? "fa-eye" : "fa-eye-slash"
                } open-eye`}
              ></i>
              <span style={{ color: "red" }}>{error.password}</span>
            </div>
            <div className="submit-btn pt-3">
              <button
                className="btn btn-primary w-100 py-2"
                type="submit"
                disabled={status === "loading"}
              >
                {status === "loading" && (
                  <span
                    className="spinner-border spinner-border-sm mx-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}{" "}
                Log in
              </button>
            </div>

            <div className="links pt-5">
              <p>
                Need an account?
                <Link
                  // to="/sign-up"
                  to="/SubmitLoginPage"
                >
                  Sign Up
                </Link>
              </p>
              <p>
                <Link to="/forgot-password">Forgot password?</Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
