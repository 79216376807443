import React, { useEffect } from "react";
import "./newprofileavatar.scss";
import { All_avter_data } from "../../redux/userDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import { photoUrl } from "../../API";
export default function NewProfileAvatar() {
  const dispatch = useDispatch();
  const { All_avter_datass } = useSelector((state) => state.userDetailsss);

  useEffect(() => {
    dispatch(All_avter_data());
  }, [dispatch]);

  return (
    <>
      <div className="single-wrapper-whole mb-4">
        <div className="profile-creation-avatar">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-12 text-center">
                <div className="profile-creation__heading">
                  <h2>Choose Your Avatar</h2>
                </div>
              </div>

              <div className="col-md-12 col-12 m-auto mt-5">
                {All_avter_datass?.map((item) => {
                  return (
                    <>
                      <button className="icon_bg btn mx-lg-0 mx-auto mt-lg-0 mt-2 avteredit">
                        <img src={photoUrl("user/profile_pic", item)} alt="" />
                      </button>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
