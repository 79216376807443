/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { context } from "../../hooks";
import {
  fetch_userdata_data,
  Subuser_details,
} from "../../redux/userDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import Placeholder from "react-bootstrap/Placeholder";

import "./firstprofile.scss";
import { photoUrl } from "../../API";

const FirstProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const { user_data, Subuser_details_data, Subuser_details_status } =
    useSelector((state) => state.userDetailsss);
  const [isSubmit, setIsSubmit] = useContext(context);

  // const handeldelete = (_id) => {
  //   dispatch(Subuser_delete({ subUserId: _id }));
  // };
  useEffect(() => {
    async function callSubUserBefore() {
      dispatch(Subuser_details());
    }
    async function callSubUserAfter() {
      if (!isSubmit) {
        dispatch(Subuser_details());
        setIsSubmit(true);
      }
    }
    callSubUserBefore();
    callSubUserAfter();
  }, [isSubmit, setIsSubmit]);

  useEffect(() => {
    dispatch(
      fetch_userdata_data({
        userId: userDetails?._id
          ? userDetails?._id
          : JSON.parse(localStorage.getItem("UserID")),
      })
    );
  }, [dispatch]);

  const saveSubUserIDInLocal = (e, id) => {
    localStorage?.setItem("subUserId", JSON.stringify(id));
  };
  const goToEditPage = (e, id) => {
    e.preventDefault();
    navigate(`/browser/edit-subProfile/${id}`);
    localStorage.setItem("subUserId", JSON.stringify(id));
  };

  const gotoAccountpage = (e, id) => {
    e.preventDefault();
    // navigate(`/browser/account-settings`);
    navigate(`/browser/edit-profile/${user_data?._id}`);
  };
  return (
    <div className="first_profile">
      <div className="first_profile-section row">
        <div className="col-lg-12">
          <h6>Who’s Watching?</h6>
        </div>
        <div className="container">
          <div className="row user-list">
            {user_data !== null ? (
              <>
                <div className="col-lg-3 col-md-3 col-12 text-center ">
                  <NavLink to="/browser" state={{ id: user_data?._id }}>
                    <button className=" btn mx-sm-0 mx-auto mt-sm-0 mt-2">
                      {/* icon_bg */}
                      {/* <img src="/assets/images/avatars/1.png" alt="img" /> */}
                      <img
                        src={photoUrl(
                          "user/profile_pic",
                          user_data?.profile_image
                        )}
                        alt=""
                      />
                    </button>
                    <div className="title-sec">
                      <p className="titleforuser" style={{ color: "white" }}>
                        {user_data?.fullName}
                      </p>
                      <span
                        className="edit-button"
                        onClick={(e) => gotoAccountpage(e)}
                      >
                        <img src="/assets/images/edit-prof.svg" alt="" />
                      </span>
                    </div>
                  </NavLink>
                </div>
              </>
            ) : (
              <>
                {[1, 2, 3, 4].map((i) => (
                  <div className="col-lg-3 col-md-3 col-12 text-center">
                    <Placeholder animation="glow" key={i}>
                      <Placeholder
                        key={i}
                        className="search-page__result__img mb-2"
                        xs={6}
                        style={{
                          color: "white",
                          height: "160px",
                          width: "150px",
                          borderRadius: "20%",
                        }}
                      />
                    </Placeholder>
                    <p>
                      <Placeholder animation="glow">
                        <Placeholder
                          xs={4}
                          style={{
                            color: "white",
                          }}
                          className="mb-3"
                        />
                      </Placeholder>
                    </p>
                  </div>
                ))}
              </>
            )}
            {Subuser_details_data !== null && (
              <>
                {Subuser_details_data !== "no data" && (
                  <>
                    {Subuser_details_data?.map((item, i) => (
                      <div
                        className="col-lg-3 col-md-3 col-12 text-center"
                        key={i}
                      >
                        <NavLink
                          to="/browser"
                          state={{ id: item?._id }}
                          onClick={(e) => saveSubUserIDInLocal(e, item?._id)}
                        >
                          <button className=" btn mx-sm-0 mx-auto mt-sm-0 mt-2">
                            {/* icon_bg */}
                            <img
                              src={photoUrl(
                                "user/profile_pic",
                                item?.profile_image
                              )}
                              alt=""
                            />
                          </button>
                        </NavLink>{" "}
                        <div className="title-sec">
                          <p
                            className="titleforuser"
                            style={{ color: "white" }}
                          >
                            {item?.fullName}
                          </p>

                          <span
                            className="edit-button"
                            // to={`/browser/edit-subProfile/${item?._id}`}
                            onClick={(e) => goToEditPage(e, item?._id)}
                          >
                            <img src="/assets/images/edit-prof.svg" alt="" />
                          </span>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </>
            )}
            {/*  
            {Subuser_details_data?.length !== 3 && ( 
            <NavLink
              to="profile-creation"
              className="col-lg-3 col-md-3 col-12 text-center"
            >
              <button className="plus_sign_bg m-auto btn">
                <img src="/assets/images/plus.svg" alt="img" />
              </button>
            </NavLink>
            )} */}
            {user_data !== null && (
              <>
                {Subuser_details_status !== null &&
                  (Subuser_details_data?.length < 3 ||
                    Subuser_details_data === "no data") && (
                    <div className="col-lg-3 col-md-3 col-12 text-center">
                      <NavLink to="profile-creation">
                        <button className="plus_sign_bg m-auto btn">
                          <img src="/assets/images/plus.svg" alt="img" />
                        </button>
                      </NavLink>
                    </div>
                  )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstProfile;
