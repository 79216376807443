import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../API";
import { endpoint } from "../API/endpoint";

export const loginRequest = createAsyncThunk("/login", async (user) => {
  try {
    const res = await axiosInstance.post(endpoint.login, user);
    return res?.data;
  } catch (error) {
    toast(error?.response?.data?.message, { type: "error" });
  }
});
export const signupRequest = createAsyncThunk("/signup", async (user) => {
  const params = new URLSearchParams(window.location.search);
  try {
    const res = await axiosInstance.post(
      params.get("ref")
        ? `${endpoint.signup}?ref=${params.get("ref")}`
        : endpoint.signup,
      user
    );
    return res?.data;
  } catch (error) {
    toast(error?.response?.data?.message, { type: "error" });
  }
});

export const socialSignIN = createAsyncThunk("/socialSignIN", async (user) => {
  try {
    const res = await axiosInstance.post(endpoint.socialLogin, user);

    return res?.data;
  } catch (error) {
    console.log("error", error);
    toast(error?.response?.data?.message, { type: "error" });
  }
});

export const change_password = createAsyncThunk(
  "/user/change-password",
  async (user) => {
    try {
      const res = await axiosInstance.post(endpoint.changePassword, user);

      return res?.data;
    } catch (error) {
      console.log("error", error);
      toast(error?.response?.data?.message, { type: "error" });
    }
  }
);
export const forgot_password = createAsyncThunk(
  "/user/forgot-password",
  async (user) => {
    try {
      const res = await axiosInstance.post(endpoint.forgotPassword, user);

      return res.data;
    } catch (error) {
      console.log("error", error);
      toast(error?.response?.data?.message, { type: "error" });
    }
  }
);
export const user_Logout = createAsyncThunk("/user/user/logout", async () => {
  try {
    const res = await axiosInstance.get(endpoint.logout);

    return res?.data;
  } catch (error) {
    console.log("error", error);
    toast(error?.response?.data?.message, { type: "error" });
  }
});
// const RedirectUser = () => {
//   let getPathname = localStorage.getItem("pathname");

//   if (getPathname !== null && getPathname !== undefined && getPathname !== "") {
//     window.location.pathname = getPathname;
//   } else {
//     window.location.pathname = "/";
//   }

//   localStorage.removeItem("pathname");
// };

const initialState = {
  status: "idle",
  isSignupCompleted: false,
  verifyRequest: false,
  verified: false,
  message: null,
  signUp_fb: false,
  userDetails: null,
  res_status: null,
  FbSignUp_user_status: "idle",
  VerifyOtp_status: "idle",
  redirectTo: "/",
  isLoggedIn: false,
  updateUserProfile_status: "idle",
  updateUserProfile_data: null,
  forgotPassword_status: "idle",
  forgotPassword_success_msg: false,
  forgotPassword_res_message: null,
  // forgotPassword_res_data: null,
  // forgotPassword_res_redirect_to: null,
  changePassword_res_status: "idle",
  changePassword_status: "idle",
  socialSignIN_status: "idle",
  socialSignIN_msg: false,
  socail_payload: {},
  socialSignIN_data: "idle",
  logInSucess: false,
  // signUp_sucess: false,
  //reset-modal-controller
  openModal: false,
  //logout
  logout_status: "idle",
  logout_mssg: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    Logout: (state, { payload }) => {
      state.redirectTo = "/";
      state.isLoggedIn = false;
      localStorage.removeItem("brainFlix_token");
      localStorage.removeItem("userDetails");
      state.logout_mssg = null;
    },
    checkLogIn: (state) => {
      if (
        localStorage.getItem("brainFlix_token") !== null &&
        localStorage.getItem("brainFlix_token") !== undefined
      ) {
        state.isLoggedIn = true;
        state.userDetails = JSON.parse(localStorage.getItem("userDetails"));
      }
    },
    //forgot
    cheakForgotEmail: (state) => {
      if (
        localStorage.getItem("forgotE") !== null &&
        localStorage.getItem("forgotE") !== undefined &&
        JSON.parse(localStorage.getItem("forgotPassword_success_msg"))
      ) {
        state.forgotPassword_success_msg = true;
      }
    },
    //reset-modal-controller
    handleopenModal: (state) => {
      state.openModal = true;
    },
    closeModal: (state) => {
      state.openModal = false;
    },
    //socail-signup
    socailResData: (state, { payload }) => {
      state.socail_payload = payload;
    },
    socailSignup: (state) => {
      if (
        localStorage.getItem("socialSignIN_paylod") !== null &&
        localStorage.getItem("socialSignIN_paylod") !== undefined &&
        JSON.parse(localStorage.getItem("socialSignIN_msg"))
      ) {
        state.socialSignIN_msg = true;
        state.socail_payload = JSON.parse(
          localStorage.getItem("socialSignIN_paylod")
        );
      } else {
        state.socialSignIN_msg = false;
      }
    },
  },
  extraReducers: {
    [signupRequest.pending]: (state) => {
      state.status = "loading";
      state.message = null;
      state.res_status = null;
    },
    [signupRequest.fulfilled]: (state, { payload }) => {
      state.status = "idle";
      // state.message = payload?.message;
      if (payload?.type === "success") {
        // state.isLoggedIn=false;
        toast(payload?.message, { type: "success" });
        localStorage.setItem("payload", payload);
        let userDetails = {
          name: payload?.data?.name,
          email: payload?.data?.email,
          phone: payload?.data?.phone,
          register_type: payload?.data?.register_type,
          referralCode: payload?.data?.referralCode,
          _id: payload?.data?._id,
        };
        state.userDetails = userDetails;
        localStorage.setItem("userDetails", JSON.stringify(userDetails));
        localStorage.setItem("brainFlix_token", payload?.token);

        // RedirectUser();
        state.isLoggedIn = true;
        // state.signUp_sucess = true;
      } else {
        toast(payload?.message, { type: "error" });
      }
    },
    [signupRequest.rejected]: (state) => {
      state.isLoggedIn = false;
      state.status = "error";
      state.message = "Something went wrong";
      toast(state.message, { type: "error" });
    },

    //login
    [loginRequest.pending]: (state) => {
      state.status = "loading";
      state.message = null;
      state.res_status = null;
      state.logInSucess = false;
    },
    [loginRequest.fulfilled]: (state, { payload }) => {
      state.status = "idle";
      // state.message = payload?.message;
      if (payload?.type === "success") {
        if (
          payload?.message ===
          "You Should Take Brainflix Subscription To Continue!!!"
        ) {
          // toast(payload?.message, { type: "warning" });
          state.message = payload?.message;
          state.logInSucess = true;
          state.isLoggedIn = true;
          localStorage.setItem(
            "brainFlix_token_subcription_token",
            payload?.data?.token
          );
          localStorage.setItem(
            "brainFlix_token_subcription_data",
            JSON.stringify(payload?.data?.userData)
          );
          state.userDetails = payload?.data;
        } else {
          state.isLoggedIn = true;
          toast(payload?.message, { type: "success" });

          let userDetails = {
            name: payload?.data?.name,
            email: payload?.data?.email,
            phone: payload?.data?.phone,
            register_type: payload?.data?.register_type,
            _id: payload?.data?._id,
            referralCode: payload?.data?.referralCode,
          };
          state.userDetails = userDetails;
          localStorage.setItem("UserID", JSON.stringify(payload?.data?._id));
          localStorage.setItem("userDetails", JSON.stringify(userDetails));
          localStorage.setItem("brainFlix_token", payload?.token);
          state.logInSucess = true;
          state.isLoggedIn = true;

          // RedirectUser();
        }
      } else {
        toast(payload?.message, { type: "error" });
      }
      // state.message = payload?.message;
    },
    [loginRequest.rejected]: (state, { payload }) => {
      state.isLoggedIn = false;
      state.status = "error";
      state.message = "Something went wrong";
      toast(state.message, { type: "error" });
    },
    //social
    [socialSignIN.pending]: (state) => {
      state.socialSignIN_status = "loading";
      state.message = null;
    },
    [socialSignIN.fulfilled]: (state, { payload }) => {
      state.socialSignIN_status = "idle";

    
      if (payload?.type === "success") {
        
        if (
          payload?.message ===
          "You Should Take Brainflix Subscription To Continue!!!"
        ) {
          // toast(payload?.message, { type: "warning" });
          state.message = payload?.message;
          state.logInSucess = true;
          state.isLoggedIn = true;
          localStorage.setItem(
            "brainFlix_token_subcription_token",
            payload?.data?.token
          );
          localStorage.setItem(
            "brainFlix_token_subcription_data",
            JSON.stringify(payload?.data?.userData)
          );
          state.userDetails = payload?.data;
        } else {
    
          toast(payload?.message, { type: "success" });
          let userDetails = {
            name: payload?.data?.name,
            email: payload?.data?.email,
            phone: payload?.data?.phone,
            register_type: payload?.data?.register_type,
            _id: payload?.data?._id,
            referralCode: payload?.data?.referralCode,
          };
          state.isLoggedIn = true;
          state.userDetails = userDetails;
          localStorage.setItem("userDetails", JSON.stringify(userDetails));
          localStorage.setItem("brainFlix_token", payload?.token);
          // window.location.pathname = "/";
          // RedirectUser();
        }
    
      } else {
        toast(payload?.message, { type: "error" });
      }
    },
    [socialSignIN.rejected]: (state) => {
      state.message = "Something went wrong";
      toast(state.message, { type: "error" });
    },
    //change-password
    [change_password.pending]: (state) => {
      state.changePassword_res_status = "loading";
      state.message = null;
    },
    [change_password.fulfilled]: (state, { payload }) => {
      state.changePassword_res_status = "idle";

      if (payload?.type === "success") {
        // state.resetMsg = payload?.data?.message;

        toast.success(payload?.message, {
          type: "success",
          hideProgressBar: true,
          autoClose: 2500,
        });
      }
    },
    [change_password.rejected]: (state) => {
      state.message = "Something went wrong";
      toast(state.message, { type: "error" });
    },
    //forgot-password
    [forgot_password.pending]: (state) => {
      state.forgotPassword_status = "loading";
    },
    [forgot_password.fulfilled]: (state, { payload }) => {
      state.forgotPassword_status = "idle";
      if (payload?.type === "success") {
        toast(payload?.message, { type: "success" });
        state.forgotPassword_success_msg = true;
        state.forgotPassword_res_message = payload;
        localStorage.setItem("forgotPassword_success_msg", true);
      }
    },
    [forgot_password.rejected]: (state) => {
      state.forgotPassword_status = "idle";
    },
    //logout
    [user_Logout.pending]: (state) => {
      state.logout_status = "loading";
    },
    [user_Logout.fulfilled]: (state, { payload }) => {
      state.logout_status = "idle";
      if (payload.type === "success") {
        toast(payload?.message, { type: "success" });
        localStorage.removeItem({ type: "subUserId" });
        localStorage.removeItem("UserID");

        // localStorage.clear();
        state.logout_mssg = "success";
      } else {
        state.logout_mssg = "error";
      }
    },
    [user_Logout.rejected]: (state) => {
      state.logout_status = "idle";
    },
  },
});

export const {
  Logout,
  checkLogIn,
  cheakForgotEmail,
  handleopenModal,
  closeModal,
  socailSignup,
  socailResData,
} = authSlice.actions;
