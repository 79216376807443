/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import "./App.scss";
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
// import Header from "./Components/Layout/Header/Header";
// import Footer from "./Components/Layout/Footer/Footer";
import Index from "./Pages/Home/Index";

// import PrivateOutlet from "./ShareModules/RouterWraper/PrivateOutlet";
// import Portfolio from "./Pages/Portfolio/Portfolio";
import LoginPage from "./Pages/AuthModule/LoginPage/LoginPage";
import NotFoundPage from "./Pages/NotFoundPage/NotFoundPage";
import SignUp from "./Pages/AuthModule/SignUp/SignUp";
import AccountSettings from "./Pages/AccountSettings/AccountSettings";
// import VideoBrowser from "./Pages/VideoBrowser/VideoBrowser";
import AfterLoginRouter from "./ShareModules/RouterWraper/AfterLoginRouter";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ForgotPassword from "./Pages/AuthModule/ForgotPassword/ForgotPassword";
import MemberShipPlan from "./Pages/MemberShipPlan/MemberShipPlan";
import Howworks from "./Pages/HowItWorks/Howworks";
import Testimonial from "./Pages/Testimonial/Testimonial";
import Blog from "./Pages/Blog/Blog";
import About from "./Pages/AboutPage/About";
import Contact from "./Pages/Contact/Contact";
import { useDispatch } from "react-redux";
import { checkLogIn } from "./redux/authSlice";
import Profileselection from "./Pages/ProfileSelection/ProfileSelection";
import jwt_decode from "jwt-decode";
import Subcription from "./Pages/subcriptionPage";
import { user_Logout } from "./redux/authSlice";
import BlogDetails from "./Pages/Blog/BlogDetails";
import { Help } from "@material-ui/icons";
import Supporteddevices from "./Pages/FooterCMS/Supporteddevices";
import Termsconditions from "./Pages/FooterCMS/Termsconditions";
import Donatetobrainflix from "./Pages/FooterCMS/ Donatetobrainflix";
import Privacypolicy from "./Pages/FooterCMS/ Privacypolicy";
import SubmitLoginPage from "./Pages/SubmitLoginPage/SubmitLoginPage";
import Beforehelp from "./././Pages/Help/Beforehelp";
function PrivateRoute({ children }) {
  const { pathname } = useLocation();

  const token =
    localStorage.getItem("brainFlix_token") ||
    sessionStorage.getItem("brainFlix_token");

  useEffect(() => {
    localStorage.setItem("pathname", pathname);
  }, [pathname]);

  return token !== null && token !== undefined ? children : <Navigate to="/" />;
}
function PublicRoute({ children }) {
  const { pathname } = useLocation();
  const token =
    localStorage.getItem("brainFlix_token") ||
    sessionStorage.getItem("brainFlix_token");

  useEffect(() => {
    localStorage.setItem("pathname", pathname);
  }, [pathname]);

  return token !== null && token !== undefined ? (
    <Navigate to="/browser" />
  ) : (
    children
  );
}

const privateRoutesName = [
  {
    path: "/account-settings/*",
    component: <AccountSettings />,
  },
  {
    path: "/profile-selection/*",
    component: <Profileselection />,
  },
  {
    path: "/browser/*",
    component: <AfterLoginRouter />,
  },
];
const publicRoutesName = [
  {
    path: "/",
    component: <Index />,
  },
  {
    path: "/subcription",
    component: <Subcription />,
  },
  {
    path: "/log-in",
    component: <LoginPage />,
  },
  {
    path: "/forgot-password",
    component: <ForgotPassword />,
  },
  {
    path: "/sign-up",
    component: <SignUp />,
  },
  {
    path: "/pricing",
    component: <MemberShipPlan />,
  },
  {
    path: "/how-it-works",
    component: <Howworks />,
  },
  {
    path: "/testimonial",
    component: <Testimonial />,
  },
  {
    path: "/blogs",
    component: <Blog />,
  },
  {
    path: "/blog-details/:name",
    component: <BlogDetails />,
  },
  {
    path: "/about",
    component: <About />,
  },
  {
    path: "/contact",
    component: <Contact />,
  },

  {
    path: "supported-devices",
    component: <Supporteddevices />,
  },
  {
    path: "termscondition",
    component: <Termsconditions />,
  },
  {
    path: "donatetobrainflix",
    component: <Donatetobrainflix />,
  },
  {
    path: "privacypolicy",
    component: <Privacypolicy />,
  },
  {
    path: "SubmitLoginPage",
    component: <SubmitLoginPage />,
  },
  {
    path: "help",
    component: <Beforehelp />,
  },
];

function App() {
  const dispatch = useDispatch();
  const currentTime = Date.now() / 1000;
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(checkLogIn());
  }, [dispatch]);

  useEffect(() => {
    if (window.localStorage.brainFlix_token) {
      const decoded = jwt_decode(localStorage.getItem("brainFlix_token"));
      var date = new Date(decoded.exp * 1000);
      // console.log(date);
      if (decoded.exp < currentTime) {
        dispatch(user_Logout());
        localStorage.clear();
        navigate("/");
        window.location.reload(true);
      }
    }
  }, [currentTime]);

  return (
    <>
      <Routes>
        {/**************  Start public Route *********************/}
        {publicRoutesName?.map((route, index) => {
          return (
            <Route
              key={index + 1}
              exact
              path={route.path}
              element={<PublicRoute>{route.component}</PublicRoute>}
            />
          );
        })}
        {/****************** End Private Route  *********************/}

        {privateRoutesName?.map((route, index) => {
          return (
            <Route
              key={index + 2}
              path={route.path}
              element={<PrivateRoute>{route.component}</PrivateRoute>}
            />
          );
        })}
        <Route
          path="/browser/*"
          element={
            <PrivateRoute>
              <AfterLoginRouter />
            </PrivateRoute>
          }
        />

        {/**************** No page found *********************/}
        <Route path="/*" element={<NotFoundPage />} />
        {/* <Route path="/*" element={<PrivateOutlet />} /> */}
      </Routes>
    </>
  );
}

export default App;
