import React, { useEffect, useState } from "react";
import { Tabs, Placeholder, Card } from "react-bootstrap";
import Footer from "../../Components/Layout/Footer/Footer";
import Header from "../../Components/Layout/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { fetch_plan_data } from "../../redux/cmsSlice";
import { useNavigate } from "react-router-dom";
import "./MemberShipPlan.scss";

export default function MemberShipPlan() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cmsPlan_data } = useSelector((state) => state.cms);

  const [choosePlan, setChoosePlan] = useState("Monthly");

  useEffect(() => {
    dispatch(fetch_plan_data());
  }, [dispatch]);

  const choosePlanAndGotoSignUpPage = (e, planID) => {
    e.preventDefault();
    navigate("/sign-up", {
      state: {
        plan_id: planID,
      },
    });
  };
  return (
    <>
      <Header />
      <div className="acc-set common_gap">
        <div className="common-container">
          <div className="acc-set__lists w80 membership">
            <div className="page-heading">
              {/* <div className="page-heading__wrapper">
                <h2>Choose the plan that's right for your family </h2>
              </div> */}
            </div>
            <div className="plan-tabs">
              <Tabs
                defaultActiveKey="Monthly"
                id="uncontrolled-tab-example"
                className="mb-3"
                onSelect={(e) => setChoosePlan(e)}
              >
                <Tabs eventKey="Monthly" title="Monthly"></Tabs>
                <Tabs eventKey="Annually" title="Annually"></Tabs>
              </Tabs>
            </div>
          </div>

          <div className="membership-card-holder">
            <div className="membership-card__row">
              <div className="membership-card__cards">
                {cmsPlan_data?.docs !== null ? (
                  <>
                    {cmsPlan_data?.docs
                      ?.filter(
                        (item) =>
                          item.subscriptionType === choosePlan &&
                          item.isVisible === true
                      )
                      .map((filteredData, i) => (
                        <div
                          className="pricing-card active mt-lg-0 mt-3"
                          key={i}
                        >
                          <div className="pricing-card__wrapper">
                            <div className="pricing-card__heading">
                              <h2>{filteredData.title}</h2>
                            </div>

                            <div className="pricing-card__pricing">
                              <h1 >
                                ${filteredData.planPrice}
                                {filteredData?.planDuration === "Monthly" ? (
                                  <span>/mo</span>
                                ) : (
                                  <span style={{marginLeft:"10px"}}>/yr</span>
                                )}
                              </h1>
                              <h4>{filteredData.planHeading}</h4>
                              <h5>{filteredData.planSubHeading}</h5>
                            </div>

                            <div className="pricing-card__lists">
                              <ul>
                                {filteredData.planFeatures.map((item, i) => (
                                  <li key={i}>{item}</li>
                                ))}
                              </ul>
                            </div>

                            {/* <div className="pricing-card__button">
                              <button
                                className={`${
                                  choosePlan === "Monthly" ? "green" : "blue"
                                }`}
                                onClick={(e) =>
                                  choosePlanAndGotoSignUpPage(
                                    e,
                                    filteredData._id
                                  )
                                }
                              >
                                {choosePlan === "Monthly"
                                  ? " Current Plan"
                                  : "Select This Plan"}
                              </button>
                            </div> */}
                          </div>
                        </div>
                      ))}
                  </>
                ) : (
                  <>
                    {[1, 2].map((i) => (
                      <>
                        <div className="pricing-card active">
                          <div className="pricing-card__wrapper">
                            <div className="pricing-card__heading">
                              <h2>
                                <Placeholder as={Card.Title} animation="glow">
                                  <Placeholder xs={6} />
                                </Placeholder>
                              </h2>
                            </div>

                            <div className="pricing-card__pricing">
                              <h1>
                                <Placeholder as={Card.Title} animation="glow">
                                  <Placeholder
                                    xs={4}
                                    style={{ height: "5px" }}
                                  />
                                </Placeholder>
                              </h1>
                              <p>
                                <Placeholder as={Card.Title} animation="glow">
                                  <Placeholder xs={12} />
                                </Placeholder>
                              </p>
                            </div>

                            <div className="pricing-card__lists">
                              <ul>
                                {[1, 2, 3, 4].map((i) => (
                                  <Placeholder
                                    as={Card.Title}
                                    animation="glow"
                                    key={i}
                                  >
                                    <Placeholder xs={12} />
                                  </Placeholder>
                                ))}
                              </ul>
                            </div>

                            <div className="pricing-card__button">
                              <Placeholder.Button variant="primary" xs={6} />
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
