/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
// import ClearIcon from "@material-ui/icons/Clear";
import { useDispatch } from "react-redux";
import { change_password } from "../../redux/authSlice";
import "./ResetModel.scss";
export default function ResetModal({ show2, handleClose2 }) {
  const navigate = useNavigate();
  // const { changePassword_res_status } = useSelector((s) => s.auth);
  let passwordRegex = new RegExp("^(?=.*[A-Z])");
  let passspecial = new RegExp("^(?=.*[!@#$%^&*_()])");
  const dispatch = useDispatch();
  const [resetData, setResetData] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [sixPass, setSix] = useState();
  const [spcl, setSpcl] = useState();
  const [uppr, setUppr] = useState();
  const [showPassword, setShowPassword] = useState({
    oldPass: false,
    pass: false,
    conPass: false,
  });

  const [error, setError] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const resetErr = () => {
    setError({
      old_password: "",
      new_password: "",
      confirm_password: "",
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "old_password") {
      if (value === "") {
        setError({
          ...error,
          old_password: "Please Enter Old Password",
        });
        setResetData({ ...resetData, [name]: value });
      } else {
        setError({
          ...error,
          old_password: "",
        });
        setResetData({ ...resetData, [name]: value });
      }
    }
    if (name === "new_password") {
      if (value === "") {
        setError({
          ...error,
          new_password: "Please enter new password",
        });
        setResetData({ ...resetData, [name]: value });
      } else {
        setError({
          ...error,
          new_password: "",
        });
        setResetData({ ...resetData, [name]: value });
      }
    }
    if (name === "confirm_password") {
      if (value === "") {
        setError({
          ...error,
          confirm_password: "Please enter confirm password",
        });
        setResetData({ ...resetData, [name]: value });
      } else if (value !== resetData.new_password) {
        setError({
          ...error,
          confirm_password: "Password does not match",
        });
        setResetData({ ...resetData, [name]: value });
      } else {
        setError({
          ...error,
          confirm_password: "",
        });
        setResetData({ ...resetData, [name]: value });
      }
    }
  };
  useEffect(() => {
    if (resetData?.new_password?.length === 0) {
      setSix(undefined);
      setSpcl(undefined);
      setUppr(undefined);
    } else {
      if (resetData.new_password.length > 5) {
        setSix(true);
      }
      if (resetData.new_password.length < 8) {
        setSix(false);
      }
      if (!passwordRegex.test(resetData.new_password)) {
        setUppr(false);
      }
      if (passwordRegex.test(resetData.new_password)) {
        setUppr(true);
      }
      if (!passspecial.test(resetData.new_password)) {
        setSpcl(false);
      }
      if (passspecial.test(resetData.new_password)) {
        setSpcl(true);
      }
    }
  }, [passwordRegex, passspecial, resetData.new_password]);
  useEffect(() => {
    if (sixPass && uppr && spcl) {
      setTimeout(() => {
        setSix(undefined);
        setSpcl(undefined);
        setUppr(undefined);
      }, 500);
    }
  }, [sixPass, uppr, spcl]);
  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData();
    resetErr();
    if (resetData.old_password.length === 0) {
      setError({
        ...error,
        old_password: "Please Enter Old Password",
      });
    } else if (resetData.old_password.length < 6) {
      setError({
        ...error,
        old_password: "Password must be at least 8 characters",
      });
    } else if (resetData.new_password.length === 0) {
      setError({
        ...error,
        new_password: "Please enter new password",
      });
    } else if (resetData.new_password.length < 6) {
      // setError({
      //   ...error,
      //   new_password: "Password must be at least 6 characters",
      // });
    } else if (!passwordRegex.test(resetData.new_password)) {
      // setError({ ...error, new_password: "At least one Upper Case character" });
    } else if (!passspecial.test(resetData.new_password)) {
      // setError({
      //   ...error,
      //   new_password: "At least one Special Character [e.g., ! @ # ?]",
      // });
    } else if (resetData.confirm_password.length === 0) {
      setError({
        ...error,
        confirm_password: "Please enter confirm password",
      });
    } else if (resetData.confirm_password !== resetData.new_password) {
      setError({
        ...error,
        confirm_password: "Password does not match",
      });
    } else if (resetData.old_password === resetData.confirm_password) {
      setError({
        ...error,
        confirm_password: "Old password and new password cannot be same",
      });
    } else {
      formData.append("old_password", resetData.old_password);
      formData.append("new_password", resetData.new_password);
      dispatch(change_password(formData));
      navigate("/");
    }
  };

  useEffect(() => {
    if (show2) {
      //reset resetData
      setResetData({
        old_password: "",
        new_password: "",
        confirm_password: "",
      });

      resetErr();
    }
  }, [show2]);

  return (
    <Modal
      show={show2}
      onHide={handleClose2}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>Reset Password</Modal.Title>

        <span className="cp" onClick={handleClose2}>
          {" "}
        </span>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="row profile-form-wpr">
            <div className="form-group ">
              <label>Old Password *</label>
              <input
                type={showPassword.oldPass ? "text" : "password"}
                name="old_password"
                placeholder="Enter Old Password"
                value={resetData.old_password}
                onChange={handleChange}
                //   onChange={handleFieldChange}
                //   onFocus={handleErrorRemove}
              />
              <i
                onClick={() =>
                  setShowPassword({
                    ...showPassword,
                    oldPass: !showPassword.oldPass,
                  })
                }
                className={`fa-solid ${
                  showPassword.oldPass ? "fa-eye" : "fa-eye-slash"
                } open-eye`}
              ></i>
              <span style={{ color: "red" }}>{error.old_password}</span>
              {/* { (
              <div classNameName="text-danger"> */}
              {/* {inputErrorData?.old_password} */}
              {/* {error.phone}
              </div> */}
              {/* )} */}
            </div>

            <div className="form-group ">
              <label>New Password *</label>
              <input
                type={showPassword.pass ? "text" : "password"}
                placeholder="Enter New Password"
                value={resetData.new_password}
                onChange={handleChange}
                //   onChange={handleFieldChange}
                //   onFocus={handleErrorRemove}
                name="new_password"
              />
              {/* {!!inputErrorData?.new_password && (
              <div classNameName="text-danger">
                {inputErrorData?.new_password}
              </div>
            )} */}
              <i
                onClick={() =>
                  setShowPassword({
                    ...showPassword,
                    pass: !showPassword.pass,
                  })
                }
                className={`fa-solid ${
                  showPassword.pass ? "fa-eye" : "fa-eye-slash"
                } open-eye`}
              ></i>
              <span style={{ color: "red" }}>{error.new_password}</span>
                
              {sixPass === undefined ? null : (
                <span style={{ color: sixPass ? "green" : "red" }}>
                  Password must be at least 8 characters
                </span>
              )}
              <br></br>
              {uppr === undefined ? null : (
                <span style={{ color: uppr ? "green" : "red" }}>
                  At least one Upper Case character
                </span>
              )}
              <br />
              {spcl === undefined ? null : (
                <span style={{ color: spcl ? "green" : "red" }}>
                  At least one Special Character [e.g., ! @ # ?]
                </span>
              )}
            </div>

            <div className="form-group ">
              <label>Confirm Password *</label>
              <input
                type={showPassword.conPass ? "text" : "password"}
                placeholder="Enter New Password"
                value={resetData.confirm_password}
                onChange={handleChange}
                //   onChange={handleFieldChange}
                //   onFocus={handleErrorRemove}
                name="confirm_password"
              />
              {/* {!!inputErrorData?.confirm_password && (
              <div className="text-danger">
                {inputErrorData?.confirm_password}
              </div>
            )} */}
              <i
                onClick={() =>
                  setShowPassword({
                    ...showPassword,
                    conPass: !showPassword.conPass,
                  })
                }
                className={`fa-solid ${
                  showPassword.conPass ? "fa-eye" : "fa-eye-slash"
                } open-eye`}
              ></i>
              <span style={{ color: "red" }}>{error.confirm_password}</span>
            </div>
          </div>
          <div class="footer-btn">
            {/* <input
            type="submit"
            class="small-submit-btn"
            value="Save changes"
            onClick={handleUpdateUser}
          /> */}
            <button
              className="btn btn-outline-primary rounded-pill px-4 py-2 shadow-none"
              onClick={handleSubmit}
              //   disabled={resetStatus === "loading"}
            >
              {/* {resetStatus === "loading" && (
                <span
                  className="spinner-border spinner-border-sm mx-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )} */}
              Save changes
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
