import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Controller } from "swiper";
// import "swiper/css/bundle";
import { useDispatch, useSelector } from "react-redux";
import {
  fetch_all_channel_list,
  fetch_category_wise_all_video,
  fetch_Recommended_video,
  post_watch_history,
} from "../../redux/videoSlice";
import settings from "./SlideJSON/settings";
import channelSet from "./SlideJSON/channelSet";
import sstSet from "./SlideJSON/sstSet";
import Modal from "react-bootstrap/Modal";
import VideoPopup from "../VideoPopUp/VideoPopup";
import { useNavigate, useLocation } from "react-router-dom";
import Placeholder from "react-bootstrap/Placeholder";
import "./VideoBrowser.scss";
import { photoUrl } from "../../API";

export default function VideoBrowser() {
  // window.scrollTo(0, 0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    vid_category_wise_get_all,
    fetch_Recommended_video_data,
    fetch_all_channel_data,
    hide_video_status,
  } = useSelector((state) => state.video);
  const [show, setShow] = useState(false);
  const [videoDetails, setVideoDetails] = useState({});
  const [fullscreen, setFullscreen] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const handleClose = () => setShow(false);
  const handleShow = (e, item) => {
    setVideoDetails(item);
    setShow(true);
  };

  const handleOpenFullScreen = () => {
    setFullscreen(!fullscreen);
  };

  const handleCloseFullScreen = () => {
    setFullscreen(false);
  };

  useEffect(() => {
    dispatch(
      fetch_category_wise_all_video({
        userId:
          JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
            ? JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
            : state?.id,
      })
    );
    if (
      JSON.parse(localStorage.getItem("subUserId")) ||
      state?.id ||
      userDetails._id
    ) {
      dispatch(
        fetch_Recommended_video({
          userId:
            JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              ? JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              : state?.id,
        })
      );
    }
    dispatch(
      fetch_all_channel_list({
        userId:
          JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
            ? JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
            : state?.id,
      })
    );
  }, [state, dispatch, userDetails._id, hide_video_status]);

  // const ShowVideoCategoryWiseList = (e, id, name) => {
  //   navigate(`show-all/${name.replace(/\s/g, "-")}`, {
  //     state: { id: id, name: name, userId: state?.id },
  //   });
  // };

  const selectedChannel = (e, channelID, name) => {
    navigate(`selected-channel/${name.replace(/\s/g, "-")}`, {
      state: { channelID: channelID },
    });
  };

  const watchHistory = (e, videoID) => {
    try {
      dispatch(
        post_watch_history({
          videoId: videoID,
          userId:
            JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              ? JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              : state?.id,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  // const channelSet = {
  //   breakpoints: {
  //     320: {
  //       slidesPerView: 2,
  //       spaceBetween: 50,
  //     },
  //     // 767: {
  //     //   slidesPerView: 2,
  //     //   spaceBetween: 50,
  //     // },
  //     // 768: {
  //     //   slidesPerView: 2,
  //     //   spaceBetween: 5,
  //     // },
  //     991: {
  //       slidesPerView: 6,
  //       spaceBetween: 10,
  //     },
  //     1199: {
  //       slidesPerView: 2,
  //     },
  //     // 1366: {
  //     //   slidesPerView: 3,
  //     // },

  //   }
  // };
  return (
    <>
      <div className="vdo-bws">
        <div className="common-container">
          {fetch_Recommended_video_data !== null ? (
            <>
              <div className="vdo-bws__row">
                <div className="vdo-bws__label">
                  <p>Recommended For You</p>
                </div>
                <div className="vdo-bws__slider">
                  <div className="vdo-bws__wrapper">
                    {fetch_Recommended_video_data?.docs[0]?.recommendedVideos
                      ?.length !== 0 ? (
                      <>
                        {/* <Slider {...settings}></Slider> */}

                        <Swiper 
                  
                          slidesPerView={4.1}
                          spaceBetween={2}
                          // pagination={{
                          //   clickable: true,
                          // }}
                          modules={[Pagination]}
                          className="mySwiper"
                        >
                          {fetch_Recommended_video_data?.docs[0]?.recommendedVideos?.map(
                            (item, i) => (
                              <>
                                <SwiperSlide>
                                  {" "}
                                  <div
                                    key={i}
                                    className="rec-slider reset-foucs-v"
                                    onClick={(e) => {
                                      watchHistory(e, item._id);
                                      handleShow(e, item);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <img
                                      width="100%"
                                      height="100%"
                                      src={`https://img.youtube.com/vi/${item?.videoId}/hqdefault.jpg`}
                                      alt={item?.title}
                                    />
                                  </div>
                                </SwiperSlide>
                              </>
                            )
                          )}
                        </Swiper>
                      </>
                    ) : (
                      <>
                        <span
                          style={{
                            color: "white",
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "30px",
                          }}
                        >
                          Oops ! No Video found
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="vdo-bws__row">
                <div className="vdo-bws__label">
                  <Placeholder animation="glow">
                    <Placeholder
                      xs={4}
                      style={{
                        color: "white",
                      }}
                      className="mb-3"
                    />
                  </Placeholder>
                </div>
                <div className="vdo-bws__slider">
                  <div className="vdo-bws__wrapper">
                    <Slider {...settings}>
                      {[1, 2, 3, 4, 5, 6].map((i) => (
                        <div className="mb-4" key={i}>
                          <Placeholder animation="glow">
                            <Placeholder
                              className="rec-slider reset-foucs-v"
                              xs={6}
                              style={{
                                color: "white",
                                height: "200px",
                                width: "380px",
                              }}
                            />
                          </Placeholder>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </>
          )}
          {fetch_all_channel_data !== null ? (
            <>
              <div className="vdo-bws__row">
                <div className="vdo-bws__label">
                  <p>Channels</p>
                </div>
                <div className="vdo-bws__slider">
                  <div className="vdo-bws__wrapper">
                    {/* {...channelSet} */}

                    <Swiper
                      className="next-slider"
                      // when window width is >= 640px

                      // {...channelSet}
                      // slidesPerView={6}
                      spaceBetween={2}
                      // {...channelSet}
                      slidesPerView={8.2}
                      // spaceBetween={2}
                      // pagination={{
                      //   clickable: true,
                      // }}
                      //   modules={[Pagination]}
                      // className="mySwiper"
                    >
                      {fetch_all_channel_data?.map((item, i) => (
                        <SwiperSlide>
                          {" "}
                          <div
                            className="channel-icon reset-foucs-v profile_img"
                            key={i}
                            onClick={(e) => {
                              selectedChannel(e, item?._id, item?.channelTitle);
                            }}
                          >
                            <img
                              style={{ cursor: "pointer" }}
                              src={
                                photoUrl(
                                  "channelManagement",
                                  item?.channelThumbnail
                                )
                                  ? photoUrl(
                                      "channelManagement",
                                      item?.channelThumbnail
                                    )
                                  : "/assets/images/no-image.png"
                              }
                              alt={item?.channelTitle}
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="vdo-bws__row">
                <div className="vdo-bws__label">
                  <Placeholder animation="glow">
                    <Placeholder
                      xs={4}
                      style={{
                        color: "white",
                      }}
                      className="mb-3"
                    />
                  </Placeholder>
                </div>
                <div className="vdo-bws__slider">
                  <div className="vdo-bws__wrapper">
                    <Swiper
                      slidesPerView={6}
                      spaceBetween={40}
                      // pagination={{
                      //   clickable: true,
                      // }}
                      modules={[Pagination]}
                      className="mySwiper"
                    >
                      {[1, 2, 3, 4, 5, 6, 7].map((i) => (
                        <SwiperSlide>
                          {" "}
                          <div className="col-lg-3 col-md-6 mb-4" key={i}>
                            <Placeholder animation="glow">
                              <Placeholder
                                className="search-page__result__img"
                                xs={6}
                                style={{
                                  color: "white",
                                  height: "150px",
                                  width: "150px",
                                  borderRadius: "50%",
                                }}
                              />
                            </Placeholder>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
              </div>
            </>
          )}

          {vid_category_wise_get_all?.length !== 0 ? (
            <>
              {vid_category_wise_get_all?.map((item, i) => (
                <>
                  <div className="vdo-bws__row" key={i}>
                    <div className="vdo-bws__label channel_btn">
                      <p>{item?.title}</p>

                      {/* {item.video.length !== 4 && (
                        <span
                          style={{ color: "white", cursor: "pointer" }}
                          onClick={(e) =>
                            ShowVideoCategoryWiseList(e, item?._id, item?.title)
                          }
                        >
                          Show All
                        </span>
                      )} */}
                    </div>
                    <div className="vdo-bws__slider">
                      <div className="vdo-bws__wrapper">
                        {item.video.length !== 0 ? (
                          <>
                            {/* {...sstSet} */}

                            <Swiper
                              slidesPerView={5.2}
                              spaceBetween={3}
                              // pagination={{
                              //   clickable: true,
                              // }}
                              modules={[Pagination]}
                              className="mySwiper"
                            >
                              {item?.video?.map((item, i) => (
                                <>
                                  <SwiperSlide>
                                    {" "}
                                    <div
                                      key={i}
                                      className="rec-slider reset-foucs-v"
                                      onClick={(e) => {
                                        watchHistory(e, item._id);
                                        handleShow(e, item);
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img
                                        width="100%"
                                        height="100%"
                                        src={`https://img.youtube.com/vi/${item.videoId}/0.jpg`}
                                        alt={item?.title}
                                      />
                                    </div>
                                  </SwiperSlide>
                                </>
                              ))}
                            </Swiper>
                          </>
                        ) : (
                          <span
                            style={{
                              color: "white",
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "30px",
                            }}
                          >
                            Oops ! No video found
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </>
          ) : (
            <>
              {[1, 2, 3].map((i) => (
                <div className="vdo-bws__row" key={i}>
                  <div className="vdo-bws__label">
                    <Placeholder animation="glow">
                      <Placeholder
                        xs={4}
                        style={{
                          color: "white",
                        }}
                        className="mb-3"
                      />
                    </Placeholder>
                  </div>
                  <div className="vdo-bws__slider">
                    <div className="vdo-bws__wrapper">
                      <Slider {...settings}>
                        {[1, 2, 3, 4].map((i) => (
                          <div className="mb-4" key={i}>
                            <Placeholder animation="glow">
                              <Placeholder
                                className="rec-slider reset-foucs-v"
                                xs={6}
                                style={{
                                  color: "white",
                                  height: "200px",
                                  width: "380px",
                                }}
                              />
                            </Placeholder>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>

      <Modal
        fullscreen={fullscreen}
        show={show}
        onHide={() => {
          // watchHistory();
          handleClose();
        }}
        className={fullscreen ? "modal_pop_full" : "modal_pop"}
      >
        <VideoPopup
          fullscreen={fullscreen}
          handleOpenFullScreen={handleOpenFullScreen}
          handleCloseFullScreen={handleCloseFullScreen}
          videoDetails={videoDetails}
          hideVideo={handleClose}
        />
      </Modal>
    </>
  );
}
