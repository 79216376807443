import React, { useEffect, useState } from "react";
import axios from "axios";
import Footer from "../../Components/Layout/Footer/Footer";
import Header from "../../Components/Layout/Header/Header";
import Placeholder from "react-bootstrap/Placeholder";
import { formatDate, formatDateBlog, ReplaceImageUpload } from "../../helpers/utilts";
// import { useNavigate } from "react-router-dom";
import "./blog.scss";

function Blog() {
  // const navigate = useNavigate();
  const [blog_data, setBlog_data] = useState(null);

  useEffect(() => {
    async function getAllBlog() {
      axios({
        method: "get",
        url: "https://webdev.wordpress-developer.us/brainflix/wp-json/wp/v2/posts",
      })
        .then(function (response) {
          setBlog_data(response?.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    getAllBlog();
  }, []);

  return (
    <>
      <Header />
      <div className="blog common_gap">
        <div className="container">
          <div className="row px-2">
            <div className="col-lg-12 mb-4 mt-3">
              <h2>Blog</h2>
            </div>

            {blog_data !== null ? (
              <>
                {blog_data?.length
                  ? blog_data.map((item, i) => (
                      <div className="col-lg-4 col-md-6 my-3" key={i}>
                        <div className="card">
                          <img
                            key={i}
                            // src="/assets/images/1.png"
                            // src={item?.yoast_head_json?.og_image[0]?.url.replace(
                            //   `https://webdev.wordpress-developer.us/brainflix/wp-content/uploads/${formatDateBlog(
                            //     item.date
                            //   )}`,
                            //   "https://brainflix.com/blog/"
                            // )}
                            src={ReplaceImageUpload(item?.yoast_head_json?.og_image[0]?.url)}
                            className="card-img-top"
                            alt="img"
                            style={{
                              height: "300px",
                              width: "100%",
                              objectFit: "cover",
                            }}
                          />
                          <div className="card-body">
                            <h5>
                              {item?.yoast_head_json?.title.slice(0, 25)}
                              ....
                            </h5>
                            <p>
                              {item?.yoast_head_json?.og_description.slice(
                                0,
                                200
                              )}
                              ....
                            </p>
                            <div className="d-flex">
                              <span>
                                <i className="fa-solid fa-calendar mx-2"></i>
                                {new Date(item.date).toDateString()}
                              </span>
                              <span className="px-3">
                                <i className="fa-solid fa-clock mx-2"></i>
                                {new Date(item.date).toLocaleTimeString()}
                                {console.log("sdaadssssssssss", item.date)}
                              </span>
                            </div>
                            <div className="text-center">
                              <a
                                // href={item.link}
                                href={item?.link.replace(
                                  `https://webdev.wordpress-developer.us/brainflix/${formatDateBlog(
                                    item.date
                                  )}`,
                                  "https://brainflix.com/blog/"
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary mt-3"
                                  // onClick={(e) =>
                                  //   goToBlogInnerPage(
                                  //     e,
                                  //     item.id,
                                  //     item?.yoast_head_json?.title
                                  //   )
                                  // }
                                >
                                  View More
                                </button>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : "Blog Not Found"}
              </>
            ) : (
              <>
                {[1, 2, 3, 4, 5, 6].map((i) => (
                  <div className="col-lg-4 col-md-6 my-3" key={i}>
                    <div className="card">
                      <Placeholder animation="glow">
                        <Placeholder
                          xs={12}
                          style={{
                            color: "white",
                            height: "300px",
                          }}
                          className="mb-3"
                        />
                      </Placeholder>
                      <div className="card-body">
                        <h5>
                          {" "}
                          <Placeholder animation="glow">
                            <Placeholder
                              xs={12}
                              style={{
                                color: "white",
                              }}
                              className="mb-3"
                            />
                          </Placeholder>
                        </h5>
                        <p>
                          <Placeholder animation="glow">
                            <Placeholder
                              xs={12}
                              style={{
                                color: "white",
                                height: "100px",
                              }}
                              className="mb-3"
                            />
                          </Placeholder>
                        </p>
                        <p>
                          <Placeholder animation="glow">
                            <Placeholder
                              xs={8}
                              style={{
                                color: "white",
                              }}
                              className="mb-3"
                            />
                          </Placeholder>
                        </p>
                        <div className="text-center">
                          <Placeholder animation="glow">
                            <Placeholder.Button
                              xs={4}
                              style={{
                                color: "white",
                              }}
                              className="mb-3"
                            />
                          </Placeholder>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Blog;
