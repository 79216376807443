import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { validEmail } from "../../../helpers/utilts";
import {
  cheakForgotEmail,
  forgot_password,
  handleopenModal,
  loginRequest,
} from "../../../redux/authSlice";
import { logo_fetech } from "../../../redux/userDetailsSlice";

export default function ForgotPassword() {
  const {
    forgotPassword_status,
    forgotPassword_success_msg,
    status,
    isLoggedIn,
  } = useSelector((s) => s.auth);

  const [forgot, setForgot] = useState({
    email: "",
    password: "",
  });

  const [err, setErr] = useState("");
  const dipatch = useDispatch();
  const navigate = useNavigate();
  const fEmail = localStorage.getItem("forgotE");
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      if (value.length === 0) {
        setForgot({ ...forgot, [name]: value });
        setErr("Please Enter your Email");
      } else if (!validEmail(value)) {
        setForgot({ ...forgot, [name]: value });
        setErr("Enter a valid Email");
      } else {
        setForgot({ ...forgot, [name]: value });
        setErr("");
      }
    } else {
      setForgot({ ...forgot, [name]: value });
    }
  };
  const handleForgot = (e) => {
    e.preventDefault();
    // if (forgotPassword_success_msg) {
    //   dipatch(loginRequest(forgot));
    // }

    if (forgot.email.length === 0) {
      setErr("Please Enter your Email");
    } else if (!validEmail(forgot.email)) {
      setErr("Enter a valid Email");
    } else {
      localStorage.setItem("forgotE", forgot.email);
      dipatch(forgot_password({ email: forgot.email }));
      navigate("/log-in");
      // window?.location.reload();
    }
  };
  const { user_data, logofetch_data } = useSelector(
    (state) => state.userDetailsss
  );
  // useEffect(() => {
  //   dipatch(
  //     logo_fetech({
  //       setting_slug: "logo",
  //     })
  //   );
  //   dipatch(cheakForgotEmail());
  //   if (forgotPassword_success_msg) {
  //     setForgot({ ...forgot, email: fEmail });
  //   }
  //   if (isLoggedIn) {
  //     navigate("/account-settings");
  //     dipatch(handleopenModal());
  //     localStorage.removeItem("forgotPassword_success_msg");
//     localStorage.removeItem("forgotE");
  //   }
  // }, [
  //   dipatch,
  //   navigate,
  //   forgotPassword_success_msg,
  //   fEmail,
  //   isLoggedIn,
  //   setForgot,
  //   forgot,
  // ]);

  // useEffect(() => {
  //   dipatch(
  //     logo_fetech({
  //       setting_slug: "logo",
  //     })
  //   );
  // })

  return (
    <>
      <div className="loginPAge">
        <div className="loginPageHead">
          {/* <Link to="/" className="logo">
            <img src="/assets/images/logo.svg" alt="brain" />
          </Link> */}

          <NavLink to="/" className="logo-af">
            {/* <img src="/assets/images/logo.svg" alt="" /> */}
            <img
              id="logoo"
              src="/assets/images/1658999284845_android-chrome-192x192.png"
              // src={`https://admin-brainflix.dedicateddevelopers.us/uploads/settings/${logofetch_data?.setting_value}`}
              alt=""
            />
            {/* setting_value */}
          </NavLink>
        </div>

        <div className="loginFormWraper">
          <div className="loginForm">
            <h2>
              Forgot password{" "}
              {/* {forgotPassword_success_msg ? "Login" : "Forgot password"}{" "} */}
            </h2>
            <div className="form-group">
              <div className="label">Email</div>
              <input
                type="text"
                name="email"
                id=""
                className="form-control py-2"
                placeholder="Your@email.com"
                onChange={handleChange}
                value={forgot.email}
              />
              <span style={{ color: "red" }}>{err}</span>
            </div>
            {/* {forgotPassword_success_msg && (
              <div className="form-group">
                <div className="label">Password</div>
                <input
                  type="text"
                  name="Password"
                  id=""
                  className="form-control py-2"
                  placeholder="Enter your New Password"
                  onChange={handleChange}
                  value={forgot.password}
                />
                {forgot.password.length && (
                  <span style={{ color: "red" }}>
                    This is temporary password please change the password
                  </span>
                )}
              </div>
            )} */}

            <div className="submit-btn pt-3">
              <button
                className="btn btn-primary w-100 py-2 "
                onClick={handleForgot}
                disabled={
                  forgotPassword_status === "loading" || status === "loading"
                }
              >
                {status === "loading" && (
                  //  ||
                  //   forgotPassword_status === "loading"
                  <span
                    className="spinner-border spinner-border-sm mx-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                Forgot password
                {/* {forgotPassword_success_msg ? "Login" : "Forgot password"} */}
              </button>
            </div>
            <div className="links pt-5">
              <p>
                Need an account?
                <Link
                  //  to="/sign-up"
                  to="/SubmitLoginPage"
                >
                  Create a new account
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
