/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import "./AfterLoginHeader.scss";
import { useDispatch, useSelector } from "react-redux";
import { Logout, user_Logout } from "../../../redux/authSlice";
import { baseURL, photoUrl } from "../../../API";
import {
  fetch_userdata_data,
  logo_fetech,
} from "../../../redux/userDetailsSlice";
import {
  fetch_Recommended_video,
  fetch_category_wise_all_video,
  fetch_all_channel_list,
} from "../../../redux/videoSlice";
import { Placeholder } from "react-bootstrap";

export default function AfterLoginHeader() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { logout_mssg, logout_status } = useSelector((s) => s.auth);
  const { user_data, logofetch_data } = useSelector(

    (state) => state.userDetailsss
  );
    console.log("🚀 ~ file: AfterLoginHeader.jsx ~ line 26 ~ AfterLoginHeader ~ logofetch_data", logofetch_data)
  const [pathnameExist, setpathnameExist] = useState(false);
  const [drop, setDrop] = useState(false);
  const [menu, setMenu] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    dispatch(
      fetch_userdata_data({
        userId: JSON.parse(localStorage.getItem("subUserId"))
          ? JSON.parse(localStorage.getItem("subUserId"))
          : JSON.parse(localStorage.getItem("UserID")) || userDetails?._id,
      })
    );
    dispatch(
      logo_fetech({
        setting_slug: "logo",
      })
    );
  }, [dispatch]);

  const logout = () => {
    dispatch(user_Logout());
  };

  const handleClickAway = () => {
    setDrop(false);
  };
  useEffect(() => {
    if (logout_mssg === "success") {
      navigate("/");
      dispatch(Logout());
    }
  }, [logout_mssg]);

  const backAllUserListPage = (e) => {
    localStorage.removeItem("UserID");
    localStorage.removeItem("subUserId");
  };

  const shuffleAllVideos = () => {
    try {
      dispatch(
        fetch_Recommended_video({
          userId:
            JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              ? JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              : state?.id,
        })
      );
      dispatch(
        fetch_category_wise_all_video({
          userId:
            JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              ? JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              : state?.id,
        })
      );
      dispatch(
        fetch_all_channel_list({
          userId:
            JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              ? JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              : state?.id,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="af-login">
        <nav className="navbar-expand-xl">
          <div className="container__af-login">
            <div className="af-header">
              <div className="row justify-content-center align-items-center">
                <div className="col-2">
                  <NavLink to="/" className="logo-af">
                    {/* <img src="/assets/images/logo.svg" alt="" /> */}
                    <img
                      src={`https://admin.brainflix.com/uploads/settings/${logofetch_data?.setting_value}`}
                      alt=""
                    />
                    {/* setting_value */}
                  </NavLink>
                </div>
                <div className="col-lg-8 col-2  list_view order-lg-0 order-1">
                  {pathnameExist ? null : (
                    <button
                      className="navbar-toggler d-lg-none d-block"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                      onClick={() => setMenu(!menu)}
                    >
                      <span className="navbar-toggler-icon">
                        <i className="fa-solid fa-bars"></i>
                      </span>
                    </button>
                  )}

                  <div
                    className={
                      pathnameExist
                        ? "d-none"
                        : menu
                        ? "nav-links active"
                        : "nav-links"
                    }
                  >
                    <button
                      className="btn cross_btn"
                      onClick={() => setMenu(false)}
                    >
                      <i className="fa-solid fa-xmark"></i>
                    </button>
                    <ul>
                      {/* <ul> */}
                      <li>
                        <NavLink to="/browser">
                          <img
                            src="/assets/images/icons/home-icon.svg"
                            alt=""
                          />
                          Home
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="search">
                          <img
                            src="/assets/images/icons/search-icon.svg"
                            alt=""
                          />
                          SEARCH
                        </NavLink>
                      </li>
                      <li style={{ textTransform: "uppercase" }}>
                        <NavLink to="favorites">
                          <img
                            src="/assets/images/icons/heart-icon.svg"
                            alt=""
                          />
                          favorite
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="recent">
                          <img
                            src="/assets/images/icons/recent-icon.svg"
                            alt=""
                          />
                          RECENT
                        </NavLink>
                      </li>

                      <li onClick={shuffleAllVideos}>
                        <span>
                          <img
                            src="/assets/images/icons/shuffle-icon.svg"
                            alt=""
                          />
                          SHUFFLE
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  className={
                    pathnameExist
                      ? "d-none"
                      : "col-lg-2 col-8 order-lg-1 order-0"
                  }
                >
                  <button
                    className="d-flex align-items-center justify-content-end profile_name_sec btn "
                    // onFocus={() => setDrop(!drop)}
                    // onBlur={() => setDrop(!drop)}

                    onClick={() => setDrop(!drop)}
                    // tabIndex="0"
                  >
                    <div className="af-header__username d-lg-block d-none">
                      {user_data?.fullName !== undefined ? (
                        <>
                          {" "}
                          <p>{user_data?.fullName}</p>
                        </>
                      ) : (
                        <>
                          {" "}
                          <Placeholder animation="glow">
                            <Placeholder
                              style={{ color: "white", width: "48px" }}
                              className="rec-slider reset-foucs-v"
                              xs={4}
                            />
                          </Placeholder>
                        </>
                      )}
                    </div>
                    {user_data?.profile_image !== undefined ? (
                      <>
                        {" "}
                        <div className="user-img">
                          <img
                            src={photoUrl(
                              "user/profile_pic",
                              user_data?.profile_image
                            )}
                            alt="my"
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="user-img" style={{ color: "white" }}>
                          <Placeholder animation="glow">
                            <Placeholder
                              className="rec-slider reset-foucs-v"
                              xs={6}
                              style={{
                                color: "white",
                                height: "55px",
                                width: "48px",
                              }}
                            />
                          </Placeholder>
                        </div>
                      </>
                    )}
                  </button>
                </div>

                <div
                  className={
                    pathnameExist ? "col-lg-2 col-8 text-end " : "d-none"
                  }
                >
                  <button className="cancle-btn-menu-head">CANCEL</button>
                </div>
              </div>
            </div>
          </div>

          {drop ? (
            <div className="dropdown" onMouseLeave={handleClickAway}>
              <ul>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <NavLink to="first-profile" onClick={backAllUserListPage}>
                    <span>Switch or Edit Profile</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="account-settings">
                    <span>Account Setting</span>
                  </NavLink>
                </li>
                {/* <li>
                    <NavLink to="user-preference">
                      <span>Preference</span>
                    </NavLink>
                  </li> */}
                <li>
                  <span onClick={() => navigate("help")}>Help</span>
                </li>
                <li>
                  <span onClick={() => navigate("hidevideo")}>
                    Hidden Videos
                  </span>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li onClick={logout}>
                  <span>
                    {logout_status === "loading" && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    Log Out
                  </span>
                </li>
              </ul>
            </div>
          ) : null}
        </nav>
      </div>
    </>
  );
}
