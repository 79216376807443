import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import AfterLoginHeader from '../../Components/Layout/AfterLoginHeader/AfterLoginHeader';
import Footer from "../../Components/Layout/Footer/Footer";
import Header from "../../Components/Layout/Header/Header";
import { validEmail } from "../../helpers/utilts";
import { user_Contact_us } from "../../redux/userDetailsSlice";
import "./contact.scss";

function Contact() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const { contactus_status, contactus_data } = useSelector(
  //   (s) => s.userDetailsss
  // );

  // const { cmsgeneral_data } = useSelector((s) => s.cms);

  const [contaccc, setcontaccc] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [error, setError] = useState({ name: "", email: "", message: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      if (value.length === 0) {
        setError({ ...error, email: "Please enter your email" });
        setcontaccc({ ...contaccc, [name]: value });
      } else {
        setError({
          ...error,
          email: "",
        });
        setcontaccc({ ...contaccc, [name]: value });
      }
    }
    if (name === "name") {
      if (value.length === 0) {
        setError({ ...error, name: "Please enter your name" });
        setcontaccc({ ...contaccc, [name]: value });
      } else {
        setError({
          ...error,
          name: "",
        });
        setcontaccc({ ...contaccc, [name]: value });
      }
    }

    if (name === "message") {
      if (value.length === 0) {
        setError({ ...error, message: "Please enter your message" });
        setcontaccc({ ...contaccc, [name]: value });
      } else {
        setError({
          ...error,
          message: "",
        });
        setcontaccc({ ...contaccc, [name]: value });
      }
    }
  };

  const handlecontact = (e) => {
    e.preventDefault();
    setError({
      name: "",
      email: "",
      message: "",
    });
    if (
      contaccc.email.length === 0 &&
      contaccc.name.length === 0 &&
      contaccc.message.length === 0
    ) {
      setError({
        email: "Please enter your email",
        name: "Please enter your name",
        message: "Please enter your message",
      });
    } else if (contaccc.email.length === 0) {
      setError({ ...error, email: "Please enter your email" });
    } else if (!validEmail(contaccc.email)) {
      setError({
        ...error,
        email: "Please Enter a vaild Email",
      });
    } else if (contaccc.name.length === 0) {
      setError({
        ...error,
        name: "Please enter your name",
      });
    } else if (contaccc.message.length === 0) {
      setError({
        ...error,
        message: "Please enter your message",
      });
    } else {
      dispatch(user_Contact_us(contaccc));
      toast.success(
        "Thank you for contacting us! You will hear back from us via email if a response is needed."
      );
      navigate("/browser");
    }
  };

  return (
    <>
      {window.location.pathname.toLowerCase() === "/contact" ? (
        <Header />
      ) : null}

      <div className="contact common_gap">
        <div className="container my-4">
          <div className="row px-3">
            <div className="col-lg-12 mb-5 ">
              <h2>Contact Us</h2>
            </div>

            <form onSubmit={(e) => handlecontact(e)}>
              <div className="col-lg-6 mx-auto">
                <div className="form-group mt-3">
                  <label>Name</label>
                  <input
                    type="text"
                    id=""
                    className="form-control py-2"
                    placeholder="Your Name"
                    onChange={handleChange}
                    value={contaccc.name}
                    name="name"
                  />
                  <span style={{ color: "red" }}>{error.name}</span>
                </div>
                <div className="form-group mt-3">
                  <label>Email</label>
                  <input
                    type="text"
                    id=""
                    className="form-control py-2"
                    placeholder="Your@email.com"
                    onChange={handleChange}
                    value={contaccc.email}
                    name="email"
                  />
                  <span style={{ color: "red" }}>{error.email}</span>
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="exampleFormControlTextarea1">Message</label>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows={3}
                    placeholder="Enter your message here"
                    onChange={handleChange}
                    value={contaccc.message}
                    name="message"
                  />
                  <span style={{ color: "red" }}>{error.message}</span>
                </div>
                <div className="text-center mt-4">
                  <button
                    className="btn btn-primary rounded-pill"
                    // onClick={(e) => handlecontact(e)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {window.location.pathname.toLowerCase() === "/contact" ? (
        <Footer />
      ) : null}
    </>
  );
}

export default Contact;
