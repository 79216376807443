/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./Editsubprofile.scss";

import {
  fetch_userdata_data,
  All_avter_data,
  Subuser_Edit,
} from "../../../src/redux/userDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { photoUrl } from "../../API";
import Modal from "react-bootstrap/Modal";
import { context } from "../../hooks";
import { toast } from "react-toastify";
export default function EditParentUser() {
  // const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathname = useParams();
  const [edit_usersss, setEdit_usersss] = useState({
    fullName: "",
    ageGroup: [],
  });
  const [isSubmit, setIsSubmit] = useContext(context);

  const [error, setError] = useState({
    fullName: "",
    ageGroup: [],
  });

  const [show, setShow] = useState(false);

  const [selectedImage, setSelectedImage] = useState("");
  const stat_options = ["4-6", "7-9", "10-12", "13+"];
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const { All_avter_datass, user_data, Subuser_Edit_status } = useSelector(
    (s) => s.userDetailsss
  );

  useEffect(() => {
    if (userDetails?._id) {
      dispatch(
        fetch_userdata_data({
          userId:
            JSON.parse(localStorage.getItem("UserID")) || userDetails?._id,
        })
      );
    }
    dispatch(All_avter_data());
  }, [dispatch, navigate]);

  useEffect(() => {
    if (user_data?.fullName) {
      setEdit_usersss({
        ...edit_usersss,
        fullName: user_data?.fullName,
        ageGroup: user_data?.ageGroup,
      });
    }
  }, [user_data]);

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
  };

  const selectImage = (e, name) => {
    const image = All_avter_datass?.find((item) => item === name);
    setSelectedImage(image);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "fullName") {
      if (value.length === 0) {
        setError({ ...error, fullName: "Please Enter Your Name" });
        setEdit_usersss({ ...edit_usersss, [name]: value });
      } else {
        setError({
          ...error,
          ageGroup: [],
          fullName: "",
        });
        setEdit_usersss({ ...edit_usersss, [name]: value });
      }
    }
  };
  const handleChangeforcheckbox = (val) => {
    const index = edit_usersss?.ageGroup?.findIndex((x) => x === val);
    if (index > -1) {
      const data = edit_usersss?.ageGroup?.filter((x) => x !== val);
      setEdit_usersss({
        ...edit_usersss,
        ageGroup: data,
      });
      console.log("not ok !");
    } else {
      console.log("ok !");
      setEdit_usersss({
        ...edit_usersss,
        ageGroup: [...edit_usersss?.ageGroup, val],
      });
    }
  };

  const handleEdituser = (e) => {
    e.preventDefault();

    if (!isSubmit) {
      setIsSubmit(true);
    } else {
      setError({
        fullName: "",
        ageGroup: [],
        userId: "",
        profile_image: "",
      });
      if (edit_usersss.fullName.length === 0) {
        setError({
          fullName: "Please Enter Your FullName",
        });
      } else {
        const data = {
          fullName: edit_usersss?.fullName,
          ageGroup: edit_usersss?.ageGroup,
          userId: pathname?.id,
          profile_image: selectedImage
            ? selectedImage
            : user_data?.profile_image,
        };

        dispatch(Subuser_Edit(data));
        toast.success("Profile Updated Successfully");
        navigate("/browser/first-profile");
      }
      setIsSubmit(false);
    }
  };

  return (
    <>
      <div className="single-wrapper-whole">
        <div className="profile-creation">
          <div className="row align-items-center justify-content-center px-3">
            <div className="col-lg-6 order-lg-0 order-1">
              <div className="profile-creation__heading">
                <h2>Edit Profile</h2>
              </div>

              <div className="profile-creation__body">
                <div className="profile-creation__form">
                  <div className="profile-creation-input">
                    <div className="profile-creation-input__label">
                      <label htmlFor="">Profile Name</label>
                      <input
                        type="text"
                        name="fullName"
                        id=""
                        onChange={handleChange}
                        value={edit_usersss?.fullName}
                      />
                    </div>
                    <span style={{ color: "red" }}>{error.fullName}</span>
                  </div>

                  <div className="profile-creation__notation">
                    <p>
                      Select one or more age groups for which videos should be
                      personalized for:
                    </p>
                  </div>

                  <div className="profile-creation__lists">
                    <ul>
                      {edit_usersss?.ageGroup?.length !== 0 &&
                        edit_usersss?.ageGroup?.length !== 0 &&
                        stat_options?.map((val, i) => (
                          <>
                            <li key={i}>
                              <div className="profile-cration__lists__item">
                                <div className="custom-checkbox">
                                  <label>
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        edit_usersss?.ageGroup?.length !== 0 &&
                                        edit_usersss?.ageGroup?.includes(val)
                                      }
                                      value={val}
                                      onChange={(e) => {
                                        handleChangeforcheckbox(e.target.value);
                                      }}
                                    />
                                    <span></span>
                                  </label>
                                </div>
                                <p>{val}</p>
                              </div>
                            </li>
                          </>
                        ))}
                    </ul>
                  </div>

                  <div className="profile-creation__button">
                    <button
                      disabled={Subuser_Edit_status === "loading"}
                      onClick={(e) => {
                        handleEdituser(e);
                      }}
                    >
                      {Subuser_Edit_status === "loading" && (
                        <span
                          className="spinner-border spinner-border-sm mx-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                      SAVE
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 order-lg-1 order-0 mb-lg-0 mb-4">
              <div className="profile-creation__img">
                <div className="profile-creation-sec">
                  <img
                    style={{ width: "14rem" }}
                    src={
                      selectedImage
                        ? photoUrl("user/profile_pic", selectedImage)
                        : photoUrl("user/profile_pic", user_data?.profile_image)
                    }
                    alt="brainflix"
                  />
                  <button className="edit-profile-button" onClick={handleShow}>
                    <img src="/assets/images/edit-prof.svg" alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        className="modal_pop"
        id="modallll"
      >
        <div className="single-wrapper-whole mb-4">
          <div className="profile-creation-avatar">
            <div className="container">
              <div className="profile-creation__heading">
                <h2>Choose Your Avatar</h2>
              </div>

              <div className="row">
                {All_avter_datass?.map((item) => {
                  return (
                    <>
                      <div className="col-md-2 new-colm">
                        <button
                          className="sdaaaaaaaaa btn mx-lg-0 mx-auto mt-lg-0 mt-2 "
                          onClick={(e) => {
                            selectImage(e, item);
                            handleClose();
                          }}
                        >
                          <img
                            src={photoUrl("user/profile_pic", item)}
                            alt=""
                          />
                        </button>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
