import React from "react";
import "./profileselection.scss";

const ProfileSelection = () => {
  return (
    <div className="profile-selection-avatar">
      <div className="row align-items-center justify-content-center first_profile-section">
        <div className="col-lg-12 text-center">
          <div className="profile-creation__heading">
            <h6>Who’s Watching?</h6>
          </div>
        </div>
        <div className="col-lg-12 m-auto">
          <div className="d-flex flex-wrap justify-content-between">
            <button className="icon_bg btn mx-sm-0 mx-auto mt-sm-0 mt-2">
              <img src="/assets/images/avatars/1.png" alt="img" />
            </button>
            <button className="icon_bg btn mx-sm-0 mx-auto  mt-sm-0 mt-2">
              <img src="/assets/images/avatars/2.png" alt="img" />
            </button>
            <button className="plus_sign_bg  btn mx-sm-0 mx-auto  mt-sm-0 mt-2">
              <img src="/assets/images/plus.svg" alt="img" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSelection;
