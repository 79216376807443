import React, { useEffect, useState } from "react";
import { Modal, Placeholder } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
// import { photoUrl } from "../../API";
import { fetch_favourite, post_watch_history } from "../../redux/videoSlice";
import { useLocation } from "react-router-dom";
import VideoPopup from "../VideoPopUp/VideoPopup";
import "./Favourites.scss";

export default function Favourites() {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const {
    fetch_favourite_data,
    fetch_favourite_status,
    favourite_status,
    hide_video_status,
  } = useSelector((s) => s.video);
  const [show, setShow] = useState(false);
  const [videoDetails, setVideoDetails] = useState({});
  const [fullscreen, setFullscreen] = useState(false);

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const handleOpenFullScreen = () => {
    setFullscreen(!fullscreen);
  };

  const handleCloseFullScreen = () => {
    setFullscreen(false);
  };
  const handleClose = () => setShow(false);
  const handleShow = (e, item) => {
    setVideoDetails(item);
    setShow(true);
  };

  useEffect(() => {
    async function callFetchFavBefore() {
      dispatch(
        fetch_favourite({
          userId:
            JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              ? JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              : state?.id,
        })
      );
    }
    callFetchFavBefore();
  }, [dispatch, userDetails._id, state, favourite_status, hide_video_status]);
  const watchHistory = (e, videoID) => {
    try {
      dispatch(
        post_watch_history({
          videoId: videoID,
          userId:
            JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              ? JSON.parse(localStorage.getItem("subUserId")) || userDetails._id
              : state?.id,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="search-page">
        <div className="common-container">
          <div className="favourites">
            <div className="fabourites__heading">
              <h2 style={{ fontSize: "28px" }}>
                {" "}
                <img
                  style={{ width: "34px" }}
                  src="/assets/images/red-heart.svg"
                  alt=""
                />{" "}
                Your favorite videos
              </h2>
            </div>
          </div>

          <div className="search-page__result">
            <div className="row">
              {fetch_favourite_status === "loading" ? (
                <>
                  {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
                    <div className="col-lg-3 col-md-3 col-12 mb-4" key={i}>
                      <Placeholder animation="glow">
                        <Placeholder
                          className="rec-slider reset-foucs-v"
                          xs={6}
                          style={{
                            color: "white",
                            height: "200px",
                            width: "300px",
                          }}
                        />
                      </Placeholder>
                    </div>
                  ))}
                </>
              ) : fetch_favourite_data?.length ? (
                fetch_favourite_data
                  ?.map((x, i) => x?.videoId)
                  .map((fab, i) => (
                    <div
                      className="col-lg-3 col-md-6 mb-4"
                      key={i}
                      onClick={(e) => {
                        watchHistory(e, fab._id);
                        handleShow(e, fab);
                      }}
                    >
                      <div
                        class="rec-slider reset-foucs-v"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={`https://img.youtube.com/vi/${fab?.videoId}/0.jpg`}
                          alt={fab?.title}
                        />
                      </div>
                    </div>
                  ))
              ) : (
                <span
                  style={{
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "30px",
                  }}
                >
                  Oops ! No video found
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        fullscreen={fullscreen}
        show={show}
        onHide={() => {
          // watchHistory();
          handleClose();
        }}
        className={fullscreen ? "modal_pop_full" : "modal_pop"}
      >
        <VideoPopup
          videoDetails={videoDetails}
          hideVideo={handleClose}
          fullscreen={fullscreen}
          handleOpenFullScreen={handleOpenFullScreen}
          handleCloseFullScreen={handleCloseFullScreen}
        />
      </Modal>
    </>
  );
}
