/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  // checkAmnt,
  passspecial,
  passwordRegex,
  validEmail,
} from "../../../helpers/utilts";
import tickIcon from "../../../assets/images/tickic1.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetch_general_cms } from "../../../../src/redux/cmsSlice";
import {
  signupRequest,
  // socailResData,
  socailSignup,
  socialSignIN,
} from "../../../redux/authSlice";
// import { Placeholder, Card } from "react-bootstrap";

import "./SignUp.scss";
import app from "../../../firebase/FirebaseConfig";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "@firebase/auth";

import { toast } from "react-toastify";
import { logo_fetech } from "../../../redux/userDetailsSlice";
export default function SignUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [signup, setSignup] = useState({
    email: "",
    password: "",
    conPass: "",
    chk18: false,
    chkTrms: true,
  });
  const [error, setError] = useState({
    email: "",
    password: "",
    conPass: "",
    chk18: "",
    chkTrms: "",
  });
  const [eightPass, setEightPass] = useState();
  const [spcl, setSpcl] = useState();
  const [uppr, setUppr] = useState();

  const [count, setCount] = useState(0);
  const [start, setStart] = useState(false);
  const [checkAgree, setcheckAgree] = useState(false);
  const [showPassword, setShowPassword] = useState({
    pass: false,
    conPass: false,
  });
  const { status, isLoggedIn, socialSignIN_status, userDetails } = useSelector(
    (s) => s.auth
  );
  const { cmsgeneral_data } = useSelector((s) => s.cms);

  const socailData = localStorage.getItem("socialSignIN_paylod");
  useEffect(() => {
    document.body.classList.add("width_bg");
    return () => {
      document.body.classList.remove("width_bg");
    };
  });
  useEffect(() => {
    if (signup?.password?.length === 0) {
      setEightPass(undefined);
      setSpcl(undefined);
      setUppr(undefined);
    } else {
      if (signup?.password?.length > 7) {
        setEightPass(true);
      }
      if (signup?.password?.length < 8) {
        setEightPass(false);
      }
      if (!passwordRegex.test(signup?.password)) {
        setUppr(false);
      }
      if (passwordRegex.test(signup?.password)) {
        setUppr(true);
      }
      if (!passspecial.test(signup?.password)) {
        setSpcl(false);
      }
      if (passspecial.test(signup?.password)) {
        setSpcl(true);
      }
    }
  }, [signup.password]);
  useEffect(() => {
    if (eightPass && uppr && spcl) {
      setTimeout(() => {
        setEightPass(undefined);
        setSpcl(undefined);
        setUppr(undefined);
      }, 500);
    }
  }, [eightPass, uppr, spcl]);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "email") {
      if (value.length === 0) {
        setError({
          ...error,
          email: "Please enter your Email",
        });
        setSignup({ ...signup, [name]: value });
      } else if (!validEmail(value)) {
        setError({
          ...error,
          email: "Please Enter A Vaild Email",
        });
        setSignup({ ...signup, [name]: value });
      } else {
        setError({
          ...error,
          email: "",
        });
        setSignup({ ...signup, [name]: value });
      }
    }
    if (name === "password") {
      if (value.length === 0) {
        setError({
          ...error,
          password: "Please enter your password",
        });
        setSignup({ ...signup, [name]: value });
      } else {
        setError({
          ...error,
          password: "",
        });
        setSignup({ ...signup, [name]: value });
      }
    }
    if (name === "conPass") {
      if (value.length === 0) {
        setError({
          ...error,
          conPass: "Please enter your Re-enter password",
        });
        setSignup({ ...signup, [name]: value });
      }
      if ("password" === value) {
        setError({
          ...error,
          conPass: "Please enter currect Re-enter password",
        });
      } else {
        setError({
          ...error,
          conPass: "",
        });
        setSignup({ ...signup, [name]: value });
      }
    }
    if (type === "checkbox") {
      if (name === "chk18") {
        if (!checked) {
          setSignup({ ...signup, [name]: checked });
          setError({
            ...error,
            chk18: "Age confirmation must be accepted",
          });
        } else {
          setSignup({ ...signup, [name]: checked });
          setError({
            ...error,
            chk18: "",
          });
        }
      }
    }
  };

  useEffect(() => {
    dispatch(
      fetch_general_cms({
        slug: "lets-get-started",
      })
    );
  }, [dispatch]);
  // function thanksdata() {
  //   dispatch(
  //     fetch_general_cms({
  //       slug: "thank-you-signup",
  //     })
  //   );
  //   setThankyou();
  // }
  const restErr = () => {
    setError({
      email: "",
      password: "",
      conPass: "",
      chk18: "",
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    restErr();
    if (
      signup.password.length === 0 &&
      signup.conPass.length === 0 &&
      signup.email.length === 0 &&
      !signup.chk18
    ) {
      setError({
        email: "Please Enter Your Email",
        password: "Please Enter your password",
        conPass: "Please Enter your Re-type password",
        chk18: "Age confirmation must be accepted",
      });
    } else if (signup.email.length === 0) {
      setError({
        ...error,
        email: "Please Enter Your Email",
      });
    } else if (!validEmail(signup.email)) {
      setError({
        ...error,
        email: "Please Enter a vaild Email",
      });
    } else if (signup.password.length === 0) {
      setError({
        ...error,
        password: "Please Enter your password",
      });
    } else if (signup.password.length < 8) {
      setError({
        ...error,
        password: "",
      });
    } else if (signup.conPass.length === 0) {
      setError({
        ...error,
        conPass: "Please Enter your Re-type password",
      });
    } else if (signup.password !== signup.conPass) {
      setError({
        ...error,
        conPass: "Passwords do not match",
      });
    } else if (!signup.chk18) {
      setError({
        ...error,
        chk18: "Age confirmation must be accepted",
      });
    } else {
      const data = {
        email: signup.email,
        password: signup.password,
      };
      dispatch(signupRequest(data));
    }
  };
  const handleSubmitForAgreeCheck = (e) => {
    e.preventDefault();
    if (signup.email.length === 0 && !signup.chk18 && !signup.chkTrms) {
      setError({
        email: "Please Enter Your Email",
        chk18: "Age confirmation must be accepted",
      });
    } else if (signup.email.length === 0) {
      setError({
        ...error,
        email: "Please Enter Your Email",
      });
    } else if (!validEmail(signup.email)) {
      setError({
        ...error,
        email: "Please Enter a vaild Email",
      });
    } else if (!signup.chk18) {
      setError({
        ...error,
        chk18: "Age confirmation must be accepted",
      });
    } else {
      setcheckAgree(true);
      // console.log("ok");
    }
  };
  const handleGoogleLogin = (e) => {
    const auth = getAuth(app);
    const gmailProvier = new GoogleAuthProvider();
    signInWithPopup(auth, gmailProvier)
      .then((result) => {
        const user = result.user;

        const credential = GoogleAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;

        let payload = {
          fullName: user?.displayName,
          email: user?.email,
          phone: user?.phoneNumber,
          registerType: "Google",
          social_id: user?.providerData[0].uid,
          // deviceToken: accessToken,
          // deviceType: window.navigator.userAgent,
        };
        dispatch(socialSignIN(payload));
        localStorage.setItem("socialSignIN_paylod", JSON.stringify(payload));
        localStorage.setItem("socialSignIN_msg", true);
        // dispatch(socailResData(payload));
        setStart(true);
        // dispatch(socialSignIN(payload));
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        if (errorCode === "auth/account-exists-with-different-credential") {
          toast.error("You have already registered with this email");
        }
        if (errorCode === "auth/popup-closed-by-user") {
          toast.error("You have already registered with this email");
        }
        console.log("error in google sign in ", error);
        // ...
      });
  };
  useEffect(() => {
    dispatch(socailSignup());
  }, [socailData]);
  useEffect(() => {
    if (start) {
      setTimeout(() => {
        setCount((x) => x + 1);
      }, 1000);
    } else {
      setCount(0);
    }
  }, [start, count]);
  useEffect(() => {
    if (count === 120) {
      localStorage.removeItem("socialSignIN_paylod");
      localStorage.setItem("socialSignIN_msg", false);
      setStart(false);
    }
  }, [count]);

  const goTODashBoard = () => {
    navigate("/browser/first-profile");
    localStorage.removeItem("socialSignIN_paylod");
    localStorage.removeItem("socialSignIN_msg");
  };
  const { user_data, logofetch_data } = useSelector(
    (state) => state.userDetailsss
  );

  // useEffect(() => {
  //   dispatch(
  //     logo_fetech({
  //       setting_slug: "logo",
  //     })
  //   );
  // })

  return (
    <>
      <div className="loginPAge">
        <div className="loginPageHead">
          {/* <Link to="/" className="logo">
            <img src="/assets/images/logo.svg" alt="brain" />
          </Link> */}
          <NavLink to="/" className="logo-af">
            <img
              src="/assets/images/1658999284845_android-chrome-192x192.png"
              alt=""
              id="logoo"
            />
            {/* <img
              src={`https://admin-brainflix.dedicateddevelopers.us/uploads/settings/${logofetch_data?.setting_value}`}
              alt=""
            /> */}
            {/* setting_value */}
          </NavLink>
        </div>

        {isLoggedIn ? (
          <>
            <div className="thank-you-sec">
              <div className="thank-you-heading">
                <h5>Thank you!</h5>
              </div>
              <div className="heading-text">
                <p className="para">
                  You will soon receive an email confirmation along with
                  information about our referral program.
                </p>

                <p className="para">
                  As a member of BrainFlix, you can give your friends one
                  additional month of free service when they join through your
                  unique referral link or code. As a thank you, you will also
                  receive one additional month of free service for every
                  referral, up to 6 months.
                </p>
                {userDetails?.referralCode !== null && (
                  <p className="para">
                    Share your referral link:
                    {`https://admin.brainflix.com/sign-up?ref=${userDetails?.referralCode}`}
                  </p>
                )}
                <p className="para">
                  Thank you for being a hero for the children and keeping them
                  safe from harmful influences!
                </p>

                <p className="last-para">
                  Visit the <u>Referral Program</u> page for more details.
                </p>
              </div>
              <div className="thank-you-button">
                <button className="btn btn-primary" onClick={goTODashBoard}>
                  CONTINUE TO CREATE YOUR PROFILE
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            {checkAgree !== false ? (
              <>
                <div className="loginFormWraper mt-2">
                  <div className="password-sec">
                    <div className="form-group">
                      <div className="label">Password</div>
                      <input
                        type={showPassword.pass ? "text" : "password"}
                        name="password"
                        id=""
                        className="form-control py-2"
                        placeholder="Enter password"
                        onChange={handleChange}
                        value={signup.password}
                      />
                      <i
                        onClick={() =>
                          setShowPassword({
                            ...showPassword,
                            pass: !showPassword.pass,
                          })
                        }
                        className={`fa-solid ${
                          showPassword.pass ? "fa-eye" : "fa-eye-slash"
                        } open-eye`}
                      ></i>
                      <span style={{ color: "red" }}>{error.password}</span>
                      {eightPass === undefined ? null : (
                        <span style={{ color: eightPass ? "green" : "red" }}>
                          Password must be at least 8 characters
                        </span>
                      )}
                      <br></br>
                      {uppr === undefined ? null : (
                        <span style={{ color: uppr ? "green" : "red" }}>
                          At least one Upper Case character
                        </span>
                      )}
                      <br />
                      {spcl === undefined ? null : (
                        <span style={{ color: spcl ? "green" : "red" }}>
                          At least one Special Character [e.g., ! @ # ?]
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <div className="label">Re-enter password</div>
                      <input
                        type={showPassword.conPass ? "text" : "password"}
                        name="conPass"
                        id=""
                        className="form-control py-2"
                        placeholder="*************"
                        onChange={handleChange}
                        value={signup.conPass}
                      />
                      <i
                        onClick={() =>
                          setShowPassword({
                            ...showPassword,
                            conPass: !showPassword.conPass,
                          })
                        }
                        className={`fa-solid ${
                          showPassword.conPass ? "fa-eye" : "fa-eye-slash"
                        } open-eye`}
                      ></i>
                      <span style={{ color: "red" }}>{error.conPass}</span>
                    </div>
                    <div className="submit-btn">
                      <button
                        className="btn btn-primary w-100 py-2 "
                        onClick={(e) => handleSubmit(e)}
                        disabled={
                          status === "loading" ||
                          socialSignIN_status === "loading"
                        }
                      >
                        {(status === "loading" ||
                          socialSignIN_status === "loading") && (
                          <span
                            className="spinner-border spinner-border-sm mx-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                        Start my 30-day free trial
                      </button>
                    </div>
                    <div className="links">
                      <p className="text-center">
                        Already a member?
                        <Link to="/log-in"> Sign in</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="loginFormWraper">
                  <div className="loginForm">
                    <h2
                      dangerouslySetInnerHTML={{
                        __html: `${cmsgeneral_data?.title}`,
                      }}
                    ></h2>
                    <button
                      className="google_box py-2"
                      onClick={handleGoogleLogin}
                    >
                      <img src="/assets/images/icons/googleImg.png" alt="img" />{" "}
                      <p>Sign Up with Google</p>{" "}
                    </button>{" "}
                    <div className="or_class">Or</div>
                    <div className="form-group">
                      <div className="label">Your email</div>
                      <input
                        type="text"
                        name="email"
                        id=""
                        className="form-control py-2"
                        placeholder="Enter Your Email"
                        onChange={handleChange}
                        value={signup.email}
                      />
                      <span style={{ color: "red" }}>{error.email}</span>
                    </div>
                    <div className="">
                      <div className="form-group">
                        <label>
                          <input
                            type="checkbox"
                            name="chk18"
                            hidden
                            id=""
                            onChange={handleChange}
                            value={signup.chk18}
                          />
                          <em>
                            <i>
                              <img src={tickIcon} alt="" />
                              <i></i>
                            </i>
                            <span>
                              I am 18 or older, and will only add children to my
                              account who are under 18 and are my legal
                              dependants
                            </span>
                            <span style={{ color: "red" }}>{error.chk18}</span>
                          </em>
                        </label>
                      </div>
                      <div className="form-group">
                        <label>
                          <input
                            type="checkbox"
                            name="chkTrms"
                            hidden
                            id=""
                            onChange={(e) =>
                              setSignup({ ...signup, chkTrms: !signup.chkTrms })
                            }
                            value={signup.chkTrms}
                            checked={signup.chkTrms}
                          />
                          <em>
                            <i>
                              <img src={tickIcon} alt="" />
                              {/* <img src="assets/images/tick.svg" alt=""></img>
                               */}
                              <i></i>
                            </i>
                            <span>
                              Yes! I would like to receive parent resources and
                              tips, updates, special promotions and other
                              information about BrainFlix.
                            </span>
                          </em>
                        </label>
                      </div>
                    </div>
                    <div className="agree-sec">
                      <div className="agree-text">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: `${cmsgeneral_data?.content}`,
                          }}
                        >
                          {/* We do not sell your information to third parties. Our
                          collection of personal information on the platform
                          that are directed to children under 13 is intended to
                          follow the principles of COPPA. We may use your data
                          as described in our <span>Privacy Policy, </span>
                          including selecting videos that fit the user’s age
                          group and learning interest. By clicking “Sign in with
                          Google” or “Agree & Continue”, you agree to our{" "}
                          <span>Subscriber Agreement</span> and acknowledge that
                          you have read our <span>Privacy Policy</span> and
                          <span>California Privacy Notice.</span> */}
                        </p>
                      </div>
                      <div className="agree-button">
                        <button
                          className="btn btn-primary w-100 py-2 "
                          onClick={(e) => handleSubmitForAgreeCheck(e)}
                        >
                          AGREE & CONTINUE
                        </button>
                      </div>
                    </div>
                    <div className="links pt-1">
                      <p className="text-center">
                        Already a member?
                        <Link to="/log-in"> Sign in</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}

        {/*
         */}

        {/* <div className="loginFormWraper mt-2">
          <div className="enter-password-sec">
            <div className="form-group">
              <div className="label">Enter your password</div>
              <input
                type="password"
                className="form-control py-2"
                placeholder="********"
              />
              <span>(case sensitive)</span>
            </div>

            <div class="submit-btn pt-3">
              <button class="btn btn-primary w-100 py-2 ">
                Start my 30-day free trial
              </button>
            </div>
            <div className="forget-sec">
              <span>Forgot Password?</span>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}
