import { Height } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Placeholder } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { photoUrl } from "../../API";
import { validEmail } from "../../helpers/utilts";
import {
  user_beta,
  user_Contact_us,
  user_beta_cms,
} from "../../redux/userDetailsSlice";
import "./SubmitLoginPage.scss";

export default function SubmitLoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user_beta_status, user_beta_data, user_beta_cms_data } = useSelector(
    (s) => s.userDetailsss
  );

  const [contaccc, setcontaccc] = useState({
    name: "",
    email: "",
    feedback: "",
    isAdult: false,
  });

  const [error, setError] = useState({
    name: "",
    email: "",
    feedback: "",
    isAdult: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "name") {
      if (value.length === 0) {
        setError({ ...error, name: "Please Enter Your name" });
        setcontaccc({ ...contaccc, [name]: value });
      } else {
        setError({
          ...error,
          name: "",
        });
        setcontaccc({ ...contaccc, [name]: value });
      }
    }
    if (name === "email") {
      if (value.length === 0) {
        setError({ ...error, email: "Please Enter Your Email" });
        setcontaccc({ ...contaccc, [name]: value });
      } else {
        setError({
          ...error,
          email: "",
        });
        setcontaccc({ ...contaccc, [name]: value });
      }
    }
    if (name === "feedback") {
      if (value.length === 0) {
        setError({ ...error, feedback: "Please Enter Your valuable feedback" });
        setcontaccc({ ...contaccc, [name]: value });
      } else {
        setError({
          ...error,
          feedback: "",
        });
        setcontaccc({ ...contaccc, [name]: value });
      }
    }
    if (type === "checkbox") {
      if (name === "isAdult") {
        if (!checked) {
          setcontaccc({ ...contaccc, [name]: checked });
          setError({
            ...error,
            isAdult: "Age confirmation must be accepted",
          });
        } else {
          setcontaccc({ ...contaccc, [name]: checked });
          setError({
            ...error,
            isAdult: "",
          });
        }
      }
    }
  };

  const handlecontact = (e) => {
    e.preventDefault();
    setError({
      name: "",
      email: "",
      feedback: "",
      isAdult: "",
    });
    if (
      contaccc.email.length === 0 &&
      contaccc.name.length === 0 &&
      contaccc.feedback.length === 0 &&
      !contaccc.isAdult
    ) {
      setError({
        email: "Please Enter Your Email",
        name: "Please Enter Your name",
        feedback: "Please Enter Your valuable feedback",
        isAdult: "Age confirmation must be accepted",
      });
    } else if (contaccc.email.length === 0) {
      setError({ ...error, email: "Please Enter Your Email" });
    } else if (!validEmail(contaccc.email)) {
      setError({
        ...error,
        email: "Please Enter a vaild Email",
      });
    } else if (contaccc.name.length === 0) {
      setError({
        ...error,
        name: "Please enter your name",
      });
    } else if (contaccc.feedback.length === 0) {
      setError({
        ...error,
        feedback: "Please Enter Your valuable feedback",
      });
    } else if (!contaccc.isAdult) {
      setError({
        ...error,
        isAdult: "Age confirmation must be accepted",
      });
    } else {
      dispatch(user_beta(contaccc));

      navigate("/");
      toast.success(
        "Thank you for your interest in our Beta program! We will reach out to you with log-in credentials once your account is created"
      );
    }
  };
  useEffect(() => {
    dispatch(user_beta_cms());
  }, [dispatch]);
  useEffect(() => {
    if (user_beta_data !== undefined && user_beta_data !== null) {
      navigate("/");
    }
  }, [user_beta_data]);

  return (
    <div className="submit-login-page-main-sec">
      <div className="container">
        <div className="submit-login-page">
          <div className="row">
            <div className="col-md-7">
              <div className="left-sec-data-submit-page">
                <div className="firts-heading-brain-data">
                  {user_beta_cms_data?.heading !== undefined ? (
                    <>
                      <h2>{user_beta_cms_data?.heading}</h2>
                    </>
                  ) : (
                    <>
                      <Placeholder animation="glow">
                        <Placeholder
                          style={{
                            color: "white",
                            width: "198px",
                            /*  */
                          }}
                          className="rec-slider reset-foucs-v"
                          xs={4}
                        />
                      </Placeholder>
                    </>
                  )}
                  {user_beta_cms_data?.text !== undefined ? (
                    <>
                      {" "}
                      <p
                        dangerouslySetInnerHTML={{
                          __html: user_beta_cms_data?.text,
                        }}
                      ></p>
                    </>
                  ) : (
                    <>
                      {" "}
                      <Placeholder animation="glow">
                        <Placeholder
                          style={{ color: "white", width: "298px" }}
                          className="rec-slider reset-foucs-v"
                          xs={6}
                        />
                        <Placeholder
                          style={{ color: "white", width: "298px" }}
                          className="rec-slider reset-foucs-v"
                          xs={6}
                        />
                        <Placeholder
                          style={{ color: "white", width: "298px" }}
                          className="rec-slider reset-foucs-v"
                          xs={6}
                        />
                        <Placeholder
                          style={{ color: "white", width: "298px" }}
                          className="rec-slider reset-foucs-v"
                          xs={6}
                        />
                        <Placeholder
                          style={{ color: "white", width: "298px" }}
                          className="rec-slider reset-foucs-v"
                          xs={6}
                        />
                        <Placeholder
                          style={{ color: "white", width: "298px" }}
                          className="rec-slider reset-foucs-v"
                          xs={6}
                        />
                        <Placeholder
                          style={{ color: "white", width: "298px" }}
                          className="rec-slider reset-foucs-v"
                          xs={6}
                        />
                        <Placeholder
                          style={{ color: "white", width: "298px" }}
                          className="rec-slider reset-foucs-v"
                          xs={6}
                        />
                        <Placeholder
                          style={{ color: "white", width: "298px" }}
                          className="rec-slider reset-foucs-v"
                          xs={6}
                        />
                        <Placeholder
                          style={{ color: "white", width: "298px" }}
                          className="rec-slider reset-foucs-v"
                          xs={6}
                        />
                        <Placeholder
                          style={{ color: "white", width: "298px" }}
                          className="rec-slider reset-foucs-v"
                          xs={6}
                        />
                        <Placeholder
                          style={{ color: "white", width: "298px" }}
                          className="rec-slider reset-foucs-v"
                          xs={6}
                        />

                        <Placeholder
                          style={{ color: "white", width: "198px" }}
                          className="rec-slider reset-foucs-v"
                          xs={4}
                        />
                      </Placeholder>
                    </>
                  )}
                </div>
                <div className="left-data-form-sec">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="">Name</label>
                        <input
                          type="text"
                          id=""
                          className="form-control py-2"
                          placeholder="Your Name"
                          onChange={handleChange}
                          value={contaccc.name}
                          name="name"
                        />
                        <span style={{ color: "red" }}>{error.name}</span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="">Email</label>
                        <input
                          // type="email" onChange={handleChange}
                          type="text"
                          id=""
                          className="form-control py-2"
                          placeholder="Your@email.com"
                          onChange={handleChange}
                          value={contaccc.email}
                          name="email"
                        />
                        <span style={{ color: "red" }}>{error.email}</span>
                        {console.log("sdada", error.name)}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="">Request / Feedback</label>
                        <textarea
                          name="feedback"
                          value={contaccc.feedback}
                          id=""
                          cols="30"
                          rows="10"
                          onChange={handleChange}
                        ></textarea>
                        <span style={{ color: "red" }}>{error.feedback}</span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div class="last-all-terms-condition-sec">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="isAdult"
                          // hidden

                          onChange={handleChange}
                          value={contaccc.isAdult}
                          id="flexCheckDefault"
                        />
                        <label
                          style={error.isAdult ? { color: "red" } : {}}
                          class="form-check-label"
                          for="flexCheckDefault"
                          onChange={handleChange}
                        >
                          I am 18 or older, and will only add children to my
                          account who are under 18 and are my legal dependents.
                          By clicking "Submit Request" below, I am agreeing to
                          BrainFlix's{" "}
                          <Link to="/termscondition">Terms of Service </Link>{" "}
                          and{" "}
                          <Link to="/privacypolicy   "> Private Policy</Link> .
                          {/* <span style={{ color: "red" }}>{error.isAdult}</span> */}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="last-submit-btn-sec">
                  <button
                    disabled={user_beta_status === "loading"}
                    className="submit-btn"
                    onClick={(e) => handlecontact(e)}
                  >
                    Submit Request
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="right-submit-login-page-sec">
                <div className="right-first-login-btn-sec">
                  <Link to="/log-in">
                    <button className="login-btn">Login</button>
                  </Link>
                </div>

                <div className="right-img-sec">
                  <figure>
                    <img
                      src={`https://admin.brainflix.com/uploads/brainflix-beta-cms/${user_beta_cms_data?.image}`}
                      alt=""
                    />
                    {/* <img src="/assets/images/betaimg.png" alt="" /> */}
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
