/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./ProfileCreation.scss";
import {
  add_sub_user,
  resetRedirectTo,
} from "../../../src/redux/userDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { context } from "../../hooks";
import { toast } from "react-toastify";

export default function ProfileCreation() {
  // const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [add_sub_usersss, setAdd_sub_usersss] = useState({
    fullName: "",
    ageGroup: [],
  });
  const [isSubmit, setIsSubmit] = useContext(context);
  const { redirectTo, add_subuser_status } = useSelector(
    (s) => s.userDetailsss
  );
  const [error, setError] = useState({ fullName: "", ageGroup: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "fullName") {
      if (value.length === 0) {
        setError({ ...error, fullName: "Enter Your Profile name" });
        setAdd_sub_usersss({ ...add_sub_usersss, [name]: value });
      } else {
        setError({
          ...error,
          fullName: "",
        });
        setAdd_sub_usersss({ ...add_sub_usersss, [name]: value });
      }
    }
  };

  const handleChangeforcheckbox = (e, val) => {
    const { name, type, checked } = e.target;
    if (type === "checkbox") {
      if (name === "ageGroup") {
        if (!checked) {
          setAdd_sub_usersss({
            ...add_sub_usersss,
            ageGroup: [...add_sub_usersss?.ageGroup, val],
          });
          setError({
            ...error,
            ageGroup: "Select at least one age group",
          });
        } else {
          setAdd_sub_usersss({
            ...add_sub_usersss,
            ageGroup: [...add_sub_usersss?.ageGroup, val],
          });
          setError({
            ...error,

            ageGroup: "",
          });
        }
      }
    }
  };

  const restErr = () => {
    setError({
      fullName: "",
      ageGroup: "",
    });
  };
  const handleAdduser = (e) => {
    e.preventDefault();
    if (!isSubmit) {
      setIsSubmit(true);
    } else {
      restErr();
      if (
        add_sub_usersss.fullName.length === 0 &&
        add_sub_usersss.ageGroup.length === 0
      ) {
        setError({
          fullName: "Enter Your Profile name",
          ageGroup: "Select at least one age group",
        });
      } else if (add_sub_usersss.fullName.length === 0) {
        setError({
          ...error,
          fullName: "Enter Your ProfileName",
        });
      } else if (add_sub_usersss.ageGroup.length === 0) {
        setError({
          ...error,
          ageGroup: "Select at least one age group",
        });
      } else {
        dispatch(add_sub_user(add_sub_usersss));
        toast.success("Subuser Added Successfully");
        navigate("/browser/first-profile");
        // window.onload = function () {window.navigate.reload()}
      }
      setIsSubmit(false);
    }
  };

  useEffect(() => {
    if (redirectTo !== null) {
      navigate(redirectTo);
      dispatch(resetRedirectTo());
    }
  }, [redirectTo]);

  // useEffect(() => {
  //   if (ADD_Subuser_message === "success") {
  //     navigate("/browser/first-profile");
  //   }
  // }, [navigate, ADD_Subuser_message]);

  return (
    <>
      <div className="single-wrapper-whole sadadadda">
        <div className="profile-creation">
          <div className="row align-items-center justify-content-center px-3">
            <div className="col-lg-12 order-lg-0 order-1">
              <div className="profile-creation__heading">
                <h2>Add New Profile</h2>
                {/* for edit profile page */}
                {/* <h2>Edit Your Profile</h2> */}
              </div>

              <div className="profile-creation__body">
                <div className="profile-creation__form">
                  <div className="profile-creation-input">
                    <div className="profile-creation-input__label">
                      <label htmlFor="">Profile Name</label>
                      <input
                        type="text"
                        name="fullName"
                        id=""
                        onChange={handleChange}
                        placeholder="Enter Your Profile name"
                        value={add_sub_usersss.fullName}
                      />
                    </div>
                    <span style={{ color: "red" }}>{error.fullName}</span>
                  </div>

                  <div className="profile-creation__notation">
                    <p>
                      Select one or more age groups for which videos should be
                      personalized for:
                    </p>
                  </div>

                  <div className="profile-creation__lists">
                    <ul>
                      <li>
                        <div className="profile-cration__lists__item">
                          <div className="custom-checkbox">
                            <label>
                              <input
                                type="checkbox"
                                name="ageGroup"
                                value={"4-6"}
                                onChange={(e) => {
                                  handleChangeforcheckbox(e, e.target.value);
                                }}
                              />
                              <span></span>
                            </label>
                          </div>
                          <p>4 - 6 </p>
                        </div>
                      </li>
                      <li>
                        <div className="profile-cration__lists__item">
                          <div className="custom-checkbox">
                            <label>
                              <input
                                type="checkbox"
                                name="ageGroup"
                                value={"7-9"}
                                onChange={(e) => {
                                  handleChangeforcheckbox(e, e.target.value);
                                }}
                              />
                              <span></span>
                            </label>
                          </div>
                          <p>7 - 9</p>
                        </div>
                      </li>
                      <li>
                        <div className="profile-cration__lists__item">
                          <div className="custom-checkbox">
                            <label>
                              <input
                                type="checkbox"
                                name="ageGroup"
                                value={"10-12"}
                                onChange={(e) => {
                                  handleChangeforcheckbox(e, e.target.value);
                                }}
                              />
                              <span></span>
                            </label>
                          </div>
                          <p>10 - 12</p>
                        </div>
                      </li>
                      <li>
                        <div className="profile-cration__lists__item">
                          <div className="custom-checkbox">
                            <label>
                              <input
                                type="checkbox"
                                name="ageGroup"
                                value={"13+"}
                                onChange={(e) => {
                                  handleChangeforcheckbox(e, e.target.value);
                                }}
                              />
                              <span></span>
                            </label>
                          </div>
                          <p>13+</p>
                        </div>
                      </li>
                      <span style={{ color: "red" }}>{error.ageGroup}</span>
                    </ul>
                  </div>

                  <div
                    className="profile-creation__button"
                    onClick={(e) => handleAdduser(e)}
                  >
                    <button disabled={add_subuser_status === "loading"}>
                      {add_subuser_status === "loading" && (
                        <span
                          className="spinner-border spinner-border-sm mx-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
