import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// import { toast } from "react-toastify";
import axiosInstance from "../API";
import { endpoint } from "../API/endpoint";

//  ----------userdetail-----------
export const fetch_userdata_data = createAsyncThunk(
  "fetch_userdata_data",
  async (id) => {
    try {
      let res = await axiosInstance.post(endpoint.userdetails, id);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);
//  ----------userdetail-----------
//  ----------addsubuser-----------
export const add_sub_user = createAsyncThunk(
  "add-sub-user",
  async (add_sub_usersss) => {
    try {
      let res = await axiosInstance.post(endpoint.addsubuser, add_sub_usersss);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

//  ----------addsubuser-----------

//  ------------------- subUuserDetails -------------------

export const Subuser_details = createAsyncThunk("user/details", async () => {
  try {
    let res = await axiosInstance.get(endpoint.subuserDetails);
    return res?.data;
  } catch (error) {
    console.log(error);
  }
});

//  ------------------- subUuserDetails -------------------

//  ------------------- Allavter -------------------

export const All_avter_data = createAsyncThunk(
  "add-profile/fetch-all-avatars/data",
  async () => {
    try {
      let res = await axiosInstance.get(endpoint.allavter);
      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

//  ------------------- Allavter -------------------

//  ------------------- Subuser_delete -------------------

export const Subuser_delete = createAsyncThunk(
  "add-profile/fetch-all-avatars",
  async (id) => {
    try {
      let res = await axiosInstance.post(endpoint.subuser_delete, id);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

//  ------------------- Subuser_delete -------------------

//  ------------------- Subuser_Edit -------------------

export const Subuser_Edit = createAsyncThunk(
  "add-profile/fetch-all-avatarsscdada",
  async (id) => {
    try {
      let res = await axiosInstance.post(endpoint.Sub_User_edit, id);
      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

//  ------------------- Subuser_Edit -------------------

//  ------------------- user_feedback -------------------

export const user_feedback = createAsyncThunk(
  "user_feedback",
  async (feedback) => {
    try {
      let res = await axiosInstance.post(endpoint.feebackApi, feedback);
      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

//  ------------------- user_feedback -------------------

//  ------------------- Contact_us -------------------

export const user_Contact_us = createAsyncThunk(
  "user_Contact_us",
  async (contact) => {
    try {
      let res = await axiosInstance.post(endpoint.contactApi, contact);
      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const user_beta = createAsyncThunk("user_beta", async (contact) => {
  try {
    let res = await axiosInstance.post(endpoint.userbeta, contact);
    return res?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    console.log("dsadaaaa", error);
  }
});

export const user_beta_cms = createAsyncThunk("user_beta", async (betaa) => {
  try {
    let res = await axiosInstance.get(endpoint.betcms, betaa);
    return res?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    console.log("dsadaaaa", error);
  }
});

//  ------------------- Contact_us -------------------

//  ------------------- video-management/list-all/genral search -------------------

export const search_list = createAsyncThunk(
  "search_list",
  async ({ userId, generalSearch, dispatch }) => {
    const search = { userId, generalSearch };
    try {
      let res = await axiosInstance.post(endpoint.search_list_api, search);
      return res?.data;
    } catch (error) {
      console.log(error);
      dispatch(rest_search());
    }
  }
);

//  ------------------- video-management/list-all/genral search-------------------

/* ===========POST CARD DETAILS VIDEO =============== */
export const post_card_details = createAsyncThunk(
  "user/take-subscription",
  async (obj) => {
    try {
      let res = await axiosInstance.post(endpoint?.userCardDetails, obj);
      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

/* ============POST CARD DETAILS VIDEO =============== */

/* ===========Parent_profile_update=============== */
export const Parent_profile_update = createAsyncThunk(
  "Parent_profile_update",
  async (obj) => {
    try {
      let res = await axiosInstance.post(endpoint?.Parentprofileupdate, obj);
      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

/* ============Parent_profile_update=============== */

//  ------------------- logofetch -------------------

export const logo_fetech = createAsyncThunk("logo_fetech", async (obj) => {
  try {
    let res = await axiosInstance.post(endpoint.logodata, obj);
    return res?.data;
  } catch (error) {
    console.log(error);
  }
});

//  ------------------- logofetch -------------------

const initialState = {
  user_data: null,
  user_status: "idle",

  add_subuser_data: null,
  add_subuser_status: "idle",
  ADD_Subuser_message: null,

  Subuser_details_data: null,
  Subuser_details_status: "idle",

  All_avter_datass: [],
  All_avter_status: "idle",

  Subuser_delete_data: null,
  Subuser_delete_status: "idle",

  Subuser_Edit_data: null,
  Subuser_Edit_status: "idle",
  redirectTo: null,

  feedback_data: null,
  feedback_status: "idle",

  contactus_data: null,
  contactus_status: "idle",

  search_list_data: null,
  search_list_status: "idle",
  isError: false,

  userCardDetails: null,
  userCardDetails_status: "idle",

  Parent_profile_data: null,
  Parent_profile_status: "idle",

  logofetch_data: null,
  logofetch_status: "idle",

  user_beta_data: null,
  user_beta_status: "idle",

  user_beta_cms_data:null,
  user_beta_cms_status:"idle"
};

export const userDetailsSlice = createSlice({
  name: "cmsSlice",

  initialState,

  reducers: {
    resetRedirectTo: (state) => {
      state.redirectTo = null;
    },
    rest_search: (state) => {
      state.search_list_data = [];
      state.search_list_status = "idle";
      state.isError = true;
      // alert("ok");
    },
  },

  extraReducers: {
    //  ----------userdetail-----------

    [fetch_userdata_data.pending]: (state) => {
      state.user_status = "loading";
      state.user_data = null;
    },
    [fetch_userdata_data.fulfilled]: (state, { payload }) => {
      state.user_status = "idle";
      if (payload?.type === "success") {
        state.user_data = payload?.data;
      }
    },
    [fetch_userdata_data.rejected]: (state) => {
      state.user_status = "idle";
      //   toast(state.message, { type: "error" });
    },

    //  ----------userdetail-----------

    //  ----------addsubuser-----------

    [add_sub_user.pending]: (state) => {
      state.add_subuser_status = "loading";
    },
    [add_sub_user.fulfilled]: (state, { payload }) => {
      state.add_subuser_status = "idle";
      if (payload?.type === "success") {
        state.add_subuser_data = payload?.data;
        state.redirectTo = "/browser/first-profile";

        state.ADD_Subuser_message = "success";
      } else {
        state.ADD_Subuser_message = "error";
        // state.add_subuser_data = "abc";
        // toast(payload?.message, { type: "error" });
      }
    },
    [add_sub_user.rejected]: (state) => {
      state.add_subuser_status = "idle";
      //   toast(state.message, { type: "error" });
    },

    //  ----------addsubuser-----------

    //  ------------------- subUuserDetails -------------------

    [Subuser_details.pending]: (state) => {
      state.Subuser_details_status = "loading";
      state.Subuser_details_data = null;
    },
    [Subuser_details.fulfilled]: (state, { payload }) => {
      if (payload?.type === "success") {
        state.Subuser_details_data = payload?.data;
      } else {
        state.Subuser_details_data = "no data";
      }
    },
    [Subuser_details.rejected]: (state) => {
      state.Subuser_details_status = "idle";
      //   toast(state.message, { type: "error" });
    },

    //  ------------------- subUuserDetails -------------------

    //  ------------------- All_avter_data -------------------

    [All_avter_data.pending]: (state) => {
      state.All_avter_status = "loading";
      state.All_avter_datass = [];
    },
    [All_avter_data.fulfilled]: (state, { payload }) => {
      state.All_avter_status = "idle";
      if (payload?.type === "success") {
        state.All_avter_datass = payload?.data;
      } else {
        state.All_avter_datass = "abc";
        // toast(payload?.message, { type: "error" });
      }
    },
    [All_avter_data.rejected]: (state) => {
      state.All_avter_status = "idle";
      //   toast(state.message, { type: "error" });
    },

    //  ------------------- All_avter_data -------------------

    //  ------------------- sub_user_delete -------------------

    [Subuser_delete.pending]: (state) => {
      state.Subuser_delete_status = "loading";
    },
    [Subuser_delete.fulfilled]: (state, { payload }) => {
      state.Subuser_delete_status = "idle";
      if (payload?.type === "success") {
        state.Subuser_delete_data = payload?.data;
      } else {
        // state.Subuser_delete_data = "abc";
        // toast(payload?.message, { type: "error" });
      }
    },
    [Subuser_delete.rejected]: (state) => {
      state.Subuser_delete_status = "idle";
      //   toast(state.message, { type: "error" });
    },

    //  ------------------- sub_user_delete -------------------

    //  ------------------- Subuser_Edit -------------------

    [Subuser_Edit.pending]: (state) => {
      state.Subuser_Edit_status = "loading";
    },
    [Subuser_Edit.fulfilled]: (state, { payload }) => {
      state.Subuser_Edit_status = "idle";
      if (payload?.type === "success") {
        state.Subuser_delete_data = payload?.data;
      } else {
        state.Subuser_delete_data = "abc";
        // toast(payload?.message, { type: "error" });
      }
    },
    [Subuser_Edit.rejected]: (state) => {
      state.Subuser_Edit_status = "idle";
      //   toast(state.message, { type: "error" });
    },

    //  ------------------- Subuser_Edit -------------------

    //  ------------------- user_feedback -------------------

    [user_feedback.pending]: (state) => {
      state.feedback_status = "loading";
    },
    [user_feedback.fulfilled]: (state, { payload }) => {
      state.feedback_status = "idle";
      if (payload?.type === "success") {
        state.feedback_data = payload?.data;
      } else {
        state.feedback_data = "abc";
        // toast(payload?.message, { type: "success" });
      }
    },
    [user_feedback.rejected]: (state) => {
      state.feedback_status = "idle";
      // toast(state.message, { type: "error" });
    },

    //  ------------------- user_feedback -------------------

    //  ------------------- user_Contact_us -------------------

    [user_Contact_us.pending]: (state) => {
      state.contactus_status = "loading";
    },
    [user_Contact_us.fulfilled]: (state, { payload }) => {
      state.feedback_status = "idle";
      if (payload?.type === "success") {
        state.contactus_data = payload?.data;
      } else {
        state.contactus_data = "abc";
        // toast(payload?.message, { type: "success" });
      }
    },
    [user_Contact_us.rejected]: (state) => {
      state.feedback_status = "idle";
      // toast(state.message, { type: "error" });
    },

    //  ------------------- user_Contact_us -------------------

    //  ------------------- user_beta -------------------

    [user_beta.pending]: (state) => {
      state.contactus_status = "loading";
    },
    [user_beta.fulfilled]: (state, { payload }) => {
      state.user_beta_status = "idle";
      console.log("dsada", payload);
      if (payload?.type === "success") {
        toast.success("Thank you for joining  Brainflix beta program");
        state.user_beta_data = payload?.data;
      }
    },
    [user_beta.rejected]: (state) => {
      state.feedback_status = "idle";
    },

    //  ------------------- user_beta -------------------



 //  ------------------- user_beta_cms -------------------

 [user_beta_cms.pending]: (state) => {
  state.user_beta_cms_status = "loading";
},
[user_beta_cms.fulfilled]: (state, { payload }) => {
  state.user_beta_cms_status = "idle";
  console.log("dsada", payload);
  if (payload?.type === "success") {
  
    state.user_beta_cms_data = payload?.data;
  }
},
[user_beta_cms.rejected]: (state) => {
  state.feedback_status = "idle";
},

//  ------------------- user_beta_cms-------------------



    //  ------------------- video-management/list-all/genral search -------------------

    [search_list.pending]: (state) => {
      state.search_list_status = "loading";
      state.search_list_data = [];
    },
    [search_list.fulfilled]: (state, { payload }) => {
      state.search_list_status = "idle";
      if (payload?.type === "success") {
        state.search_list_data = payload?.data;
      } else {
        state.search_list_data = [];
        // toast(payload?.message, { type: "success" });
      }
    },
    [search_list.rejected]: (state) => {
      state.search_list_status = "idle";
      state.search_list_data = [];
      // toast(state.message, { type: "error" });
    },

    //  ------------------- video-management/list-all/genral search -------------------

    /** =================== POST CARD DETATILS =================== */

    [post_card_details.pending]: (state) => {
      state.userCardDetails_status = "loading";
      state.userCardDetails = null;
    },
    [post_card_details.fulfilled]: (state, { payload }) => {
      state.userCardDetails_status = "idle";
      if (payload?.type === "success") {
        state.userCardDetails = payload?.data;
      } else {
        state.userCardDetails = "no data";
        // toast(payload?.message, { type: "success" });
      }
    },
    [post_card_details.rejected]: (state) => {
      state.userCardDetails_status = "idle";
      toast(state.message, { type: "error" });
    },
    /** =================== POST CARD DETATILS =================== */

    /** =================== Parent_profile_update =================== */

    [Parent_profile_update.pending]: (state) => {
      state.Parent_profile_status = "loading";
      state.userCardDetails = null;
    },
    [Parent_profile_update.fulfilled]: (state, { payload }) => {
      state.Parent_profile_status = "idle";
      if (payload?.type === "success") {
        state.Parent_profile_data = payload?.data;
      } else {
        state.Parent_profile_data = "no data";
        // toast(payload?.message, { type: "success" });
      }
    },
    [Parent_profile_update.rejected]: (state) => {
      state.Parent_profile_status = "idle";
      toast(state.message, { type: "error" });
    },
    /** =================== Parent_profile_update =================== */

    /** =================== logo =================== */

    [logo_fetech.pending]: (state) => {
      state.logofetch_status = "loading";
      state.userCardDetails = null;
    },
    [logo_fetech.fulfilled]: (state, { payload }) => {
      state.logofetch_status = "idle";
      if (payload?.type === "success") {
        state.logofetch_data = payload?.data;
      } else {
        state.logofetch_data = "no data";
        // toast(payload?.message, { type: "success" });
      }
    },
    [logo_fetech.rejected]: (state) => {
      state.logofetch_status = "idle";
      toast(state.message, { type: "error" });
    },
    /** =================== logo =================== */
  },
});

export const { resetRedirectTo, rest_search } = userDetailsSlice.actions;
