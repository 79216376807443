import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./authSlice";
import { cmsSlice } from "./cmsSlice";
import { userDetailsSlice } from "./../redux/userDetailsSlice";
import { videoSlice } from "./videoSlice";
const Store = configureStore({
  reducer: {
    cms: cmsSlice.reducer,
    auth: authSlice.reducer,
    userDetailsss: userDetailsSlice.reducer,
    video: videoSlice.reducer,
  },
});

export default Store;
